import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  popup: {
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    gap: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
      gap: 15,
    },
  },
  imageWrapper: {
    textAlign: 'center',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    justifyContent: 'center',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
      gap: 15,
    },
  },
  textLink: {
    color: '#626DF9',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: 'rgb(68, 76, 174)',
      textShadow: '-1px 1px 1px rgb(68 76 174 / 30%)',
    }
  },
}));
export default useStyles;
