import axiosAuthoringInstance from 'api/axios-client';

export const getIsoIcons = () => {
  return axiosAuthoringInstance
    .get(`/isoIcons/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
