import axiosAuthoringInstance from 'api/axios-client';

export const updateWorkProcedureRevision = (revisionID, data) => {
  return axiosAuthoringInstance
    .patch(`workProcedureRevisions/${revisionID}/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateWorkProcedure = (id, data) => {
  return axiosAuthoringInstance
    .patch(`workProcedures/${id}/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const editWorkProcedureRevisionLocation = (revisionID, data) => {
  return axiosAuthoringInstance
    .patch(`/workProcedures/locations/${revisionID}/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateWorkProcedureRevisionHazard = (id, data) => {
  return axiosAuthoringInstance
    .patch(`/workProcedures/hazards/${id}/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateRiskEvaluationHazardPPE = (id, data) => {
  return axiosAuthoringInstance
    .patch(`sdsEvaluations/ppe/${id}/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateWorkProcedureHazardPPE = (id, data) => {
  return axiosAuthoringInstance
    .patch(`workProcedures/hazards/ppe/${id}/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
