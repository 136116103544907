import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  menuItem: {
    '&.MuiListItemButton-root': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '12px 10px 12px 18px',
      color: theme.palette.colors.textLight,
      width: '100%',
      '& svg': {
        minWidth: 'auto',
        fill: theme.palette.colors.textLight,
      },
      '&:hover': {
        color: theme.palette.colors.primary,
        background: theme.palette.hoverColor.main,
        '& svg': {
          fill: theme.palette.colors.primary,
        },
      },
    },
  },
  menuItemHasChildren: {
    paddingRight: 0,
    '& .MuiListItemButton-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  menuActive: {
    '&.MuiListItemButton-root': {
      background: theme.palette.hoverColor.main,
      color: theme.palette.colors.primary,
      '& .MuiSvgIcon-root': {
        fill: theme.palette.colors.primary,
      },
      '& svg': {
        fill: theme.palette.colors.primary,
      },
    },
  },
  menuItemText: {
    flex: 1,
    marginLeft: 10,
    '& .MuiTypography-root': {
      fontSize: 14,
      flex: 1,
      whiteSpace: 'nowrap',
    },
  },
  childrenItemListWrap: {
    transition: 'max-height 0.25s ease-in-out',
    maxHeight: 0,
  },
  isExpand: {
    width: '100%',
    maxHeight: 450,
  },
  vertical: {
    right: 6,
    top: 0,
    width: 11,
    '& .simplebar-scrollbar:before': {
      top: 2,
      bottom: 2,
    },
  },
}));

export default useStyles;
