enum USER_ROLE {
    ADMIN_ROLE = 'Admin',
    ADMIN_LIGHT_ROLE = 'Admin Light',
    READ_ONLY_ROLE = 'Read only',
    NO_ACCESS_ROLE = 'No Access',
    STAFF_ROLE = 'Staff',
    OWNER = 'Owner',
}

enum LOCATION_ROLE {
    ADMIN_ROLE = 'Admin',
    READ_ONLY_ROLE = 'Read only',
    NO_ACCESS_ROLE = 'No Access',
    STAFF_ROLE = 'Staff',
    ADMIN_WITH_APPROVAL = 'Admin with approval',
    OWNER = 'Owner',
}

export { 
    USER_ROLE,
    LOCATION_ROLE,
}