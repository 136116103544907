import axios from 'axios';
import axiosAuthoringInstance from 'api/axios-client';
import { BASE_API_URL } from '../constants';
import { displayErrorApi } from 'utils/helper';
import defaultAxiosInstance from 'api/axios-client/default';

export const getUser = async () => {
  return await axiosAuthoringInstance
    .get(`/user/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const impersonateUser = (email) => {
  return axiosAuthoringInstance
    .get(`/user/impersonate/?impersonated_email=${encodeURIComponent(email)}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getUserWithoutHandling = () => {
  const axiosInstance = axios.create({
    baseURL: BASE_API_URL,
    timeout: 50000,
    headers: {
      Authorization: localStorage.getItem('access_token')
        ? 'JWT ' + localStorage.getItem('access_token')
        : null,
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  });
  return axiosInstance
    .get(`/user/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSDS = (sdsId) => {
  return axiosAuthoringInstance
    .get(`/sds/${sdsId}/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getAllUserSDSFiles = () => {
  return axiosAuthoringInstance
    .get('/sds/')
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getGHSStatements = () => {
  return axiosAuthoringInstance
    .get('/sds/hazardCodes/')
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubscriptionPlans = () => {
  return defaultAxiosInstance
    .get(`${BASE_API_URL}/subscriptions/public/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const loginQrCode = async (qrLoginToken) => {
  const qrAxiosInstance = axios.create({
    baseURL: BASE_API_URL,
    timeout: 50000,
    headers: {
      Authorization: qrLoginToken ? 'JWT ' + qrLoginToken : null,
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  });

  return qrAxiosInstance
    .get(`${BASE_API_URL}/auth/loginQRCode/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      displayErrorApi(error);
      return error.response;
    });
};

export const getPermission = () => {
  return axiosAuthoringInstance
    .get('/user/permission/')
    .then((response) => response)
    .catch((err) => err.response);
};
