import { createSlice } from '@reduxjs/toolkit';
import { ChemicalRegulationList } from 'interfaces/administration/SettingFunctionInterface';
import { fetchChemicalRegulationList } from './thunks';

export interface SettingState {
  chemicalRegulationList: Array<ChemicalRegulationList> | null;
}

const initialState: SettingState = {
  chemicalRegulationList: null,
};

const SettingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      /* fetchPermission */
      .addCase(
        fetchChemicalRegulationList.fulfilled,
        (
          state: SettingState,
          action: {
            payload: Array<ChemicalRegulationList>;
          }
        ) => {
          state.chemicalRegulationList = action.payload;
        }
      );
  },
});

export default SettingSlice.reducer;
