import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'constant';
import { UserState } from './slice';
import UserInterface, {
  UserAdditionalColumnSetting,
} from 'interfaces/UserInterface';
import { PrintLabelValueEnums } from 'enums/print-label-format-enums';

export const loadingSelector = createSelector<
  [(state: RootState) => UserState],
  boolean
>([state => state.user], user => user.loading);

export const userDataSelector = createSelector<
  [(state: RootState) => UserState],
  UserInterface | null
>([state => state.user], user => user.userData);

export const systemColumnsSelector = createSelector<
  [(state: RootState) => UserState],
  Array<UserAdditionalColumnSetting>
>([state => state.user], user => user.systemColumns);

export const customColumnsSelector = createSelector<
  [(state: RootState) => UserState],
  Array<UserAdditionalColumnSetting>
>([state => state.user], user => user.customColumns);

export const newerCountSelector = createSelector<
  [(state: RootState) => UserState],
  number
>([state => state.user], user => user.newerCount);

export const missingSDSCountSelector = createSelector<
  [(state: RootState) => UserState],
  number
>([state => state.user], user => user.missingSDSCount);

export const completedSDSRequestCountSelector = createSelector<
  [(state: RootState) => UserState],
  number
>([state => state.user], user => user.completedSDSRequestCount);

export const archivedSDSCountSelector = createSelector<
  [(state: RootState) => UserState],
  number
>([state => state.user], user => user.archivedSDSCount);

export const defaultLocationSelector = createSelector<
  [(state: RootState) => UserState],
  string | number | null
>([state => state.user], user => user.defaultLocation);

export const sdsCountSelector = createSelector<
  [(state: RootState) => UserState],
  number
>([state => state.user], user => user.sdsCount);

export const totalSDSInLocationCountSelector = createSelector<
  [(state: RootState) => UserState],
  number
>([state => state.user], user => user.totalSDSInLocationCount);

export const hasArchivedSdsSelector = createSelector<
  [(state: RootState) => UserState],
  boolean
>([state => state.user], user => user.hasArchivedSds);

export const allSubstancesCountSelector = createSelector<
  [(state: RootState) => UserState],
  number
>([state => state.user], user => user.allSubstancesCount);

export const substanceInUseCountSelector = createSelector<
  [(state: RootState) => UserState],
  number
>([state => state.user], user => user.substanceInUseCount);

export const substanceNotUseCountSelector = createSelector<
  [(state: RootState) => UserState],
  number
>([state => state.user], user => user.substanceNotUseCount);

export const availableSdsSelector = createSelector<
  [(state: RootState) => UserState],
  number
>([state => state.user], user => user.availableSds);

export const languageCodeSelector = createSelector<
  [(state: RootState) => UserState],
  string | null
>([state => state.user], user => user.languageCode);

export const printFormatSelector = createSelector<
  [(state: RootState) => UserState],
  PrintLabelValueEnums | null
>([state => state.user], user => user.printFormat);

export const hasImportedBinderSelector = createSelector<
  [(state: RootState) => UserState],
  boolean
>([state => state.user], user => user.hasImportedBinder);

export const accessMyWorkProcedurePageSelector = createSelector<
  [(state: RootState) => UserState],
  boolean
>([state => state.user], user => user.accessMyWorkProcedurePage);

export const accessMyExposurePageSelector = createSelector<
  [(state: RootState) => UserState],
  boolean
>([state => state.user], user => user.accessMyExposurePage);

export const customTagDataSelector = createSelector<
  [(state: RootState) => UserState],
  Array<UserAdditionalColumnSetting> | null
>([state => state.user], user => user.customTagData);

export const addSdsGuideCompletedSelector = createSelector<
  [(state: RootState) => UserState],
  boolean
>([state => state.user], user => user.addSdsGuideCompleted);

export const needToSetPwdSelector = createSelector<
  [(state: RootState) => UserState],
  boolean
>([state => state.user], user => user.needToSetPwd);

export const notFindSdsGuideCompletedSelector = createSelector<
  [(state: RootState) => UserState],
  boolean
>([state => state.user], user => user.notFindSdsGuideCompleted);

export const sdsRequestEmailCountSelector = createSelector<
  [(state: RootState) => UserState],
  number
>([state => state.user], user => user.sdsRequestEmailCount);

export const deletedSDSCountSelector = createSelector<
  [(state: RootState) => UserState],
  number
>([state => state.user], user => user.deletedSDSCount);
