import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import Paper from '@mui/material/Paper';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {
  ExposuresTableColumnProps,
  ExposuresTableProps,
  ExposureInterface,
} from 'interfaces/exposure';
import useStyles from './styles';
import { isMobile } from 'utils/helper';
import CardTableMobile from 'components/commons/card-table-mobile';
import { UnarchiveRounded } from '@mui/icons-material';
import { post } from '../../../api';

const MyExposuresTable = ({
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  count,
  rows,
  fetchExposures,
}: ExposuresTableProps) => {
  const classes: any = useStyles();
  const { t } = useTranslation(['exposures', 'common', 'key_info']);
  const columns: Array<ExposuresTableColumnProps> = [
    {
      id: 'work_procedure_name',
      title: t('common:job_procedures'),
      minWidth: 130,
      align: 'left',
    },
    {
      id: 'status',
      title: t('common:status'),
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'exposure_date',
      title: t('exposures:exposure_date'),
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'actions',
      title: '',
      minWidth: 100,
      align: 'center',
    },
  ];

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const markAssigmentAsCompleted = (id: number) => {
    const handlemarkAssigmentAsCompletedRequest = post(
      `/exposures/assigments/${id}/complete/`,
      {}
    );
    handlemarkAssigmentAsCompletedRequest.then((response) => {
      if (response.status === 200) {
        fetchExposures();
      }
    });
  };

  const getValue = (item: ExposureInterface, columnId: string) => {
    if (columnId === 'status') {
      return t(`exposures:${item[columnId]}`);
    }
    if (columnId === 'actions') {
      return (
        <div className={classes.actionsWrapper}>
          {item.status !== 'completed' ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                markAssigmentAsCompleted(item.id);
              }}
            >
              {t('exposures:mark_as_completed')}
            </Button>
          ) : null}
        </div>
      );
    }
    // @ts-ignore
    return item[columnId];
  };

  return (
    <>
      {isMobile() ? (
        <CardTableMobile
          hiddenNameLabel
          column={columns}
          renderValue={(item: ExposureInterface, id: string) =>
            getValue(item, id)
          }
          data={rows}
          count={count}
          page={page + 1}
          handlePageChange={(event: any, newPage: number) => {
            setPage(newPage - 1);
          }}
        />
      ) : (
        <Paper sx={{ width: '100%', position: 'relative', marginTop: '20px' }}>
          <TableContainer>
            <SimpleBar style={{ height: '78vh' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column, _) => (
                      <TableCell
                        key={column.id}
                        align={column.align ?? 'center'}
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                        }}
                      >
                        {column.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row: ExposureInterface, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          className={classes.tableRow}
                          component={'tr'}
                        >
                          {columns.map(
                            (column: ExposuresTableColumnProps, index) => {
                              return (
                                <TableCell
                                  key={index}
                                  align={column.align ?? 'center'}
                                  style={{
                                    minWidth: column.minWidth,
                                    maxWidth: column.maxWidth,
                                  }}
                                >
                                  {getValue(row, column.id)}
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </SimpleBar>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event: any, newPage: number) => {
              setPage(newPage);
            }}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ backgroundColor: '#F2F5F7' }}
            labelRowsPerPage={`${t('common:rows_per_page')}`}
          />
        </Paper>
      )}
    </>
  );
};

export default MyExposuresTable;
