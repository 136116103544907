import { Button } from "@mui/material";
import { CALENDLY_SDS_MANAGER_DEMO, SDS_MANAGER_URL } from "constant";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isMobile } from "utils/helper";
import { sdsManagerUrlLocalization } from "utils/i18n";
import useStyles from "./styles";
import { userDataSelector } from "services/user/selectors";

const DemoQRAccountFooter = () => {
  const classes = useStyles();
  const { t } = useTranslation(['footer']);
  const user = useSelector(userDataSelector);

  const listDemoQRUser: { [key: number]: string } = {
    6137: 'es',
    6056: 'us',
    5993: 'dk',
    6135: 'it',
    6064: 'el',
    6066: 'fi',
    6065: 'pt',
    6055: 'de',
    6142: 'nz',
    6143: 'au',
    6144: 'ca',
    6141: 'uk',
    5671: 'eu',
    6136: 'fr',
    6145: 'sv',
    6067: 'nl',
    5833: 'no',
    6068: 'pl'
  }
  const getLocalizationLink = (link: string) => {
    if (user && listDemoQRUser[user.id]) {
      return SDS_MANAGER_URL + '/' + listDemoQRUser[user.id] + link;
    }
    return SDS_MANAGER_URL + '/eu' + link;
  }

  return (
    <div className={classes.footerDemoWrapper}>
      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
        >
          <a
            rel="noreferrer"
            href={CALENDLY_SDS_MANAGER_DEMO}
            target={'_blank'}
          >
            {t('footer:book_demo')}
          </a>
        </Button>
        <Button
          variant="contained"
        >
          <a
            rel="noreferrer"
            href={getLocalizationLink('/pricing/')}
            target={'_blank'}
          >
            {t('footer:start_free_trial')}
          </a>
        </Button>
      </div>
      {!isMobile() && <div className={classes.footerLink}>
        <a
          rel="noreferrer"
          href={sdsManagerUrlLocalization() + '/privacy-policy'}
          target={'_blank'}
        >
          {t('footer:privacy_policy')}
        </a>
        <a
          rel="noreferrer"
          href={sdsManagerUrlLocalization() + '/terms-and-conditions'}
          target={'_blank'}
        >
          {t('footer:terms')}
        </a>
      </div>}
    </div>
  )
}
export default DemoQRAccountFooter;