import { ReactElement } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'utils/helper';
/* Components */
import {
  ListItemIcon,
  Box,
  Typography,
  ClassNameMap,
  ListItemButton,
} from '@mui/material';
import { ExpandMore, KeyboardArrowRight } from '@mui/icons-material';
import CustomTooltip from 'components/commons/custom-tooltip';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

interface MenuItemProps {
  content: string;
  isSelected: (value: string) => boolean;
  value: string;
  itemIcon: ReactElement;
  hasChildren?: boolean;
  childrenComponent?: ReactElement;
  isExpanded?: boolean;
  showMore?: boolean;
  onClick?: () => void;
  badge?: ReactElement | null;
}

const MenuItem = ({
  content,
  isSelected,
  value,
  itemIcon,
  hasChildren,
  childrenComponent,
  isExpanded,
  showMore,
  onClick,
  badge,
}: MenuItemProps) => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();

  const renderContent = (): ReactElement => {
    return (
      <ListItemButton
        className={`
          ${classes.menuItem} ${
          hasChildren ? classes.menuItemHasChildren : ''
        } ${isSelected(value) ? classes.menuActive : ''}
        `}
        component="div"
        onClick={() => onClick !== undefined && onClick()}
      >
        <ListItemIcon style={{ minWidth: 'auto' }}>
          {itemIcon}
          {badge}
        </ListItemIcon>

        <Box className={classes.menuItemText} display="flex">
          <Typography component="span">{t(content)}</Typography>

          {hasChildren ? (
            isExpanded ? (
              <ExpandMore
                style={{
                  fontSize: 22,
                  color: 'rgba(0, 0, 0, 0.54)',
                }}
              />
            ) : (
              <KeyboardArrowRight
                style={{
                  fontSize: 22,
                  color: 'rgba(0, 0, 0, 0.54)',
                }}
              />
            )
          ) : null}
        </Box>
      </ListItemButton>
    );
  };

  return (
    <>
      {!showMore ? (
        <CustomTooltip placement="right" title={t(content)}>
          {renderContent()}
        </CustomTooltip>
      ) : (
        renderContent()
      )}

      {hasChildren && (
        <div
          className={`${classes.childrenItemListWrap} ${
            isExpanded ? classes.isExpand : ''
          }`}
        >
          <SimpleBar
            style={{
              maxHeight: 450,
            }}
            autoHide={isMobile() ? false : true}
            classNames={{
              vertical: classes.vertical,
            }}
          >
            {childrenComponent}
          </SimpleBar>
        </div>
      )}
    </>
  );
};

export default MenuItem;
