import axiosAuthoringInstance, { setAxiosTimeout } from 'api/axios-client';
import { SUBSTANCE_FILTER_LOCATION_STATUS } from 'enums/substance-filter-enum';

export const getInventoryStats = () => {
  return axiosAuthoringInstance
    .get('/v2/locations/statistics/')
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getLocationsStructureUser = () => {
  return axiosAuthoringInstance
    .get('/locations/foldersStructureGrantAccess/')
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getDepartments = () => {
  return axiosAuthoringInstance
    .get('/locations/')
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getParentAndSiblingDepartments = inventoryID => {
  return axiosAuthoringInstance
    .get(`/locations/${inventoryID}/listlocationsforsetingparent/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getLocationByID = (inventoryID, params = {}) => {
  return axiosAuthoringInstance
    .get(`/locations/${inventoryID}/`, { params: params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getAllUserSDSFiles = (locationId, params) => {
  return axiosAuthoringInstance
    .get(`locations/${locationId}/availableSDSForLocation/`, {
      params: params,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstances = params => {
  const newParams = new URLSearchParams();
  params.producer_name?.map(el => newParams.append('producer_name', el));
  params.product_name?.map(el => newParams.append('product_name', el));
  params.revision_date?.map(el => newParams.append('revision_date', el));
  if (params.search) newParams.append('search', params.search);
  newParams.append('page', params.page);
  newParams.append('page_size', params.page_size);
  if (params.is_archived) newParams.append('is_archived', params.is_archived);
  if (params.has_newer_version) {
    newParams.append('has_newer_version', params.has_newer_version);
    newParams.append('is_archived', false);
  }
  if (params.without_linked_sds != undefined)
    newParams.append('without_linked_sds', params.without_linked_sds);
  if (params.hazardous_substances)
    newParams.append('hazardous_substances', params.hazardous_substances);
  if (params.duplicated != undefined)
    newParams.append('duplicated', params.duplicated);
  if (params.sds_status) newParams.append('sds_status', params.sds_status);
  if (params.approval_status)
    newParams.append('approve_status', params.approval_status);
  if (params.locations) newParams.append('location_filter', params.locations);
  if (params.risk_assessment)
    newParams.append('risk_assesment', params.risk_assessment);
  return axiosAuthoringInstance
    .get(`substances/`, { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstancesV2 = params => {
  const newParams = new URLSearchParams();
  params.producer_name?.map(el => newParams.append('producer_name', el));
  params.product_name?.map(el => newParams.append('product_name', el));
  params.revision_date?.map(el => newParams.append('revision_date', el));
  params.chemical_regulation_list_ids?.map(el =>
    newParams.append('chemical_regulation_list_ids', el)
  );
  newParams.append('page', params.page);
  newParams.append('page_size', params.page_size);

  if (params.search) newParams.append('search', params.search);
  if (params.cas_no) newParams.append('cas_no', params.cas_no);
  if (params.is_archived != undefined)
    newParams.append('is_archived', params.is_archived);
  if (params.without_linked_sds != undefined)
    newParams.append('without_linked_sds', params.without_linked_sds);
  if (params.duplicated != undefined)
    newParams.append('duplicated', params.duplicated);
  if (params.has_product_label != undefined)
    newParams.append('has_product_label', params.has_product_label);
  if (params.dismissed_upgrade != undefined)
    newParams.append('dismissed_upgrade', params.dismissed_upgrade);

  if (params.hazardous_substances)
    newParams.append('hazardous_substances', params.hazardous_substances);
  if (params.sds_status) newParams.append('sds_status', params.sds_status);
  if (params.approval_status)
    newParams.append('approve_status', params.approval_status);
  if (params.risk_assessment)
    newParams.append('risk_assesment', params.risk_assessment);
  if (params.sds_request_status)
    newParams.append('sds_request_status', params.sds_request_status);
  if (params.location_status)
    newParams.append('location_status', params.location_status);
  if (params.locationIds) newParams.append('location_ids', params.locationIds);
  if (params.importFile)
    newParams.append('import_file_path', params.importFile);
  if (params.wish_list_id)
    newParams.append('wish_list_id', params.wish_list_id);
  if (params.has_replies_from_vendor)
    newParams.append('has_replies_from_vendor', params.has_replies_from_vendor);
  if (params.is_sds_request)
    newParams.append('is_sds_request', params.is_sds_request);
  if (params.replaced_by_newer_version)
    newParams.append(
      'replaced_by_newer_version',
      params.replaced_by_newer_version
    );

  if (params.hazardous_risk_assessment)
    newParams.append(
      'hazardous_risk_assessment',
      params.hazardous_risk_assessment
    );

  if (params.has_requested_new_version)
    newParams.append(
      'has_requested_new_version',
      params.has_requested_new_version
    );

  if (params.order_by != undefined)
    newParams.append('order_by', params.order_by);
  else newParams.append('order_by', 'product_name');

  if (params.has_newer_version) {
    newParams.append('has_newer_version', params.has_newer_version);
    newParams.append('is_archived', false);
  }

  if (params.locations) {
    if (
      Object.values(SUBSTANCE_FILTER_LOCATION_STATUS).includes(params.locations)
    )
      newParams.append('location_filter', params.locations);
    else newParams.append('location_id', params.locations);
  }
  if (params.assigned_user) {
    newParams.append('assigned_user', params.assigned_user);
  }

  return axiosAuthoringInstance
    .get(`v2/substances/`, { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getPhotographedProducts = () => {
  return axiosAuthoringInstance
    .get(`v2/substances/getPhotographedProducts/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getAllSds = params => {
  const newParams = new URLSearchParams();
  params.producer_name?.map(el => newParams.append('producer_name', el));
  params.product_name?.map(el => newParams.append('product_name', el));
  params.revision_date?.map(el => newParams.append('revision_date', el));
  params.updated_by?.map(el => newParams.append('updated_by', el));
  if (params.statement) newParams.append('statement', params.statement);
  if (params.search) newParams.append('search', params.search);
  if (params.cas_no) newParams.append('cas_no', params.cas_no);
  newParams.append('page', params.page);
  newParams.append('page_size', params.page_size);
  if (params.has_newer_version) {
    newParams.append('has_newer_version', params.has_newer_version);
  }
  if (params.without_linked_sds != undefined)
    newParams.append('without_linked_sds', params.without_linked_sds);
  if (params.hazardous_substances)
    newParams.append('hazardous_substances', params.hazardous_substances);
  if (params.duplicated != undefined)
    newParams.append('duplicated', params.duplicated);
  if (params.sds_status) newParams.append('sds_status', params.sds_status);
  if (params.approval_status)
    newParams.append('approve_status', params.approval_status);
  if (params.risk_assessment)
    newParams.append('risk_assesment', params.risk_assessment);
  if (params.locations) {
    if (
      Object.values(SUBSTANCE_FILTER_LOCATION_STATUS).includes(params.locations)
    ) {
      newParams.append('location_filter', params.locations);
    } else newParams.append('location_id', params.locations);
  }
  if (params.order_by) {
    newParams.append('order_by', params.order_by);
  } else {
    newParams.append('order_by', 'product_name');
  }
  if (params.custom_field) {
    newParams.append('custom_field', params.custom_field);
  }
  if (params.custom_field_value) {
    newParams.append('custom_field_value', params.custom_field_value);
  }
  if (params.is_advanced_search) {
    newParams.append('is_advanced_search', params.is_advanced_search);
  }
  if (params.product_presence) {
    newParams.append('product_presence', params.product_presence);
  }
  if (params.product_presence_date) {
    newParams.append('product_presence_date', params.product_presence_date);
  }
  if (params.ghs_codes) newParams.append('ghs_codes', params.ghs_codes);
  if (params.language_code)
    newParams.append('language_code', params.language_code);
  if (params.external_system_id)
    newParams.append('external_system_id', params.external_system_id);
  if (params.amount && params.amount_measure) {
    newParams.append('amount', params.amount);
    newParams.append('amount_measure', params.amount_measure);
  }

  return axiosAuthoringInstance
    .get(`v2/substances/allSds/`, { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const exportSDSsToExcel = params => {
  const newParams = new URLSearchParams();
  newParams.append('order_by', 'product_name');
  params.producer_name?.map(el => newParams.append('producer_name', el));
  params.revision_date?.map(el => newParams.append('revision_date', el));
  params.updated_by?.map(el => newParams.append('updated_by', el));
  if (params.statement) newParams.append('statement', params.statement);
  if (params.search) newParams.append('search', params.search);
  if (params.cas_no) newParams.append('cas_no', params.cas_no);
  if (params.sds_status) newParams.append('sds_status', params.sds_status);
  if (params.approval_status)
    newParams.append('approve_status', params.approval_status);
  if (params.risk_assessment)
    newParams.append('risk_assesment', params.risk_assessment);
  if (params.locations) {
    if (
      Object.values(SUBSTANCE_FILTER_LOCATION_STATUS).includes(params.locations)
    ) {
      newParams.append('location_filter', params.locations);
    } else newParams.append('location_id', params.locations);
  }
  if (params.custom_field) {
    newParams.append('custom_field', params.custom_field);
  }
  if (params.custom_field_value) {
    newParams.append('custom_field_value', params.custom_field_value);
  }
  if (params.is_advanced_search) {
    newParams.append('is_advanced_search', params.is_advanced_search);
  }
  if (params.product_presence) {
    newParams.append('product_presence', params.product_presence);
  }
  if (params.product_presence_date) {
    newParams.append('product_presence_date', params.product_presence_date);
  }
  if (params.language_code)
    newParams.append('language_code', params.language_code);
  if (params.ghs_codes) newParams.append('ghs_codes', params.ghs_codes);
  if (params.external_system_id)
    newParams.append('external_system_id', params.external_system_id);
  if (params.amount && params.amount_measure) {
    newParams.append('amount', params.amount);
    newParams.append('amount_measure', params.amount_measure);
  }
  setAxiosTimeout(300000);
  return axiosAuthoringInstance
    .get(`v2/substances/exportToExcel/`, {
      params: newParams,
      responseType: 'blob',
    })
    .then(function (response) {
      setAxiosTimeout(50000);
      return response;
    })
    .catch(function (error) {
      setAxiosTimeout(50000);
      return error.response;
    });
};

export const getMatches = params => {
  return axiosAuthoringInstance
    .get(`substances/matches/`, { params: params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstancesUsage = substanceId => {
  return axiosAuthoringInstance
    .get(`substances/${substanceId}/usage/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getNewerCount = () => {
  return axiosAuthoringInstance
    .get('v2/substances/newerCount/')
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getFiltersValues = () => {
  let apiUrl = `v2/substances/filters/`;
  return axiosAuthoringInstance
    .get(apiUrl)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getCasNoFiltersValues = (
  onlyAccessLocation,
  excludeArchived,
  parentLocation
) => {
  let apiUrl = `v2/menu/listCasNoMenu/`;
  return axiosAuthoringInstance
    .get(apiUrl, {
      params: {
        onlyAccessLocation: onlyAccessLocation || true,
        excludeArchived: excludeArchived || true,
        parentLocation: parentLocation || null,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstancesFiltersValues = () => {
  return axiosAuthoringInstance
    .get(`substances/filters/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getLanguageMenuRequest = () => {
  return axiosAuthoringInstance
    .get(`v2/menu/listLanguageMenu/`, {
      params: {
        onlyAccessLocation: true,
        excludeArchived: true,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getAllSdsFiltersOptions = () => {
  return axiosAuthoringInstance
    .get(`v2/substances/allSds/filters/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getAllSdsCasNoFilterAutocomplete = params => {
  const newParams = new URLSearchParams();
  if (params.value) newParams.append('value', params.value);

  return axiosAuthoringInstance
    .get(`v2/substances/allSds/casNoAutocomplete/`, { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstancesOnLocation = (locationId, params) => {
  const newParams = new URLSearchParams();
  params.producer_name?.map(el => newParams.append('producer_name', el));
  params.product_name?.map(el => newParams.append('product_name', el));
  params.revision_date?.map(el => newParams.append('revision_date', el));
  newParams.append('page', params.page);
  newParams.append('page_size', params.page_size);
  newParams.append('is_archived', params.is_archived);
  newParams.append('has_newer_version', params.has_newer_version);
  if (!params.custom_field) {
    newParams.append('custom_field', false);
  }
  if (params.search) {
    newParams.append('search', params.search);
  }
  if (params.order_by) {
    newParams.append('order_by', params.order_by);
  }
  if (params.include_sublocation) {
    newParams.append('include_sublocation', params.include_sublocation);
  }
  let apiUrl = `locations/substances/`;
  if (locationId) {
    apiUrl = `locations/${locationId}/substances/`;
  }
  return axiosAuthoringInstance
    .get(apiUrl, { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstancesOnLocationV2 = (locationId, params) => {
  const newParams = new URLSearchParams();
  params.producer_name?.map(el => newParams.append('producer_name', el));
  params.product_name?.map(el => newParams.append('product_name', el));
  params.revision_date?.map(el => newParams.append('revision_date', el));
  // params.cas_no?.map((el) => newParams.append('cas_no', el));
  newParams.append('cas_no', params.cas_no ?? '');
  newParams.append('without_linked_sds', false);
  newParams.append('page', params.page);
  newParams.append('page_size', params.page_size);
  if (params.custom_tag)
    newParams.append('custom_tag', params.custom_tag);
  if (params.custom_field_tag)
    newParams.append('custom_field_tag', params.custom_field_tag);
  if (params.custom_field_value)
    newParams.append('custom_field_value', params.custom_field_value || '');
  newParams.append('is_archived', params.is_archived);
  if (params.has_newer_version) {
    newParams.append('has_newer_version', params.has_newer_version);
    newParams.append(
      'has_newer_version_for_location_and_my_sds',
      params.has_newer_version
    );
  }
  if (params.without_ean_upc_code) {
    newParams.append('without_ean_upc_code', params.without_ean_upc_code);
  }
  if (params.log_presence_months) {
    newParams.append('log_presence_months', params.log_presence_months);
  }
  if (params.custom_field) {
    newParams.append('custom_field', true);
  }
  if (params.search) {
    newParams.append('search', params.search);
  }
  if (params.order_by) {
    newParams.append('order_by', params.order_by);
  }
  if (params.include_sublocation) {
    newParams.append('include_sublocation', params.include_sublocation);
  }
  if (params.show_chemical) {
    newParams.append('show_chemical', true);
  }

  let apiUrl = `v2/locations/my-sds/`;
  if (locationId) {
    apiUrl = `v2/locations/${locationId}/substances/`;
  }
  return axiosAuthoringInstance
    .get(apiUrl, { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getRelatedSubstance = substanceId => {
  const newParams = new URLSearchParams();
  newParams.append('is_archived', false);
  newParams.append('has_newer_version', false);
  return axiosAuthoringInstance
    .get(`substances/${substanceId}/locations/`, { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getRelatedSubstanceV2 = (substanceId, params) => {
  const newParams = new URLSearchParams();
  newParams.append('is_archived', false);
  if (params.custom_field) {
    newParams.append('custom_field', true);
  }
  return axiosAuthoringInstance
    .get(`v2/substances/${substanceId}/locations/`, { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getImportedBinderDetail = bookletId => {
  return axiosAuthoringInstance
    .get(`importedBinders/${bookletId}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getImportedBinders = (
  page,
  pageSize,
  locations,
  status,
  resolution,
  keyword,
  showReplace = false,
  limit = null
) => {
  const newParams = new URLSearchParams();
  locations.map(el => newParams.append('location_id', el));
  resolution.map(el => newParams.append('resolution', el));
  newParams.append('page', page);
  newParams.append('page_size', pageSize);
  if (keyword) {
    newParams.append('search', keyword);
    if (showReplace) newParams.append('include_replace', true);
  }
  if (limit) newParams.append('limit', limit);
  if (status) {
    status.map(value => newParams.append('import_status', value));
  }

  return axiosAuthoringInstance
    .get(`importedBinders/`, { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getCustomerUsers = (
  customerId,
  status = 1,
  keyword = '',
  qr_status = {},
  otherParams = {}
) => {
  const newParams = new URLSearchParams();
  if (status != -1) newParams.append('is_active', status);
  Object.entries(qr_status).map(([key, value]) => {
    newParams.append(key, value);
  });
  if (keyword) newParams.append('search', keyword);

  Object.entries(otherParams).map(([key, value]) => {
    newParams.append(key, value);
  });

  return axiosAuthoringInstance
    .get(`customer/${customerId}/users/`, { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getCustomerUser = (userId) => {
  return axiosAuthoringInstance
    .get(`user/${userId}/customerUser/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubscriptionsWithPricing = () => {
  return axiosAuthoringInstance
    .get(`/subscriptions/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getStatusByTransactionId = id => {
  return axiosAuthoringInstance
    .get(`/subscriptions/billing/detail/?id=${id}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getArchivedLocations = () => {
  return axiosAuthoringInstance
    .get(`locations/archived/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getLocationAuthorisedUser = () => {
  return axiosAuthoringInstance
    .get(`/v2/user/getLocationAuthorisedUser/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getExtractionStatus = (departmentID, requestID) => {
  const urlParams = new URLSearchParams();
  urlParams.append('department_id', departmentID);
  urlParams.append('id', requestID);
  return axiosAuthoringInstance
    .get(`locations/getExtractionStatus/`, { params: urlParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getExtractionStatusV2 = (departmentID, email, requestID) => {
  const urlParams = new URLSearchParams();
  if (departmentID) urlParams.append('department_id', departmentID);
  if (email) urlParams.append('email', email);
  urlParams.append('id', requestID);
  return axiosAuthoringInstance
    .get(`v2/locations/getExtractionStatus/`, { params: urlParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getChangeAmountLog = (substanceID, params) => {
  const urlParams = new URLSearchParams();
  urlParams.append('page', params.page);
  urlParams.append('page_size', params.pageSize);
  return axiosAuthoringInstance
    .get(`/v2/substances/${substanceID}/changeAmountLog/`, {
      params: urlParams,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getAmountWorkProcedureOptions = (substanceId) => {
  return axiosAuthoringInstance
    .get(`/v2/substances/${substanceId}/amountWorkProcedureOptions/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export const getInfoAmountSubstance = substanceID => {
  return axiosAuthoringInstance
    .get(`/v2/substances/${substanceID}/amount/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstanceUseAndDisposalReport = params => {
  return axiosAuthoringInstance
    .get('/substances-report/', { params: params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstanceUseAndDisposalExcelReport = params => {
  return axiosAuthoringInstance
    .get(`/substances-report/export-excel/`, {
      params: params,
      responseType: 'blob',
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getLocationBinderSDSFile = (locationId, params) => {
  setAxiosTimeout(300000);
  return axiosAuthoringInstance
    .get(`/v2/locations/${locationId}/binder/`, {
      params: params,
      responseType: 'arraybuffer',
    })
    .then(function (response) {
      setAxiosTimeout(50000);
      return response;
    })
    .catch(function (error) {
      setAxiosTimeout(50000);
      return error.response;
    });
};

export const getLocationBinderInfo = (locationId, params) => {
  setAxiosTimeout(300000);
  return axiosAuthoringInstance
    .get(`/v2/locations/${locationId}/infoBinder/`, { params: params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getLocationGenerateBinder = (locationId, params) => {
  setAxiosTimeout(300000);
  return axiosAuthoringInstance
    .get(`/v2/locations/${locationId}/generateBinder/`, { params: params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getInformationStatus = requestId => {
  return axiosAuthoringInstance
    .get(`/informations/?id=${requestId}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getLatestExtractedDataCustomerStatus = requestId => {
  return axiosAuthoringInstance
    .get(`/v2/substances/latestExtractedDataCustomerStatus/?id=${requestId}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstanceChemicalRegulationList = () => {
  return axiosAuthoringInstance
    .get(`/v2/substances/chemicalRegulationList/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getImportFileList = () => {
  return axiosAuthoringInstance
    .get(`/v2/substances/wishlist-import-file-filters/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getDownloadPdfsInfoStatus = requestId => {
  return axiosAuthoringInstance
    .get(`/importedBinders/customerDownloadPdfsInfo/?id=${requestId}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSectionSDSHeaderTranslation = () => {
  return axiosAuthoringInstance
    .get(`/v2/substances/sectionHeaderTranslation/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getCurrentAndNewSDSInfo = (currentSdsId, newSdsId) => {
  return axiosAuthoringInstance
    .get(`/pdfs/getSDSInfo/`, {
      params: {
        current_sds_id: currentSdsId,
        new_sds_id: newSdsId,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSDSValidationListV2 = params => {
  const newParams = new URLSearchParams();
  newParams.append('page', params.page);
  newParams.append('page_size', params.page_size);
  params.validation_quantity?.map(value =>
    newParams.append('validation_quantity', value)
  );
  if (params.validation_conflict !== null) {
    newParams.append('validation_conflict', params.validation_conflict);
  }
  params.status?.map(value => newParams.append('status', value));
  if (params.search) {
    newParams.append('search', params.search);
  }
  return axiosAuthoringInstance
    .get(`/sds-validations/`, { params: newParams })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getAllTagsBySectionId = (sectionId, substanceId) => {
  const newParams = new URLSearchParams();
  newParams.append('substance_id', substanceId);
  newParams.append('section', sectionId);
  return axiosAuthoringInstance
    .get(`/tagsSections/`, { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstancesCount = () => {
  return axiosAuthoringInstance
    .get(`/v2/substances/countSubstance/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const importedStatusGetLocationsFilter = () => {
  return axiosAuthoringInstance
    .get(`importedBinders/locationsFilter/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getDiffBetweenTwoSDS = sdsIds => {
  const newParams = new URLSearchParams();
  sdsIds?.map(sds_id => newParams.append('sds_id', sds_id));
  return axiosAuthoringInstance
    .get(`v2/substances/getDiffBetweenTwoSds/`, { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getQRLoginUserTemplate = qrUserId => {
  return axiosAuthoringInstance
    .get(`sendQRLoginUser/?login_qr_code_user_id=${qrUserId}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getImportProductListStatus = wishListId => {
  const urlParams = new URLSearchParams();
  urlParams.append('id', wishListId);
  return axiosAuthoringInstance
    .get(`v2/substances/import-product-list-status/`, { params: urlParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getLastSDSAddedByUser = () => {
  return axiosAuthoringInstance
    .get(`v2/substances/lastSDS/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getCustomerSDSWishLists = params => {
  return axiosAuthoringInstance
    .get(`customerSdsWishList/product-list/`, { params: params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSDSFromDistributor = params => {
  return axiosAuthoringInstance
    .get(`getSDSFromDistributor/`, { params: params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstanceVendorMessage = id => {
  return axiosAuthoringInstance
    .get(`/v2/substances/${id}/vendorMessage/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getChemicalRegulationList = () => {
  return axiosAuthoringInstance
    .get(`/chemicalRegulationList/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getIngredientCheck = () => {
  return axiosAuthoringInstance
    .get(`/user/ingredientCheck/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstanceNFPAvalueFromSDSPdf = SdsId => {
  return axiosAuthoringInstance
  .get(`/v2/substances/getNFPAValueSDSPdf/?sds_id=${SdsId}`)
  .then(function (response) {
    return response;
  })
  .catch(function (error) {
    return error.response;
  });
}

export const getSubstanceNFPAvalue = substanceId => {
  return axiosAuthoringInstance
    .get(`/v2/substances/${substanceId}/getRecentNFPALabelValue/`, {
      params: {},
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSubstanceHMISvalue = substanceId => {
  return axiosAuthoringInstance
    .get(`/v2/substances/${substanceId}/getRecentHMISLabelValue/`, {
      params: {},
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSDSRequestChangeDataLogs = substanceId => {
  return axiosAuthoringInstance
    .get(`/v2/substances/${substanceId}/changeLogs/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSDSRequestComments = substanceId => {
  return axiosAuthoringInstance
    .get(`/v2/substances/${substanceId}/comments/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getTutorialVideosAndGuideArticles = () => {
  return axiosAuthoringInstance
    .get(`/tutorialsAndGuidesSetting/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getRiskSDS = params => {
  const newParams = new URLSearchParams();
  if (params.search) newParams.append('search', params.search);
  newParams.append('page', params.page);
  newParams.append('page_size', params.page_size);

  if (params.hazardous_substances)
    newParams.append('hazardous_substances', params.hazardous_substances);

  if (params.duplicated != undefined)
    newParams.append('duplicated', params.duplicated);

  if (params.sds_status) newParams.append('sds_status', params.sds_status);
  if (params.risk_assessment)
    newParams.append('risk_assesment', params.risk_assessment);
  if (params.locations) {
    if (
      Object.values(SUBSTANCE_FILTER_LOCATION_STATUS).includes(params.locations)
    ) {
      newParams.append('location_filter', params.locations);
    } else newParams.append('location_id', params.locations);
  }
  if (params.order_by) {
    newParams.append('order_by', params.order_by);
  } else {
    newParams.append('order_by', 'product_name');
  }

  return axiosAuthoringInstance
    .get(`riskEvaluations/allSds`, { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};