import { BASE_API_URL, getEnv } from 'api/constants';
import { renderSnackbar } from '../../utils';
import axiosInstance from 'api/axios-client';
import defaultAxiosInstance from 'api/axios-client/default';
import i18next from 'utils/i18n';
import { USER_ROLE } from 'enums/role.enums';
const host = window.location.hostname;

const loginUser = (email, password) => {
  return defaultAxiosInstance
    .post(`${BASE_API_URL}/auth/login/`, {
      email: email,
      password: password,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const signUpUser = (
  email,
  firstName,
  lastName,
  phoneNumber,
  company,
  country,
  language,
  subscriptionPlan,
  isTrial,
  password
) => {
  return defaultAxiosInstance
    .post(`${BASE_API_URL}/auth/signUp/`, {
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      service: 'sds_inventory',
      company: company,
      country: country,
      default_language: language,
      subscription_plan: subscriptionPlan,
      is_trial_period: isTrial,
      password: password,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.error_code !== undefined) {
        if (typeof error.response?.data?.error_message == 'string') {
          renderSnackbar([error.response.data.error_message]);
        }
        if (typeof error.response?.data?.error_message == 'object') {
          const res = [];
          Object.entries(error.response.data.error_message).map(
            ([key, value]) => {
              res.push(
                `<span style="text-transform: capitalize">${key}</span>: ${value.join(
                  ', '
                )}`
              );
            }
          );
          renderSnackbar(res);
        }
      } else {
        renderSnackbar([i18next.t('common:some_thing_wrong')]);
      }
      return error.response;
    });
};

const resetPasswordUser = (email) => {
  return defaultAxiosInstance
    .post(`${BASE_API_URL}/auth/reset-password/`, {
      email: email,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const requestLoginLink = (email) => {
  return defaultAxiosInstance
    .post(`${BASE_API_URL}/auth/requestLoginLink/`, {
      email: email,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const loginLink = (userId, token) => {
  return defaultAxiosInstance
    .post(`${BASE_API_URL}/auth/loginLink/`, {
      userId: userId,
      token: token,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.error_code !== undefined) {
        if (typeof error.response?.data?.error_message == 'string') {
          renderSnackbar([error.response.data.error_message]);
        }
        if (typeof error.response?.data?.error_message == 'object') {
          const res = [];
          Object.entries(error.response.data.error_message).map(
            ([key, value]) => {
              res.push(
                `<span style="text-transform: capitalize">${key}</span>: ${value.join(
                  ', '
                )}`
              );
            }
          );
          renderSnackbar(res);
        }
      } else {
        renderSnackbar([i18next.t('common:some_thing_wrong')]);
      }
      return error.response;
    });
};

const oneSignIn = (userId, token, signin_token) => {
  return defaultAxiosInstance
    .post(`${BASE_API_URL}/auth/onesignin/`, {
      userId: userId,
      token: token,
      signin_token: signin_token? signin_token: null,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.error_code !== undefined) {
        if (typeof error.response?.data?.error_message == 'string') {
          renderSnackbar([error.response.data.error_message]);
        }
        if (typeof error.response?.data?.error_message == 'object') {
          const res = [];
          Object.entries(error.response.data.error_message).map(
            ([key, value]) => {
              res.push(
                `<span style="text-transform: capitalize">${key}</span>: ${value.join(
                  ', '
                )}`
              );
            }
          );
          renderSnackbar(res);
        }
      } else {
        renderSnackbar([i18next.t('common:some_thing_wrong')]);
      }
      return error.response;
    });
};

const needToSetPassword = (userId, token) => {
  return defaultAxiosInstance
    .post(`${BASE_API_URL}/auth/onesignin/need-to-set-password/`, {
      userId: userId,
      token: token,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const validSetPasswordToken = (userId, token) => {
  return defaultAxiosInstance
    .post(`${BASE_API_URL}/auth/check-valid-token/`, {
      userId: userId,
      token: token,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.error_code !== undefined) {
        if (typeof error.response?.data?.error_message == 'string') {
          renderSnackbar([error.response.data.error_message]);
        }
        if (typeof error.response?.data?.error_message == 'object') {
          const res = [];
          Object.entries(error.response.data.error_message).map(
            ([key, value]) => {
              res.push(
                `<span style="text-transform: capitalize">${key}</span>: ${value.join(
                  ', '
                )}`
              );
            }
          );
          renderSnackbar(res);
        }
      } else {
        renderSnackbar([i18next.t('common:some_thing_wrong')]);
      }
      return error.response;
    });
};

const setPassword = (userId, token, password) => {
  return defaultAxiosInstance
    .post(`${BASE_API_URL}/auth/set-password/`, {
      userId: userId,
      token: token,
      password: password,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.error_code !== undefined) {
        if (typeof error.response?.data?.error_message == 'string') {
          renderSnackbar([error.response.data.error_message]);
        }
        if (typeof error.response?.data?.error_message == 'object') {
          const res = [];
          Object.entries(error.response.data.error_message).map(
            ([key, value]) => {
              res.push(
                `<span style="text-transform: capitalize">${key}</span>: ${value.join(
                  ', '
                )}`
              );
            }
          );
          renderSnackbar(res);
        }
      } else {
        renderSnackbar([i18next.t('common:some_thing_wrong')]);
      }
      return error.response;
    });
};

const createUser = (data, qrLogin = false) => {
  if (qrLogin) {
    return axiosInstance
      .post('/auth/createUser/?qr_login=true', {
        ...data,
        last_name: '-',
        email: 'email@sent_from_back_end.com',
        inventory_manager_user_role: USER_ROLE.STAFF_ROLE
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error.response;
      });
  }

  return axiosInstance
    .post('/auth/createUser/', data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const reportError = (error) => {
  renderSnackbar(['Server currently under maintenance, please try again later']);

  if (getEnv() !== 'production' || host.indexOf('localhost') !== -1)
    return;

  const originalRequest = error.config;
  if (originalRequest.url === '/report')
    return;

  return defaultAxiosInstance.post(
    '/report/', 
    { 
      url: originalRequest.url, 
      error_message: 'Internal server error' 
    }
  );
}
const update2FaSettingStatus = (otp) => {
  return axiosInstance
    .post('/auth/changeOtpStatus/', {
      otp: otp ? otp : null
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
  });
};
const sendChange2AFStatusOtp = () => {
  return axiosInstance
  .post('/auth/activateOtp/')
  .then(function (response) {
    return response;
  })
  .catch(function (error) {
    return error.response;
  });
};
const validateChange2AFStatusOtp = (otp) => {
  return axiosInstance
  .post('/auth/validateOtpEmail/', {otp: otp})
  .then(function (response) {
    return response;
  })
  .catch(function (error) {
    return error.response;
  });
};
const sendLogin2FAOtp = (requestId) => {
  return defaultAxiosInstance
  .post(`${BASE_API_URL}/auth/resendOtp/`, {request_id: requestId})
  .then(function (response) {
    return response;
  })
  .catch(function (error) {
    return error.response;
  });
};
const validateLogin2FAOtp = (requestId, otp) => {
  return defaultAxiosInstance
  .post(`${BASE_API_URL}/auth/validateOtp/`, {request_id: requestId, otp: otp})
  .then(function (response) {
    return response;
  })
  .catch(function (error) {
    return error.response;
  });
}

const refreshNewAccessToken = (token) => {
  return defaultAxiosInstance
  .post('/auth/refresh-token/', { token: token })
  .then(function (response) {
    return response;
  })
  .catch(function (error) {
    return error.response;
  });
}

/* get refresh token for scanning QR code session, refresh token will be  */
const scanningQRSessionGetRefreshToken = (token) => {
  return defaultAxiosInstance
  .post('/auth/scanning-qr-session-refresh-token/', { token: token })
  .then(function (response) {
    return response;
  })
  .catch(function (error) {
    return error.response;
  });
}

export {
  loginUser,
  signUpUser,
  resetPasswordUser,
  requestLoginLink,
  loginLink,
  oneSignIn,
  validSetPasswordToken,
  setPassword,
  createUser,
  needToSetPassword,
  reportError,
  update2FaSettingStatus,
  sendChange2AFStatusOtp,
  validateChange2AFStatusOtp,
  sendLogin2FAOtp,
  validateLogin2FAOtp,
  scanningQRSessionGetRefreshToken,
  refreshNewAccessToken
};
