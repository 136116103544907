import { useEffect, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { fetchUser } from 'services/user/thunks';
import {
  checkCustomerPermissions,
  checkUserPermissions,
  validateFeatureNotAvailable,
} from 'utils/userRoleUtils';
import { AppDispatch } from 'constant';
import { PERMISSION, USER_PERMISSION } from 'enums/permissions.enums';
import UserInterface from 'interfaces/UserInterface';

interface PermissionRouteProps {
  user: UserInterface | null;
  permission?: USER_PERMISSION | PERMISSION;
  customerPermission?: PERMISSION;
  children: ReactElement;
}

const PermissionRoute = (props: PermissionRouteProps) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const { user, permission, customerPermission, children } = props;
  
  useEffect(() => {
    if (permission && !checkUserPermissions(permission as USER_PERMISSION)) {
      history.replace('/');
      validateFeatureNotAvailable(permission as PERMISSION);
      return;
    }

    if (customerPermission && !checkCustomerPermissions(customerPermission)) {
      history.replace('/');
      validateFeatureNotAvailable(permission as PERMISSION);
      return;
    }
  }, []);

  useEffect(() => {
    if (!user) dispatch(fetchUser());
  }, []);
  
  if (user?.qr_login_token 
    && (
      history.location.pathname == '/dashboard' 
      || history.location.pathname == '/onboarding'
    )
  ) {
    return (
      <Redirect to={{
          pathname: '/my-sds',
          search: history.location.search,
        }}
      />
    )
  }

  return children;
};

export default PermissionRoute;
