import { Suspense, lazy, memo, ReactNode } from 'react';
import useStyles from './stylesV2';
import { useSelector } from 'react-redux';
import { checkUserExpire, deferImport, isMobile, shouldShowOnboarding } from '../../utils/helper';
import {
  expandMenuSelector,
  loadingModalSelector,
  featureNotAvailableSelector,
  openUpgradePlanPopupSelector,
} from 'services/common/selectors';
import UserInterface from 'interfaces/UserInterface';
/* Components */
import { ClassNameMap } from '@mui/material';
import MessagePopup from 'components/popup/message-popup/MessagePopup';
import AlertPopup from '../popup/alert-popup/AlertPopup';
import AddReportPopup from '../popup/add-report-popup/AddReportPopup';
import HeaderV2 from 'components/commons/header';
import CustomSidebar from 'components/commons/sidebar';
import Footer from 'components/commons/footer';
import DemoQRAccountFooter from 'components/commons/footer-demo/DemoQRAccountFooter';
import LoadingModal from 'components/loader/LoadingModal';
import FeatureNotAvailablePopup from 'components/popup/feature-not-available-popup';
import useSetUpStep from 'hooks/useSetUpStep';

/* Lazy load component */
const UpgradePlanSizePopup = lazy((): Promise<any> => {
  return deferImport(import('components/popup/upgrade-plan-size-popup'));
});
const OnboardingBubble = lazy((): Promise<any> => {
  return deferImport(import('components/onboarding/onboarding-bubble'));
});
/* End */

interface LayoutProps {
  children: ReactNode;
  user: UserInterface | null;
}

const Layout = ({ children, user }: LayoutProps) => {
  const openUpgradePlanPopup = useSelector(openUpgradePlanPopupSelector);
  const featureNotAvailable = useSelector(featureNotAvailableSelector);
  const loadingModal = useSelector(loadingModalSelector);

  return (
    <>
      <MessagePopup />
      <AlertPopup />
      <AddReportPopup />
      {loadingModal && <LoadingModal />}
      {!!featureNotAvailable && <FeatureNotAvailablePopup />}
      <Suspense fallback={<LoadingModal />}>
        {user &&
        !(
          window.location.pathname
            .toLowerCase()
            .indexOf('/service-unavailable') > -1
        ) ? (
          <>
            {openUpgradePlanPopup && <UpgradePlanSizePopup />}
            <AuthenticatedLayout user={user}>{children}</AuthenticatedLayout>
          </>
        ) : (
          children
        )}
      </Suspense>
    </>
  );
};

export default Layout;

interface AuthenticatedLayoutProps {
  user: UserInterface | null;
  children: ReactNode;
}

const AuthenticatedLayout = memo(
  ({ user, children }: AuthenticatedLayoutProps) => {
    const classes: ClassNameMap = useStyles();
    const showMore = useSelector(expandMenuSelector);
    const location = window.location.pathname;
    const isDemoQRAccount = user?.customer.id === 25 && user.qr_login_token;
    const { hasCompletedAll } = useSetUpStep();
    const showOnboardingBubble =  shouldShowOnboarding(user, 'bubble') && !hasCompletedAll

    if (isDemoQRAccount) {
      return (
        <div className={classes.root}>
          <HeaderV2 />
          {isMobile() ? (
            <div
              className={`${classes.page}`}
              style={{
                flexDirection: 'column',
                marginBottom: 50,
                overflowY: 'auto' 
              }}
            >
              <div className={classes.contentWrapper}>{children}</div>
              <Footer />
            </div>
          ) : (
            <div
              className={classes.page}
              style={{
                paddingLeft:
                  !user || checkUserExpire(user) ? 0 : showMore ? 300 : 60,
                marginBottom: 50,
              }}
            >
              {!checkUserExpire(user) && <CustomSidebar />}
              <div
                className={`${
                  showMore && location !== '/' ? classes.sidebarOpen : ''
                }`}
                style={{ position: 'relative', width: '100%', overflowY: 'auto' }}
              >
                <div
                  className={`${classes.contentWrapper} ${classes.contentWrapperWithDemoQRAccount}`}
                >
                  {children}
                </div>
              </div>
            </div>
          )}
          <DemoQRAccountFooter />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <HeaderV2 />
        {isMobile() ? (
          <div
            className={`${classes.page}`}
            style={{ flexDirection: 'column', overflowY: 'auto'}}
          >
            <div className={classes.contentWrapper}>{children}</div>
            <Suspense>
              {showOnboardingBubble && <OnboardingBubble />}
            </Suspense>
            <Footer />
          </div>
        ) : (
          <div
            className={classes.page}
            style={{
              paddingLeft:
                !user || checkUserExpire(user) ? 0 : showMore ? 300 : 60,
            }}
          >
            {!checkUserExpire(user) && <CustomSidebar />}
            <div
              className={`${
                showMore && location !== '/' ? classes.sidebarOpen : ''
              }`}
              style={{ position: 'relative', width: '100%', overflowY: 'auto'  }}
            >
              <div 
                className={classes.contentWrapper} 
                style={
                  showOnboardingBubble 
                  ? { paddingBottom: 80 }: {}
                }
              >
                {children}
                </div>
              <Footer />
            </div>
            <Suspense>
              {showOnboardingBubble && <OnboardingBubble left={showMore ? 320 : 80}/>}
            </Suspense>
          </div>
        )}
      </div>
    );
  }
);
