import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

export interface MultiSelectFilterOption<K, V> {
  name: K;
  value: V;
  [propName: string]: any;
}

interface MultiSelectFilterProps<K, V> {
  options: Array<MultiSelectFilterOption<K, V>>;
  label: string;
  defaultValue?: Array<MultiSelectFilterOption<K, V>>;
  width?: number | string;
  disabled?: boolean;
  loading?: boolean;
  onSelect?: (value: Array<MultiSelectFilterOption<K, V>>) => void;
  translatedKey?: string;
}

function MultiSelectFilter<K, V>({
  options,
  label,
  defaultValue = [],
  width = 280,
  disabled,
  loading = false,
  onSelect,
  translatedKey,
}: MultiSelectFilterProps<K, V>) {
  const { t } = useTranslation();
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [selected, setSelected] = useState<
    Array<MultiSelectFilterOption<K, V>>
  >([]);

  useEffect(() => {
    if (defaultValue && options) {
      const mapdefaultValue = defaultValue.map(el => el.value);
      const defaultSelectedOptions: MultiSelectFilterOption<K, V>[] = [];
      options.map(option => {
        if (mapdefaultValue.indexOf(option.value) !== -1) {
          defaultSelectedOptions.push(option);
        }
      });
      setSelected(defaultSelectedOptions);
    }
  }, [options]);

  return (
    <FormControl
      style={{
        width: width ?? 280,
      }}
    >
      <InputLabel
        id="multi-select-label"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
        variant="standard"
      >
        {label}
        {loading && (
          <CircularProgress
            style={{
              width: '12px',
              height: '12px',
            }}
          />
        )}
      </InputLabel>
      <Select
        labelId="multi-select-input"
        variant="standard"
        multiple
        value={selected}
        onChange={(
          e: SelectChangeEvent<Array<MultiSelectFilterOption<K, V>>>
        ) => {
          setHasChanged(true);
          setSelected(e.target.value as Array<MultiSelectFilterOption<K, V>>);
        }}
        renderValue={(value: Array<MultiSelectFilterOption<K, V>>) => {
          let res: K | string = '';
          value.forEach(
            (item: MultiSelectFilterOption<K, V>, index: number) => {
              const resItem = translatedKey
                ? t(`${translatedKey}:${item.name}`)
                : item.name;
              if (index == 0) res = resItem;
              else res = res + ', ' + resItem;
            }
          );
          return res;
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 360,
              width: width ?? 280,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'menu',
        }}
        disabled={disabled}
        onClose={() => {
          if (hasChanged) {
            setHasChanged(false);
            if (onSelect !== undefined) onSelect(selected);
          }
        }}
      >
        {options &&
          options.map((option: MultiSelectFilterOption<K, V>) => {
            return (
              <MenuItem
                disabled={disabled}
                key={`multi-select-option-${option.value}`}
                value={option as any}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={
                      !!selected.find(
                        (item: MultiSelectFilterOption<K, V>) =>
                          item.value === option.value
                      )
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    translatedKey
                      ? t(`${translatedKey}:${option.name}`)
                      : (option.name as string)
                  }
                  sx={{ whiteSpace: 'break-spaces' }}
                />
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}

export default MultiSelectFilter;
