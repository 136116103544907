import { Theme } from '@mui/system';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  exposuresWrapper: {
    width: '100%',
  },
  filtersWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0px',
    },
  },
  loadingWrapper: {
    width: '100%',
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
  },
  substitutionsWrapper: {
    width: '100%',
  },
}));

export default useStyles;
