import { createSlice } from '@reduxjs/toolkit';
import { RiskAssessmentRow } from 'interfaces/work-procedure/RiskAssessmentInterface';
import { fetchRiskAssessments } from './thunks';
import { PaginationResponse } from 'interfaces/common/PaginationInterface';

export interface RiskAssessmentState {
  loading: boolean;
  riskAssessmentList: Array<RiskAssessmentRow>;
  totalCount: number;
  substanceDetailId: string|null;
}

const initialState: RiskAssessmentState = {
  loading: false,
  riskAssessmentList: [],
  totalCount: 0,
  substanceDetailId: null,
};

const RiskAssessmentSlice = createSlice({
  name: 'risk-assessment',
  initialState,
  reducers: {
    setSubstanceDetailId(state, action: { payload: string|null }) {
      state.substanceDetailId = action.payload
    },
  },
  extraReducers: (builder: any) => {
    builder
      /* fetchRiskAssessments */
      .addCase(
        fetchRiskAssessments.fulfilled,
        (
          state: RiskAssessmentState,
          action: { payload: PaginationResponse<RiskAssessmentRow> }
        ) => {
          state.riskAssessmentList = action.payload.results;
          state.totalCount = action.payload.count;
          state.loading = false;
        }
      )
      .addCase(fetchRiskAssessments.pending, (state: RiskAssessmentState) => {
        state.loading = true;
      })
      .addCase(fetchRiskAssessments.rejected, (state: RiskAssessmentState) => {
        state.loading = false;
      });
  },
});

export const { 
  setSubstanceDetailId,
} = RiskAssessmentSlice.actions;
export default RiskAssessmentSlice.reducer;
