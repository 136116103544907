import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  messageDisabled: {
    display: 'none',
  },
  messageActive: {
    display: 'block',
    position: 'fixed',
    width: '60%',
    left: '20%',
    top: 0,
    zIndex: 99,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      left: 0,
    },
  },
}));

export default useStyles;
