import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { isMobile } from 'utils/helper';

const useStyles = makeStyles((theme: Theme) => ({
  uploadFileText: {
    marginTop: 4,
    marginLeft: 6,
    color: '#626DF9',
    cursor: 'pointer',
    fontWeight: 600,
  },
  uploadFile: {
    width: '37%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '15px !important',
    [theme.breakpoints.down('sm')]: {
      width: '55%',
    },
  },
  fileItem: {
    paddingTop: 5,
    paddingBottom: 5,
    marginBottom: 5,
    marginLeft: 24,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#626DF9',

    alignItems: 'center',
    fontSize: '12px',

    height: 20,
    textAlign: 'center',
    display: 'flex',
    // borderRadius: 30,
    // borderBottom: '1px solid gainsboro',

    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  fileListWrapper: {
    maxWidth: '95%',
    position: 'relative',
    paddingTop: 10,
    // display: 'flex',
    // flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      transform: 'initial',
      alignItems: 'center'
    }
  },
  clearIcon: {
    height: 16,
    width: 16,
    marginLeft: !isMobile() ? '88%': '80%',
    position: 'absolute',
    color: 'grey',
    '&:hover': {
      color: 'black',
    }
  },
  fileSizeText: {
    color: 'dimgray',
    marginLeft: 10
  }
}));

export default useStyles;
