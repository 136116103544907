import { useTranslation } from 'react-i18next';

import DesktopDialog from 'components/commons/desktop-dialog';
import React from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './styles';
import { TextField } from '@mui/material';
import FormGrid from 'components/commons/form-grid';
import SubmitButton from 'components/commons/submit-button';
import { trimString } from 'utils/helper';
import { fetchImpersonateUser } from 'services/user/thunks';
import { AppDispatch } from 'constant';

interface ImpersonateUserPopupProps {
  onClose:() => void;
  open: boolean;
}

const ImpersonateUserPopup = ({ 
  onClose, 
  open 
}: ImpersonateUserPopupProps) => {
  const classes: any = useStyles();
  const { t } = useTranslation(['common']);
  const refContainer = React.useRef(null);
  const dispatch: AppDispatch = useDispatch();
  const [errors, setErrors] = React.useState<Array<string>>([]);

  const onSubmit = () => {
    let formData: any = refContainer.current;
    const email = trimString(formData?.email.value);
    if (!email) {
      setErrors([t('common:email_not_empty')]);
      return;
    }
    setErrors([]);
    return new Promise((resolve, reject) => {
      dispatch(
        fetchImpersonateUser({
          email: email
        })
      ).then(() => {
        resolve(true);
        onClose();
      }).catch(() => {
        reject(false);
      });
    });
  }

  return (
    <DesktopDialog
      onClose={() => {
        onClose();
      }}
      open={open}
      title={t('common:impersonate_user')}
      actions={
        <SubmitButton
          form="impersonateUser"
          variant="contained"
          color={'primary'}
          onSave={onSubmit}
        >
          {t('common:impersonate')}
        </SubmitButton>
      }
    >
      <div className={classes.formWrapper}>
        <form id="impersonateUser" name="impersonateUser" ref={refContainer}>
          <div className={classes.wrapper}>
            <div className={classes.column}>
              <FormGrid className={classes.inputRow}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label={`${t('common:email')}`}
                  required={true}
                  type="email"
                  variant='standard'
                />
              </FormGrid>
            </div>
          </div>
          <div className={classes.errorsRow}>
            {errors.map((el, index) => {
              return <p key={index} className={classes.errorText}>{el}</p>;
            })}
          </div>
        </form>
      </div>
    </DesktopDialog>
  );
};

export default ImpersonateUserPopup;
