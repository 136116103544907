import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    margin: 0,
    height: 'fit-content',
    padding: '10px 20px',
    textAlign: 'right',
    backgroundColor: theme.palette.backgroundColor.main,
    color: '#000000',
    zIndex: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 15,
    '& a': {
      color: '#000000',
      textDecoration: 'none',
      fontSize: 12,
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '5px 30px',
      '& a': {
        fontSize: 11,
      },
    },
    '& a:hover': {
      color: '#626DF9',
    }
  },
  includeEmergencyAgencyInfo: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    }
  },
  emergencyAgencyInfo: {
    display: 'flex',
    flex: 1,
    gap: 30,
    '&>div': {
      textAlign: 'left',
    },
    '& p': {
      fontSize: 12,
      textAlign: 'left',
    },
    '& a': {
      marginLeft: 0,
      textAlign: 'left',
      whiteSpace: 'normal'
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
      justifyContent: 'center',
    }
  },
  emergencyTitle: {
    paddingBottom: 3,
    marginBottom: 3,
    borderBottom: '1px solid rgb(128 128 128 / 30%)',
  }
}));

export default useStyles;
