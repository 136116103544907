import { createAsyncThunk } from '@reduxjs/toolkit';
import { getChemicalRegulationList } from 'api';
import {
  ChemicalRegulationList,
  GetChemicalRegulationListResponse,
} from 'interfaces/administration/SettingFunctionInterface';
import { ApiError, ErrorPayload } from 'interfaces/common/ApiResponseInterface';

export const fetchChemicalRegulationList = createAsyncThunk<
  Array<ChemicalRegulationList>,
  undefined,
  {
    rejectValue: ErrorPayload;
  }
>('setting/fetchChemicalRegulationList', async (_, { rejectWithValue }) => {
  const response: GetChemicalRegulationListResponse | ApiError =
    await getChemicalRegulationList();

  if (response.status !== 200)
    return rejectWithValue(response.data as ErrorPayload);

  return response.data as Array<ChemicalRegulationList>;
});
