import { getI18n } from '../utils/i18n';
import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';

export function LanguageProvider({
  language,
  children,
}: {
  language?: string;
  children: React.ReactNode;
}) {
  const i18n = getI18n({ lng: language });
  useEffect(() => {
    if (language && i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}
