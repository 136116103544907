import customAxiosInstance from 'api/axios-client';
import axiosAuthoringInstance from 'api/axios-client';
import defaultAxiosInstance from 'api/axios-client/default';
import { BACKEND_URL } from 'api/constants';

export const createWorkProcedure = (name, description, frequency) => {
  return customAxiosInstance
    .post(`workProcedures/`, {
      description: description,
      name: name,
      frequency: frequency,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const linkWorkProcedureToLocation = (workProcedureId, data) => {
  return customAxiosInstance
    .post(`workProcedures/${workProcedureId}/locations/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const createSubstanceHazard = (workProcedureSubstanceId, data) => {
  return customAxiosInstance
    .post(`riskEvaluations/${workProcedureSubstanceId}/hazards/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const createWorkProcedureHazard = (workProcedureId, data) => {
  return customAxiosInstance
    .post(`workProcedures/${workProcedureId}/hazards/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const createWorkProcedureRevisionHazard = (
  workProcedureId,
  revisionId,
  data
) => {
  return customAxiosInstance
    .post(
      `workProcedures/${workProcedureId}/hazards/?revision_id=${revisionId}`,
      data
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const createSDSRiskEvaluation = data => {
  return customAxiosInstance
    .post(`riskEvaluations/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const createChildSds = (riskEvaluationId, data) => {
  return axiosAuthoringInstance
    .post(`riskEvaluations/${riskEvaluationId}/childSds/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const linkOtherSdsToSameRiskEvaluation = (riskEvaluationId, data) => {
  return axiosAuthoringInstance
    .post(`riskEvaluations/${riskEvaluationId}/linkOtherSds/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const unlinkOtherSdsRiskEvaluation = (riskEvaluationId, data) => {
  return axiosAuthoringInstance
    .post(`riskEvaluations/${riskEvaluationId}/unlinkOtherSds/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const confirmReadWorkProcedure = workProcedureId => {
  return axiosAuthoringInstance
    .post(`/workProcedures/${workProcedureId}/confirm/`, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const addUserToWorkProcedure = (workProcedureId, userIdList) => {
  return axiosAuthoringInstance
    .post(`workProcedures/${workProcedureId}/users/`, { user_ids: userIdList })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const addRiskEvaluationHazardPPE = (id, data) => {
  return axiosAuthoringInstance
    .post(`riskEvaluations/hazards/${id}/ppe/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const addWorkProcedureHazardPPE = (id, data) => {
  return axiosAuthoringInstance
    .post(`workProcedures/hazards/${id}/ppe/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const unlinkWorkProcedureRevisionSubstance = (workProcedureId, data) => {
  return axiosAuthoringInstance
    .post(`workProcedures/${workProcedureId}/substances/unlink/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const archiveRiskEvaluation = id => {
  return axiosAuthoringInstance
    .post(`/riskEvaluations/${id}/archive/`, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const linkSubstanceRiskToWorkProcedure = (
  workProcedureId,
  revisionId,
  riskAssessmentId
) => {
  return axiosAuthoringInstance
    .post(
      `/workProcedures/${workProcedureId}/substances/link/?revision_id=${revisionId}`,
      { risk_assessment_id: riskAssessmentId }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const approveWorkProcedureRevision = (id, data) => {
  return axiosAuthoringInstance
    .post(`workProcedureRevisions/${id}/approve/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const createRevisionDraft = id => {
  return axiosAuthoringInstance
    .post(`workProcedureRevisions/${id}/draft/`, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const publicInstruction = token => {
  return defaultAxiosInstance
    .post(`${BACKEND_URL}/sds-admin/public-instructions/`, { object: token })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
