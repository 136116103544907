import React from 'react';
import { Snackbar, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type CustomSnackbarProps = {
  onClose: () => void;
  text: string[];
  onClick: () => void;
  actionLabel?: string;
  className?: string;
  autoHide?: boolean
};

export default function CustomSnackbar({
  onClose,
  text,
  onClick,
  actionLabel,
  className,
  autoHide = true
}: CustomSnackbarProps) {
  const { t } = useTranslation();

  return (
    <Snackbar
      className={className ?? ''}
      open
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      message={
        <div>
          {text.map((value, index) => (
            <p
              key={index}
              dangerouslySetInnerHTML={{
                __html: value,
              }}
            />
          ))}
        </div>
      }
      autoHideDuration={autoHide ? 5000 : null}
      action={
        <React.Fragment>
          <Button size="small" color="inherit" onClick={onClick}>
            {(actionLabel ?? t("common:close") )?.toUpperCase()}
          </Button>
        </React.Fragment>
      }
    />
  );
}

