import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tableRow: {
    cursor: 'pointer',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
}));

export default useStyles;
