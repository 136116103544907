import { Icon } from "@mui/material"

interface RiskIconProps  {
    width: number;
    height: number;
    color?: string;
}

const RiskIcon = ({width, height, color}: RiskIconProps) => (
    <Icon>
        <svg 
            width={width ? width : 25} 
            height={height ? height : 25} 
            version="1.1" 
            viewBox="140 130 500 500" 
            xmlns="http://www.w3.org/2000/svg"
            fill={color ? color : 'black'}
        >
            <path d="m383.24 248.39c39.051 1.7266 74.492 17.344 101.49 42.035l44.848-44.848c-38.516-36.129-89.785-58.82-146.34-60.617zm27.711 105.03 88.375 153.07c7.2656 12.586 7.2656 27.773 0 40.359-7.2656 12.586-20.418 20.18-34.953 20.18h-176.75c-14.531 0-27.684-7.5938-34.953-20.18-7.2656-12.586-7.2656-27.773 0-40.359l88.379-153.07c7.2656-12.586 20.418-20.18 34.953-20.18 14.535 0.003906 27.688 7.5977 34.953 20.18zm-34.953 157.27c-7.4727 0-13.535 6.0586-13.535 13.535 0 7.4727 6.0586 13.535 13.535 13.535 7.4727 0 13.535-6.0586 13.535-13.535 0.003906-7.4727-6.0586-13.535-13.535-13.535zm0-24.16c5.6836 0 10.355-4.5039 10.559-10.184l2.668-73.957c0.10938-2.9766-0.88672-5.5742-2.9531-7.7148-2.0664-2.1406-4.6289-3.2305-7.6016-3.2305h-5.3438c-2.9766 0-5.5391 1.0898-7.6055 3.2305-2.0664 2.1406-3.0625 4.7422-2.9531 7.7148l2.668 73.957c0.21094 5.6836 4.8828 10.184 10.562 10.184zm118.98-185.86 44.848-44.848c35.668 38.031 58.25 88.473 60.555 144.15 0.10547 2.5547-0.73828 4.7969-2.5156 6.6445-1.7695 1.8438-3.9766 2.7891-6.5352 2.7891h-45.324c-4.8203 0-8.7891-3.7695-9.043-8.5781-2.0234-38.523-17.57-73.461-41.984-100.16zm-126.22-115.71c-56.547 1.7969-107.81 24.488-146.34 60.617l44.848 44.848c27.004-24.695 62.445-40.309 101.49-42.035zm-156.58 70.867c-35.664 38.023-58.25 88.469-60.555 144.15-0.10547 2.5547 0.73828 4.7969 2.5156 6.6445 1.7695 1.8438 3.9766 2.7891 6.5352 2.7891h45.324c4.8203 0 8.7891-3.7695 9.043-8.5781 2.0234-38.523 17.57-73.461 41.98-100.15z" fillRule="evenodd"/>
        </svg>
    </Icon>
)

export default RiskIcon;
