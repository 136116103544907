import { CircularProgress, Dialog, DialogContent } from '@mui/material'
import useStyles from './styles';

const LoadingModal = () => {
  const classes = useStyles();

  return (
    <Dialog open className={classes.loadingModal}>
      <DialogContent style={{ overflow: 'hidden' }}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingModal;
