import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  {
    root: {
      display: 'grid',
      gridGap: '24px',
    },

    actions: {
      display: 'grid',
      gridGap: '24px',
      gridTemplateColumns:
        'max-content max-content max-content max-content max-content',
    },
  },
  { name: 'FormGrid' }
);

export default memo<
  React.HTMLAttributes<HTMLDivElement> & { columns?: number }
>(({ columns, className, ...props }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.root + ' ' + className}
      style={{
        gridTemplateColumns: columns
          ? 'repeat(' + columns + ', 1fr)'
          : undefined,
      }}
      {...props}
    />
  );
});

const ActionsRow = memo<React.HTMLAttributes<HTMLDivElement>>((props) => {
  const classes = useStyles();
  return <div className={classes.actions} {...props} />;
});

export { ActionsRow };
