import axiosAuthoringInstance from 'api/axios-client';

const updateUserLanguage = (userId, languageCode) => {
  return axiosAuthoringInstance
    .patch(`/user/${userId}/`, { language_code: languageCode })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateUserLanguageNotUpdateOwner = (userId, languageCode) => {
  return axiosAuthoringInstance
    .patch(`/user/${userId}/changeUserLanguage/`, { code: languageCode })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateInventoryManagerSettings = (payload) => {
  return axiosAuthoringInstance
    .patch('/v2/user/inventoryManagerSettings/', payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateUser = (userId, data) => {
  return axiosAuthoringInstance
    .patch(`/user/${userId}/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateQrLoginUser = (userId, data) => {
  return axiosAuthoringInstance
    .patch(`/user/${userId}/updateQrLoginUser/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const changePassword = (data) => {
  return axiosAuthoringInstance
    .patch('/user/changePassword/', data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateLastLogin = () => {
  return axiosAuthoringInstance
    .patch('/auth/last-login/')
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export {
  changePassword,
  updateUser,
  updateUserLanguage,
  updateUserLanguageNotUpdateOwner,
  updateInventoryManagerSettings,
  updateLastLogin,
  updateQrLoginUser,
};
