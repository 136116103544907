import { reducer as formReducer } from 'redux-form';
import pageReducer from './pageReducer/pageReducer';
import workProcedureReducer from 'services/work-procedure/slice';
import substanceReducer from 'services/substance/slice';
import commonReducer from 'services/common/slice';
import locationManagementReducer from 'services/location-management/slice';
import locationReducer from 'services/location/slice';
import userReducer from 'services/user/slice';
import userManagementReducer from 'services/user-management/slice';
import homeReducer from 'services/home/slice';
import riskAssessmentReducer from 'services/risk-assessment/slice';
import permissionReducer from 'services/permission/slice';
import settingReducer from 'services/setting/slice';

import { configureStore } from '@reduxjs/toolkit';
const store = configureStore({
  reducer: {
    pageReducer: pageReducer,
    workProcedure: workProcedureReducer,
    substance: substanceReducer,
    common: commonReducer,
    locationManagement: locationManagementReducer,
    location: locationReducer,
    user: userReducer,
    form: formReducer,
    home: homeReducer,
    userManagement: userManagementReducer,
    riskAssessment: riskAssessmentReducer,
    permission: permissionReducer,
    setting: settingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
    }),
});
window.store = store;
export default store;
