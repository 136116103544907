import React from 'react';
import { Icon } from '@mui/material';

const MyWorkProcedureIcon: React.FC<{
  fontSize: string | number;
  color?: string;
}> = ({ fontSize, color }) => (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 475.000000 500.000000"
      width={24}
      height={24}
      preserveAspectRatio="xMidYMid meet"
      fill={color ? color : 'rgba(58, 53, 65, 0.87)'}
      fontSize={fontSize}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      >
        <path
          d="M417 4483 c-10 -9 -8 -1225 1 -1241 7 -10 124 -12 553 -10 l544 3 3
233 2 232 155 0 155 0 2 -782 3 -783 310 -5 310 -5 3 -232 2 -233 550 0 550 0
0 624 c0 560 -2 624 -16 630 -23 9 -1057 7 -1071 -2 -9 -6 -13 -69 -15 -237
l-3 -230 -150 0 -150 0 -3 628 -2 628 152 -3 153 -3 5 -230 5 -230 548 -3 547
-2 -2 627 -3 628 -545 0 -545 0 -5 -230 -5 -230 -467 -3 -468 -2 -2 232 -3
233 -546 3 c-300 1 -549 -1 -552 -5z"
        />
        <path
          d="M3893 2541 c-228 -78 -342 -334 -242 -545 33 -72 111 -151 182 -186
163 -80 312 -57 447 67 189 174 163 474 -53 614 -106 68 -227 86 -334 50z
m233 -120 c126 -57 193 -194 159 -324 -36 -138 -138 -217 -278 -217 -104 1
-193 53 -243 142 -26 48 -29 62 -29 143 0 80 3 95 27 136 32 54 77 95 133 120
55 25 175 25 231 0z"
        />
        <path
          d="M3855 1529 c-255 -22 -488 -106 -609 -218 -78 -73 -86 -98 -86 -293
l0 -168 850 0 850 0 0 168 c0 195 -8 220 -86 293 -129 120 -337 192 -624 219
-128 11 -151 11 -295 -1z m410 -124 c234 -34 407 -109 461 -200 22 -39 25 -52
22 -137 l-3 -93 -735 0 -735 0 -3 89 c-2 73 1 95 19 132 44 93 225 175 459
209 120 18 394 18 515 0z"
        />
      </g>
    </svg>
);

export default MyWorkProcedureIcon;
