import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'constant';
import { PermissionState } from './slice';
import {
  PLAN_PERMISSION,
  LOCATION_ACCESS_PERMISSION,
} from 'interfaces/permission';
import { PERMISSION } from 'enums/permissions.enums';

export const planPermissionSelector = createSelector<
  [(state: RootState) => PermissionState],
  PLAN_PERMISSION | null
>([(state) => state.permission], (permission) => permission.planPermission);

export const rolePermissionSelector = createSelector<
  [(state: RootState) => PermissionState],
  LOCATION_ACCESS_PERMISSION | null
>(
  [(state) => state.permission],
  (permission) => permission.locationAccessPermission
);

export const openVisionPermissionSelector = createSelector<
  [(state: RootState) => PermissionState],
  Array<PERMISSION> | null
>(
  [(state) => state.permission],
  (permission) => permission.openVisionPermission
);
