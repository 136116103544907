enum SUBSCRIPTION_PLAN {
  Basic = 2,
  Premium = 4,
  Pro = 6,
  Enterprise = 7,
}

enum SUBSCRIPTION_PLAN_LABEL {
  free = 'free',
  basic = 'basic',
  premium = 'premium',
  pro = 'pro',
  demo = 'Demo Inv Mgr',
  enterprise = 'enterprise',
}

enum SUBSCRIPTION_PLAN_DISPLAY_NAME {
  FREE = 'Free',
  BASIC = 'Basic',
  STANDARD = 'Standard',
  PREMIUM = 'Premium',
  SMALL_ENTERPRISE = 'Small Enterprise',
  PRO = 'Pro',
  ENTERPRISE = 'Enterprise',
  DEMO_INV_MNG = 'Demo Inv Mngr',
  ECOMMERCE_FREE = 'eCommerce Free',
}

enum BILLING_TYPE {
  monthly = 'monthly',
  annually = 'annually',
}

export {
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PLAN_LABEL,
  BILLING_TYPE,
  SUBSCRIPTION_PLAN_DISPLAY_NAME,
};
