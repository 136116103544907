import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 18,
    backgroundColor: '#ae8145',
    color: '#FFFFFF',
    fontSize: 20,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default useStyles;
