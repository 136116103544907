import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { openMessage } from 'store/pageAction/pageAction';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const MessagePopup = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(['permissions_notifications']);
  const open = useSelector((state: any) => state.pageReducer.openMessage);
  const text = useSelector((state: any) => state.pageReducer.popupMessageText);
  const option = useSelector((state: any) => state.pageReducer.popupMessageOption);
  React.useEffect(() => {
    if (open) {
      setTimeout(() => dispatch(openMessage(false)), option?.time ? option?.time : 4000);
    }
  }, [open]);

  return (
    <div className={open ? classes.messageActive : classes.messageDisabled}>
      <Alert
        severity= {option?.type ? option?.type : 'warning'}
        sx={{ height: '50px' }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => dispatch(openMessage(false))}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>
          {text ? text : t('permissions_notifications:module_not_active')}
        </AlertTitle>
      </Alert>
    </div>
  );
};

export default MessagePopup;
