import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'constant';
import { LocationState } from './slice';
import {
  ArchiveLocationItem,
  LocationItem,
} from 'interfaces/location-management';
import { UserLocationAccess } from 'interfaces/administration/UserInterface';

export const loadingSelector = createSelector<
  [(state: RootState) => LocationState],
  boolean
>([(state) => state.location], (location) => location.loading);

export const locationDataSelector = createSelector<
  [(state: RootState) => LocationState],
  Array<LocationItem>
>([(state) => state.location], (location) => location.locationData);

export const commonLocationDataSelector = createSelector<
  [(state: RootState) => LocationState],
  Array<LocationItem>
>([(state) => state.location], (location) => location.commonLocationData);

export const favoriteLocationDataSelector = createSelector<
  [(state: RootState) => LocationState],
  Array<LocationItem>
>([(state) => state.location], (location) => location.favoriteLocationData);

export const archivedLocationDataSelector = createSelector<
  [(state: RootState) => LocationState],
  Array<ArchiveLocationItem>
>([(state) => state.location], (location) => location.archivedLocationData);

export const locationAccessSelector = createSelector<
  [(state: RootState) => LocationState],
  Array<UserLocationAccess>
>([(state) => state.location], (location) => location.locationAccess);
