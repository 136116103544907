import {
  GetStatisticsResponseDto,
  TutorialVideosAndGuideArticles,
} from 'interfaces/home';
import { fetchStatistics, fetchVideosAndArticles } from './thunks';
import { createSlice } from '@reduxjs/toolkit';

export interface HomeState {
  statistics: GetStatisticsResponseDto | null;
  statisticsLoading: boolean;
  hideAllPanels: boolean;
  introVideo: { [key: string]: string };
  videosAndArticles: TutorialVideosAndGuideArticles[];
  loadedVideosAndArticles: boolean;
  weeklyWebinarUrl: string;
}

const initialState: HomeState = {
  statistics: null,
  statisticsLoading: false,
  hideAllPanels: false,
  introVideo: {},
  videosAndArticles: [],
  loadedVideosAndArticles: false,
  weeklyWebinarUrl: "",
};

const HomeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHideAllPanels(state, action: { payload: boolean }) {
      state.hideAllPanels = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchStatistics.pending, (state: HomeState) => {
        state.statisticsLoading = true;
      })
      .addCase(
        fetchStatistics.fulfilled,
        (state: HomeState, action: { payload: GetStatisticsResponseDto }) => {
          state.statistics = action.payload;
          state.statisticsLoading = false;
        }
      )
      .addCase(fetchStatistics.rejected, (state: HomeState) => {
        state.statistics = null;
        state.statisticsLoading = false;
      })
      .addCase(
        fetchVideosAndArticles.fulfilled,
        (
          state: HomeState,
          action: {
            payload: {
              videosAndArticles: TutorialVideosAndGuideArticles[];
              introVideo: { [key: string]: string };
              weeklyWebinarUrl: string;
            };
          }
        ) => {
          state.loadedVideosAndArticles = true;
          state.videosAndArticles = action.payload.videosAndArticles;
          state.introVideo = action.payload.introVideo;
          state.weeklyWebinarUrl = action.payload.weeklyWebinarUrl;
        }
      )
      .addCase(fetchVideosAndArticles.rejected, (state: HomeState) => {
        state.videosAndArticles = [];
        state.introVideo = {};
        state.weeklyWebinarUrl = "";
      });
  },
});

export const { setHideAllPanels } = HomeSlice.actions;
export default HomeSlice.reducer;
