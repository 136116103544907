import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  searchInput: {
    border: 0,
    background: 'none',
    outline: 'none',
    fontFamily: '"Montserrat",sans-serif',
    fontSize: 14
  },
  searchWrapper: {
    borderRadius: '6px',
    padding: '8px 20px',
    backgroundColor: theme.palette.colors.white,
    color: theme.palette.colors.text,
    boxShadow: 'rgb(58 53 65 / 40%) 0px 2px 5px 0px',
    display: 'flex',
    width: '720px',
    [theme.breakpoints.down('xl')]: {
      width: '540px'
    },
    [theme.breakpoints.down('lg')]: {
      width: '425px'
    },
    [theme.breakpoints.down('md')]: {
     width: '360px',
    },
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flex: 1,
     },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  searchIconContent: {
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
  },
  searchCategory: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#26272F',
    margin: '10px 0px',
    display: 'flex',
    gap: '4px',
    alignItems: 'center'
  },
  backIcon: {
    "&:hover": {
      cursor: 'pointer'
    }
  },
  searchSuggestion: {
    border: '1px solid #cccccc8f',
    borderRadius: '6px',
    width: 320,
    backgroundColor: theme.palette.colors.white,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
  },
  searchList: {
    display: 'flex',
    flexDirection: 'column',
  },
  redoText: {
    fontSize: '14px',
    textDecoration: 'underline',
    color: theme.palette.colors.primary,
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    "&:hover": {
      cursor: 'pointer'
    }
  },
  sdsResultName: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: '2px',
    fontWeight: 600
  },
  sdsResultSupplier: {
    fontSize: '14px',
    fontWeight: 500,
  },
  sdsResultWrapper: {
    width: '100%',
    color: '#5B5B5B', 
    whiteSpace: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    borderBottom: '1px solid #e0e0e0',
    margin: '0px 0px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },
  sdsResultInfo: {
    flex : 1,
    color: '#5B5B5B', 
    whiteSpace: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },
  searchResultAction: {
    display: 'flex',
    gap: 5
  }
}));

export default useStyles;
