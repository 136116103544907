import { createSlice } from '@reduxjs/toolkit';
import { SubstanceInterface } from 'interfaces/substance';
import { fetchSubstance, fetchSds } from './thunks';
import { PaginationResponse } from 'interfaces/common/PaginationInterface';

export interface SubstanceState {
  loading: boolean;
  substanceList: Array<SubstanceInterface>;
  totalSubstanceList: number;
  sdsList: Array<SubstanceInterface>;
  totalSdsList: number;
  reload: boolean;
  bulkSelected: Array<SubstanceInterface>;
}

const initialState: SubstanceState = {
  loading: false,
  substanceList: [],
  totalSubstanceList: 0,
  sdsList: [],
  totalSdsList: 0,
  reload: false,
  bulkSelected: [],
};

const SubstanceSlice = createSlice({
  name: 'substance',
  initialState,
  reducers: {
    setSubstanceList(state, action: { payload: Array<SubstanceInterface> }) {
      state.substanceList = action.payload;
    },
    setSubstanceItem(state, action: { payload: SubstanceInterface }) {
      const newListItem = state.substanceList.map(
        (item: SubstanceInterface) => {
          if (item.id === action.payload.id) return action.payload;
          return item;
        }
      );
      state.substanceList = newListItem;
    },
    setTotalSubstanceList(state, action: { payload: number }) {
      state.totalSubstanceList = action.payload;
    },
    setSdsList(state, action: { payload: Array<SubstanceInterface> }) {
      state.sdsList = action.payload;
    },
    setSdsItem(state, action: { payload: SubstanceInterface }) {
      const newListItem = state.sdsList.map((item: SubstanceInterface) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
      state.sdsList = newListItem;
    },
    setTotalSdsList(state, action: { payload: number }) {
      state.totalSdsList = action.payload;
    },
    setReload(state, action: { payload: boolean }) {
      state.reload = action.payload;
    },
    setBulkSelected(state, action: { payload: Array<SubstanceInterface> }) {
      state.bulkSelected = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder
      /* fetchSubstance */
      .addCase(
        fetchSubstance.fulfilled,
        (
          state: SubstanceState,
          action: { payload: PaginationResponse<SubstanceInterface> }
        ) => {
          state.substanceList = action.payload.results;
          state.totalSubstanceList = action.payload.count;
          state.loading = false;
        }
      )
      .addCase(fetchSubstance.pending, (state: SubstanceState) => {
        state.loading = true;
      })
      .addCase(fetchSubstance.rejected, (state: SubstanceState) => {
        state.loading = false;
      })
      /* fetchSds */
      .addCase(
        fetchSds.fulfilled,
        (
          state: SubstanceState,
          action: { payload: PaginationResponse<SubstanceInterface> }
        ) => {
          state.sdsList = action.payload.results;
          state.totalSdsList = action.payload.count;
          state.loading = false;
        }
      )
      .addCase(fetchSds.pending, (state: SubstanceState) => {
        state.loading = true;
      })
      .addCase(fetchSds.rejected, (state: SubstanceState) => {
        state.loading = false;
      });
  },
});

export const { setReload, setSubstanceItem, setSdsItem, setBulkSelected } =
  SubstanceSlice.actions;
export default SubstanceSlice.reducer;
