import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingModal: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
    '& .MuiDialog-paper': {
      background: 'none',
      boxShadow: 'none',
    },
  },
});

export default useStyles;
