import { useSelector } from 'react-redux';
import { userDataSelector } from 'services/user/selectors';
import { isOwner } from 'utils/userRoleUtils';

const useSetUpStep = () => {
  const userData = useSelector(userDataSelector);
  const isFreePlan =
    userData?.customer?.inventory_subscription_plan?.plan?.plan == 'free';
  const skipStatus =
    userData?.setup_steps_status?.onboarding_step_skip_status ?? {};

  const setupSteps = [
    {
      id: 'get_compliant_today',
      hide: !isOwner(userData) || isFreePlan,
    },
    {
      id: 'setup_locations_and_access',
      hide: !userData?.customer.has_multi_location || isFreePlan,
      completed: userData?.setup_steps_status?.completed_create_locations,
    },
    {
      id: 'import_sds',
      completed: userData?.setup_steps_status?.completed_import_sds_files,
    },
    {
      id: 'create_overview_of_chemicals',
      hide: userData?.customer.has_sds_files,
      completed: userData?.setup_steps_status?.completed_add_sds_files,
    },
    {
      id: 'find_sds',
      hide: userData?.qr_login_token,
      completed: userData?.setup_steps_status?.completed_add_sds_files,
    },
    {
      id: 'create_qr_code_poster',
      hide: isFreePlan,
      completed: !!userData?.setup_steps_status?.completed_print_qr_poster,
    },
    {
      id: 'print_sds_binder',
      hide: !isFreePlan,
      completed: !!userData?.setup_steps_status?.generated_sds_binder,
    },
    {
      id: 'go_live',
      completed: !!userData?.setup_steps_status?.completed_go_live,
    },
  ];
  const showSteps = setupSteps.filter((step) => !step.hide);
  const totalSteps = showSteps.length;
  const countCompletedSteps = showSteps.filter((step) => step.completed).length;
  const setUpStepProgress = (countCompletedSteps / totalSteps) * 100;
  const countActiveSteps = showSteps.filter(
    (step) => !(step.completed || skipStatus[step.id])
  ).length;
  const hasCompletedAll = countCompletedSteps == showSteps.length;

  return { setUpStepProgress, totalSteps, countCompletedSteps, showSteps, countActiveSteps, hasCompletedAll };
};

export default useSetUpStep;
