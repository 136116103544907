import { Badge } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRef, useEffect, useState } from 'react';

const BADGE_ANIMATE_DURATION = 100;

const useStyles = makeStyles(() => ({
  badge: {
    transition: `all ${8 * BADGE_ANIMATE_DURATION}ms ease`,
    transform: 'scale(0.8)',
    marginLeft: 30,
    marginTop: -4,
    position: 'absolute',
  },
  badgeHover: {
    transition: `all ${BADGE_ANIMATE_DURATION}ms ease`,
    marginLeft: 30,
    marginTop: -4,
    transform: 'scale(1.3)',
    position: 'absolute',
  },
  badgeChild: {
    transition: `all ${8 * BADGE_ANIMATE_DURATION}ms ease`,
    transform: 'scale(0.7)',
    marginLeft: 4,
    marginTop: 5,
    position: 'absolute',
  },
  badgeHoverChild: {
    transition: `all ${BADGE_ANIMATE_DURATION}ms ease`,
    marginLeft: 4,
    marginTop: 5,
    transform: 'scale(1.2)',
    position: 'absolute',
  },
}));

const usePrevious = (value: number): number => {
  const ref = useRef<number>(0);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

interface CustomBadgeProps {
  value: number;
  isChild?: boolean;
  color?:
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'primary'
    | 'secondary';
}

const CustomBadge = ({ value, isChild = false, color }: CustomBadgeProps) => {
  const classes = useStyles();
  const [animated, setAnimated] = useState<boolean>(false);
  const preValue = usePrevious(value);

  useEffect(() => {
    if (value == 0 || preValue == 0 || (value == 1 && preValue < value)) return;
    setAnimated(true);
    setTimeout(() => {
      setAnimated(false);
    }, 5 * BADGE_ANIMATE_DURATION);
  }, [value]);

  return (
    <Badge
      badgeContent={value}
      className={
        animated
          ? isChild
            ? classes.badgeHoverChild
            : classes.badgeHover
          : isChild
          ? classes.badgeChild
          : classes.badge
      }
      max={99}
      color={color}
      sx={
        !color || color == 'default'
          ? {
              '& .MuiBadge-badge': {
                backgroundColor: 'red',
                color: 'white',
                width: value < 10 ? 20 : value <= 99 ? 25 : 30,
              },
            }
          : {
              '& .MuiBadge-badge': {
                width: value < 10 ? 20 : value <= 99 ? 25 : 30,
              },
            }
      }
    />
  );
};

export default CustomBadge;
