import { Icon } from '@mui/material';

const MenuIcon = ({ color }: {color: string}) => (
  <Icon style={{ height: '1.3em' }}>
    <svg
      width={30}
      height={30}
      viewBox="0 0 24 24"
      fill={color ? color : 'white'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z"
      />
    </svg>
  </Icon>
);

export default MenuIcon;
