import { useState } from 'react';
import { 
  Tooltip, 
  TooltipProps,
  ClassNameMap,
  ClickAwayListener,
} from '@mui/material';
import useStyles from './styles';

interface CustomTooltipProps extends TooltipProps {
  mobileEnabled?: boolean,
}

const CustomTooltip = ({ 
  title, 
  placement, 
  children, 
  mobileEnabled = false,
  ...props 
}: CustomTooltipProps) => {
  const classes: ClassNameMap = useStyles();
  const [show, setShow] = useState<boolean>(false);

  if (mobileEnabled) {
    return (
      <ClickAwayListener 
        onClickAway={() => setShow(false)}
      >
        <Tooltip
          open={show}
          onClose={() => setShow(false)}
          classes={{ tooltip: classes.tooltip }}
          title={title}
          placement={placement}
          arrow
          {...props}
        >
          <span
            onClick={(e) => {
              e.stopPropagation()
              setShow(true)
            }}
            onMouseEnter={() => setShow(true)}
          >
            {children}
          </span>
        </Tooltip>
      </ClickAwayListener>
    );
  }
  
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={title}
      placement={placement}
      arrow
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
