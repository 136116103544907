import React from 'react';
import { Icon } from '@mui/material';

const ReportIcon: React.FC<{ fontSize: string | number; marginRight: any }> = ({
  fontSize,
  marginRight,
}) => (
  <Icon style={{ fontSize: fontSize, marginRight: marginRight }}>
    <svg
      className="svg-icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M929.510902 1005.568H94.489098C58.930196 1005.568 30.117647 977.217255 30.117647 942.240627V63.347451C30.117647 28.350745 58.930196 0 94.489098 0h835.021804C965.069804 0 993.882353 28.350745 993.882353 63.347451v878.893176c0 34.976627-28.812549 63.327373-64.371451 63.327373zM94.489098 942.822902h835.021804V71.780392H94.489098v871.04251z m770.650353-314.267608H158.860549V188.79749h706.881255v439.757804h-0.602353z m-331.073255-178.55749l-126.293333-123.663059c-1.204706-1.204706-0.602353-3.011765-1.827138-4.21647-6.144-6.023529-14.71749-8.432941-23.29098-8.432942a28.912941 28.912941 0 0 0-23.29098 8.432942c-1.244863 1.204706-0.622431 3.011765-1.867294 4.21647l-126.293334 123.683137a29.515294 29.515294 0 0 0 0 42.807216c12.247843 11.464784 31.884549 11.464784 43.530039 0l107.901491-105.552314 107.90149 105.552314c12.247843 11.464784 31.282196 11.464784 43.530039 0 11.64549-12.047059 11.64549-31.36251 0-42.827294z m258.108235-188.195137c-0.602353-1.204706-0.602353-2.409412-1.224784-3.011765-1.827137-1.807059-4.296784-1.204706-6.746353-2.409412-2.449569-1.204706-4.276706-1.807059-6.746353-3.011765-3.051922-0.622431-5.50149-1.807059-8.57349-1.807058-0.602353 0-1.224784 0-1.847216 0.582274-0.602353 0-1.204706-0.602353-2.449568-0.602353h-123.843765c-17.769412 0-31.864471 13.854118-31.864471 31.36251 0 17.488314 17.167059 29.555451 64.371451 31.382588h17.769412l-137.938823 135.127843a29.515294 29.515294 0 0 0 0 42.807216c12.247843 11.464784 31.884549 11.464784 43.530039 0l141.010823-137.537255v53.689726c0 17.488314 14.095059 31.36251 31.864471 31.362509 17.78949 0 31.884549-13.854118 31.884549-31.362509V283.507451c0-4.216471-0.602353-7.830588-2.449569-11.444706-1.204706-3.011765-3.072-5.421176-4.899137-7.850667-1.224784-1.204706-1.224784-1.807059-1.847216-2.409411zM158.860549 754.627765h449.395451V691.902745H158.860549v62.72502z m641.907451 125.470117H158.860549v-62.725019h642.509804v62.725019h-0.602353z"
        //fill="#2E323E"
      />
    </svg>
  </Icon>
);

export default ReportIcon;
