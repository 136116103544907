import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, ErrorPayload } from 'interfaces/common/ApiResponseInterface';
import {
  ArchiveLocationItem,
  GetArchivedLocationsResponse,
  GetLocationStructureResponse,
  GetLocationStructureResponseDto,
  LocationStructureInterface,
} from 'interfaces/location-management';
import {
  getArchivedLocations,
  getLocationsStructureUser,
} from 'api/inventory/get';
import { insertLocation } from 'utils/helper';

export const fetchLocationStructure = createAsyncThunk<
  LocationStructureInterface | null,
  undefined,
  {
    rejectValue: ErrorPayload;
  }
>('location/fetch-location-structure', async (_, { rejectWithValue }) => {
  const response: GetLocationStructureResponse | ApiError =
    await getLocationsStructureUser();
  if (response.status !== 200)
    return rejectWithValue(response.data as ErrorPayload);

  const responseData = response.data as GetLocationStructureResponseDto;
  return insertLocation(responseData.all, responseData.locations_access);
});

export const fetchArchivedLocations = createAsyncThunk<
  Array<ArchiveLocationItem>,
  undefined,
  {
    rejectValue: ErrorPayload;
  }
>('location/fetch-archive-locations', async (_, { rejectWithValue }) => {
  const response: GetArchivedLocationsResponse | ApiError =
    await getArchivedLocations();
  if (response.status !== 200)
    return rejectWithValue(response.data as ErrorPayload);

  return response.data as Array<ArchiveLocationItem>;
});
