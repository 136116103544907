import axiosAuthoringInstance from 'api/axios-client';

export const editExtractedValue = (sdsId, tag, new_value) => {
  return axiosAuthoringInstance
    .post(`/pdfs/${sdsId}/editExtractedValue/`, {
      tag: tag,
      new_value: new_value,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const editExtractedValueV2 = (sdsId, data) => {
  return axiosAuthoringInstance
    .patch(`/v2/substances/${sdsId}/editExtractedValue/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const editMultipleSelectCustomFieldValue = (sdsId, data) => {
  return axiosAuthoringInstance
    .patch(`/v2/substances/${sdsId}/editMultipleSelectCustomFieldValue/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const editInfoMessage = (sdsId, data) => {
  return axiosAuthoringInstance
    .patch(`/v2/substances/${sdsId}/editInfoMessage/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export const removeInfoMessage = (sdsId) => {
  return axiosAuthoringInstance
    .patch(`/v2/substances/${sdsId}/removeInfoMessage/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}