import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, getMyWorkProcedures, getWorkProcedures, post } from 'api';
import { PaginationRequest } from "interfaces/common/PaginationInterface";

export const fetchWorkProcedureInfoByID = createAsyncThunk(
  'workProcedure/fetchWorkProcedureInfoByID',
  async(
    params: {
      workProcedureId: number|string,
      selectedRevisionId: number|string,
    },
    { rejectWithValue }
  ) => {
    const response = await get(
      `workProcedures/${params.workProcedureId}/`,
      { revision_id: params.selectedRevisionId }
    );
    if (!response.data || response.data.status_code)
      return  rejectWithValue(response.data);
    return response.data;
  }
)

export const fetchWorkProcedurePPEsByID = createAsyncThunk(
  'workProcedure/fetchWorkProcedurePPEsByID',
  async(
    params: {
      workProcedureId: number|string,
      selectedRevisionId: number|string,
      languageCode: string
    },
    { rejectWithValue }
  ) => {
    const response = await get(
      `workProcedures/${params.workProcedureId}/ppe/`,
      {
        revision_id: params.selectedRevisionId
          ? params.selectedRevisionId :
          '',
        language_code: params.languageCode
      }
    );
    if (!response.data || response.data.status_code)
      return  rejectWithValue(response.data);;
    return response.data;
  }
)

export const fetchLocationWorkProcedure = createAsyncThunk(
  'workProcedure/fetchLocationWorkProcedure',
  async(
    {workProcedureId, selectedRevisionId}: {
      workProcedureId: number|string,
      selectedRevisionId: number|string
    },
    { rejectWithValue }
  ) => {
    const response = await get(
      `workProcedures/${workProcedureId}/`,
      { revision_id: selectedRevisionId }
    );
    if (!response.data || response.data.status_code)
      return  rejectWithValue(response.data);
    return {
      locations: response.data.locations,
      status: response.data.status
    };
  }
)

export const fetchUsersWorkProcedure = createAsyncThunk(
  'workProcedure/fetchUsersWorkProcedure',
  async(
    {workProcedureId, selectedRevisionId}: {
      workProcedureId: number|string,
      selectedRevisionId: number|string
    },
    { rejectWithValue }
  ) => {
    const response = await get(
      `workProcedures/${workProcedureId}/`,
      { revision_id: selectedRevisionId }
    );
    if (!response.data || response.data.status_code)
      return rejectWithValue(response.data);
    return {
      users: response.data.users,
      status: response.data.status
    };
  }
)

export const fetchSubstancesWorkProcedure = createAsyncThunk(
  'workProcedure/fetchSubstancesWorkProcedure',
  async(
    {workProcedureId, selectedRevisionId}: {
      workProcedureId: number|string,
      selectedRevisionId: number|string
    },
    { rejectWithValue }
  ) => {
    const response = await get(
      `workProcedures/${workProcedureId}/`,
      { revision_id: selectedRevisionId }
    );
    if (!response.data || response.data.status_code)
      return rejectWithValue(response.data);;
    return {
      substances: response.data.substances,
      status: response.data.status,
      missed_info: response.data.missed_info
    }
  }
)

export const createNewRevisionRequest = createAsyncThunk(
  'workProcedure/createNewRevision',
  async(
    selectedRevisionId: number|string,
    { rejectWithValue }
  ) => {
    const response = await post(
      `workProcedureRevisions/${selectedRevisionId}/draft/`,
      {}
    );
    if (!response.data || response.data.status_code)
      return  rejectWithValue(response.data);;
    return response.data.revision_id;
  }
)

export const fetchMyWorkProcedures = createAsyncThunk(
  'workProcedure/fetchMyWorkProcedures',
  async(
    params: PaginationRequest,
    { rejectWithValue }
  ) => {
    const response = await getMyWorkProcedures(params);
    if (!response.data || response.data.status_code)
      return rejectWithValue(response.data);
    return response.data;
  }
)

export const fetchWorkProcedures = createAsyncThunk(
  'workProcedure/fetchWorkProcedures',
  async(
    params: PaginationRequest,
    { rejectWithValue }
  ) => {
    const response = await getWorkProcedures(params);
    if (!response.data || response.data.status_code)
      return rejectWithValue(response.data);
    return response.data;
  }
)

