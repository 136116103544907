import { CSSProperties, MouseEvent } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  checkUserPermissions,
  validateFeatureNotAvailable,
} from 'utils/userRoleUtils';
import { setOpenDrawer } from 'services/common/slice';
import { SideBarItem } from 'interfaces/common';
import { AppDispatch } from 'constant';
/* Components */
import {
  ListItemIcon,
  List,
  ListItem,
  ListItemText,
  ClassNameMap,
} from '@mui/material';

interface CollapseListItemProps {
  listItem: Array<SideBarItem>;
  isSelected: (value: string) => boolean;
  style?: CSSProperties;
}

const CollapseListItem = ({
  listItem,
  isSelected,
  style = {},
}: CollapseListItemProps) => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();
  const dispatch: AppDispatch = useDispatch();

  return (
    <div className={classes.container} style={{ ...style }}>
      <List component="div">
        {listItem.map((item: SideBarItem) => {
          if (item.hide) return null;
          if (item.permission && !checkUserPermissions(item.permission, true))
            return null;

          return (
            <ListItem
              key={`sidebar-collapse-list-item-${item.value}`}
              className={`${classes.childMenuItem} ${
                isSelected(item.value) ? classes.menuActive : ''
              }`}
              onClick={(e: MouseEvent<HTMLLIElement>) => {
                e.stopPropagation();
                if (
                  item.permission &&
                  validateFeatureNotAvailable(item.permission)
                ) {
                  dispatch(setOpenDrawer(false));
                  return;
                }
                item.onClick !== undefined && item.onClick();
              }}
              id={item.value}
            >
              <ListItemIcon style={{ minWidth: 'auto' }}>
                {item.icon}
                {item.badge}
              </ListItemIcon>
              <ListItemText
                primary={`${t(item.translation)}`}
                className={classes.listItemMargin}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default CollapseListItem;
