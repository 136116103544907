import React from 'react';

const MyExposuresIcon: React.FC<{
  fontSize: string | number;
  color?: string;
}> = ({ fontSize, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width={24}
    height={24}
    viewBox="0 0 1000 1000"
    fill={color ? color : 'rgba(58, 53, 65, 0.87)'}
    fontSize={fontSize}
  >
    <desc>Created with Fabric.js 3.5.0</desc>
    <defs></defs>
    <g transform="matrix(32.1399 0 0 32.1399 499.9996 448.4509)" id="600109">
      <g>
        <g transform="matrix(1 0 0 1 0 0)">
          <path />
        </g>
        <g transform="matrix(1 0 0 1 0 0)">
          <path
            transform=" translate(-12, -12)"
            d="M 7 11 H 1 v 2 h 6 v -2 z m 2.17 -3.24 L 7.05 5.64 L 5.64 7.05 l 2.12 2.12 l 1.41 -1.41 z M 13 1 h -2 v 6 h 2 V 1 z m 5.36 6.05 l -1.41 -1.41 l -2.12 2.12 l 1.41 1.41 l 2.12 -2.12 z M 17 11 v 2 h 6 v -2 h -6 z m -5 -2 c -1.66 0 -3 1.34 -3 3 s 1.34 3 3 3 s 3 -1.34 3 -3 s -1.34 -3 -3 -3 z m 2.83 7.24 l 2.12 2.12 l 1.41 -1.41 l -2.12 -2.12 l -1.41 1.41 z m -9.19 0.71 l 1.41 1.41 l 2.12 -2.12 l -1.41 -1.41 l -2.12 2.12 z M 11 23 h 2 v -6 h -2 v 6 z"
          />
        </g>
      </g>
    </g>
    <g transform="matrix(9.9113 0 0 9.9113 767.5722 789.3429)" id="839145">
      <g>
        <g transform="matrix(1 0 0 1 0 0)">
          <path
            transform=" translate(-24, -24)"
            d="M 24 11.8 c 2.32 0 4.2 1.88 4.2 4.2 s -1.88 4.2 -4.2 4.2 s -4.2 -1.88 -4.2 -4.2 s 1.88 -4.2 4.2 -4.2 m 0 18 c 5.95 0 12.2 2.91 12.2 4.2 v 2.2 h -24.4 v -2.2 c 0 -1.29 6.25 -4.2 12.2 -4.2 m 0 -21.8 c -4.42 0 -8 3.58 -8 8 c 0 4.41 3.58 8 8 8 s 8 -3.59 8 -8 c 0 -4.42 -3.58 -8 -8 -8 z m 0 18 c -5.33 0 -16 2.67 -16 8 v 6 h 32 v -6 c 0 -5.33 -10.67 -8 -16 -8 z"
          />
        </g>
        <g transform="matrix(1 0 0 1 0 0)">
          <path
            transform=" translate(-24, -24)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default MyExposuresIcon;
