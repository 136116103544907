import React from 'react';
import { Icon } from '@mui/material';

const ComplianceIcon: React.FC<{
  fontSize: string | number;
  color?: string;
}> = ({ fontSize, color }) => (
  <Icon style={{ fontSize: fontSize }}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 472 500"
      fill={color ? color : 'rgba(58, 53, 65, 0.87)'}
    >
      <g>
        <path
          className="st0"
          d="M126.81,76.91v31.08h168.34V76.91c0-7.16-5.82-12.98-12.98-12.98l-38.01,0c-2.83,0-5.12-2.29-5.12-5.12
		c0-15.48-12.59-28.07-28.06-28.07c-13.72,0-25.17,9.9-27.59,22.94l34.83,0c2.83,0,5.12,2.29,5.12,5.12c0,2.83-2.29,5.12-5.12,5.12
		h-40.09c-0.11,0.01-0.21,0.01-0.32,0.01h-38.01C132.63,63.93,126.81,69.75,126.81,76.91z"
        />
        <path
          className="st0"
          d="M305.38,82.03v25.96h30.49c1.36,0,2.66,0.54,3.62,1.49c0.96,0.96,1.49,2.26,1.49,3.62l-0.01,164.55
		c2.93-0.27,5.9-0.41,8.9-0.41c5.82,0,11.53,0.51,17.08,1.49V110.69c0-15.81-12.86-28.66-28.66-28.66H305.38z"
        />
        <path
          className="st0"
          d="M349.87,287.48c-48.32,0-87.63,39.31-87.63,87.63c0,48.32,39.31,87.63,87.63,87.63
		c48.32,0,87.63-39.31,87.63-87.63C437.5,326.79,398.19,287.48,349.87,287.48L349.87,287.48z M334.19,397.58l55.79-55.79
		c2-2,5.24-2,7.24,0c2,2,2,5.24,0,7.24l-59.41,59.41c-0.99,1-2.3,1.5-3.61,1.5c-1.31,0-2.62-0.5-3.62-1.49l-28.06-28.06
		c-2-2-2-5.24,0-7.24c2-2,5.24-2,7.24,0L334.19,397.58z"
        />
        <path
          className="st0"
          d="M121.49,150.81c-3.25,0-5.9,2.64-5.9,5.9v24.98c0,3.26,2.64,5.9,5.9,5.9h24.98c3.26,0,5.9-2.64,5.9-5.9v-24.98
		c0-3.26-2.64-5.9-5.9-5.9H121.49z"
        />
        <path
          className="st0"
          d="M121.49,219.6c-3.25,0-5.9,2.64-5.9,5.9v24.98c0,3.26,2.64,5.9,5.9,5.9h24.98c3.26,0,5.9-2.64,5.9-5.9V225.5
		c0-3.26-2.64-5.9-5.9-5.9H121.49z"
        />
        <path
          className="st0"
          d="M121.49,288.39c-3.25,0-5.9,2.64-5.9,5.9v24.98c0,3.26,2.64,5.9,5.9,5.9h24.98c3.26,0,5.9-2.64,5.9-5.9v-24.98
		c0-3.26-2.64-5.9-5.9-5.9H121.49z"
        />
        <path
          className="st0"
          d="M121.49,357.19c-3.25,0-5.9,2.64-5.9,5.9v24.98c0,3.26,2.64,5.9,5.9,5.9h24.98c3.26,0,5.9-2.64,5.9-5.9v-24.98
		c0-3.26-2.64-5.9-5.9-5.9H121.49z"
        />
        <path
          className="st0"
          d="M55.02,110.69v323.39c0,15.81,12.86,28.66,28.66,28.66h222.65c-12.56-6.27-23.6-15.16-32.39-25.96H86.09
		c-2.83,0-5.12-2.29-5.12-5.12V113.11c0-2.83,2.29-5.12,5.12-5.12h30.49l0-25.96H83.68C67.88,82.03,55.02,94.88,55.02,110.69z"
        />
        <path
          className="st0"
          d="M162.6,250.48c0,8.9-7.24,16.13-16.13,16.13h-24.98c-8.9,0-16.13-7.24-16.13-16.13V225.5
		c0-8.9,7.24-16.13,16.13-16.13h24.98c8.9,0,16.13,7.24,16.13,16.13V250.48L162.6,250.48z M91.21,118.22v308.32h175.44
		c-9.28-14.95-14.64-32.58-14.64-51.43c0-47.41,33.9-87.06,78.73-95.98l0.01-160.9h-30.49H121.71l-0.02,0l-0.02,0H91.21
		L91.21,118.22z M146.46,197.82h-24.98c-8.9,0-16.13-7.24-16.13-16.13v-24.98c0-8.9,7.24-16.13,16.13-16.13l24.98,0
		c8.9,0,16.13,7.24,16.13,16.13v24.98C162.6,190.58,155.36,197.82,146.46,197.82L146.46,197.82z M146.46,278.16
		c8.9,0,16.13,7.24,16.13,16.13v24.98c0,8.9-7.24,16.13-16.13,16.13h-24.98c-8.9,0-16.13-7.24-16.13-16.13v-24.98
		c0-8.9,7.24-16.13,16.13-16.13L146.46,278.16L146.46,278.16z M146.46,346.95c8.9,0,16.13,7.24,16.13,16.13v24.98
		c0,8.9-7.24,16.13-16.13,16.13h-24.98c-8.9,0-16.13-7.24-16.13-16.13v-24.98c0-8.9,7.24-16.13,16.13-16.13L146.46,346.95
		L146.46,346.95z M183.11,255.7c-2.83,0-5.12-2.29-5.12-5.12c0-2.83,2.29-5.12,5.12-5.12l128.37,0c2.83,0,5.12,2.29,5.12,5.12
		c0,2.83-2.29,5.12-5.12,5.12H183.11L183.11,255.7z M311.48,230.51H183.11c-2.83,0-5.12-2.29-5.12-5.12c0-2.82,2.29-5.12,5.12-5.12
		l128.37,0c2.83,0,5.12,2.29,5.12,5.12C316.6,228.22,314.31,230.51,311.48,230.51L311.48,230.51z M311.48,186.9H183.11
		c-2.83,0-5.12-2.29-5.12-5.12c0-2.83,2.29-5.12,5.12-5.12h128.37c2.83,0,5.12,2.29,5.12,5.12
		C316.6,184.61,314.31,186.9,311.48,186.9L311.48,186.9z M311.48,161.72H183.11c-2.83,0-5.12-2.29-5.12-5.12
		c0-2.83,2.29-5.12,5.12-5.12l128.37,0c2.83,0,5.12,2.29,5.12,5.12C316.6,159.43,314.31,161.72,311.48,161.72L311.48,161.72z
		 M177.99,294.19c0-2.83,2.29-5.12,5.12-5.12h81.95c2.83,0,5.12,2.29,5.12,5.12c0,2.83-2.29,5.12-5.12,5.12h-81.95
		C180.29,299.31,177.99,297.01,177.99,294.19L177.99,294.19z M183.11,314.25l62.51,0c2.82,0,5.12,2.29,5.12,5.12
		c0,2.83-2.29,5.12-5.12,5.12h-62.51c-2.83,0-5.12-2.29-5.12-5.12C177.99,316.55,180.29,314.25,183.11,314.25L183.11,314.25z
		 M183.11,357.86h50.48c2.83,0,5.12,2.29,5.12,5.12c0,2.83-2.29,5.12-5.12,5.12h-50.48c-2.83,0-5.12-2.29-5.12-5.12
		C177.99,360.16,180.29,357.86,183.11,357.86L183.11,357.86z M183.1,383.04l50.57,0c2.83,0,5.12,2.29,5.12,5.12
		c0,2.83-2.28,5.12-5.12,5.12H183.1c-2.83,0-5.12-2.29-5.12-5.12C177.98,385.34,180.28,383.04,183.1,383.04z"
        />
      </g>
    </svg>
  </Icon>
);

export default ComplianceIcon;
