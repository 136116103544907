import axios from 'axios';
import { BASE_API_URL } from 'api/constants';
import { renderSnackbar } from 'utils';
import i18next from 'utils/i18n';

const defaultAxiosInstance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 600000,
  headers: {
    accept: 'application/json',
  },
});

defaultAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle request time out
    if (!error.response)  {
      renderSnackbar([i18next.t('common:request_time_out')]);
      return Promise.reject({
        response: {
          status: 408,
          data: { errors: null }
        }
      });
    }

    if (error.response.status === 500) {
      //reportError(error);
    }

    
    return Promise.reject(error);
  }
);

export default defaultAxiosInstance;