enum SUBSTANCE_FILTER_SDS_STATUS {
    USE = 'use',
    NOT_USE = 'not_use',
    MANUALLY_ENTERED = 'manually_entered',
}

enum SUBSTANCE_FILTER_APPROVAL_STATUS {
    ALL = 'all',
    APPROVED = 'approved',
    NOT_APPROVED = 'not_approved',
    NOT_APPROVED_COMPLETELY = 'partial_approved',
}

enum SUBSTANCE_FILTER_LOCATION_STATUS {
    ACCESSED = 'access',
    ALL = 'all'
}

enum SUBSTANCE_FILTER_RISK_ASSESSMENT_STATUS {
    ALL = 'all',
    WITH_RISK = 'with_risk_assessment',
    NEED_RISK = 'need_risk_assessment',
    NOT_NEEDED = 'risk_assessment_not_needed',
    WITHOUT_RISK = 'without_risk_assessment',
}

enum SUBSTANCE_FILTER_SDS_REQUEST_STATUS {
    ALL = 'all',
    REQUESTED_FROM_SUPPLIER = 'requested_from_supplier',
    PENDING = 'pending',
    AVAILABLE_ON_DATE = 'available_on_date',
    SDS_NOT_AVAILABLE = 'sds_not_available',
    NEW = 'new',
    REQUEST_FOR_PRODUCT_APPROVAL = 'request_for_product_approval',
    APPROVED = 'approved',
    DECLINED = 'declined',
    COMPLETED = 'completed',
    CONFIRMED_ON_LOCATION = "confirmed_on_location"
}

enum SUBSTANCE_FILTER_SDS_ON_LOCATION_STATUS {
    ALL = 'all',
    ACTIVE = 'active',
    ARCHIVED = 'archived'
}

enum PRODUCT_PRESENCE_FILTER_OPTIONS {
    RECORDED = 'recorded',
    NO_RECORDED = 'no_recorded'
}

enum EXTERNAL_SYSTEM_ID_FILTER_OPTIONS {
    ALL = 'all',
    PRESENT = 'present',
    MISSING = 'missing'
}
  
const DEFAULT_SUBSTANCE_FILTER_SDS_STATUS = SUBSTANCE_FILTER_SDS_STATUS.USE;
const DEFAULT_SUBSTANCE_FILTER_APPROVAL_STATUS = SUBSTANCE_FILTER_APPROVAL_STATUS.ALL;
const DEFAULT_SUBSTANCE_FILTER_LOCATION_STATUS = SUBSTANCE_FILTER_LOCATION_STATUS.ACCESSED;
const DEFAULT_SUBSTANCE_FILTER_RISK_ASSESSMENT_STATUS = SUBSTANCE_FILTER_RISK_ASSESSMENT_STATUS.ALL;
const DEFAULT_SUBSTANCE_FILTER_SDS_REQUEST_STATUS = SUBSTANCE_FILTER_SDS_REQUEST_STATUS.ALL;
const DEFAULT_SUBSTANCE_FILTER_SDS_ON_LOCATION_STATUS = SUBSTANCE_FILTER_SDS_ON_LOCATION_STATUS.ACTIVE;

export {
    SUBSTANCE_FILTER_SDS_STATUS,
    SUBSTANCE_FILTER_APPROVAL_STATUS,
    SUBSTANCE_FILTER_LOCATION_STATUS,
    SUBSTANCE_FILTER_RISK_ASSESSMENT_STATUS,
    SUBSTANCE_FILTER_SDS_REQUEST_STATUS,
    SUBSTANCE_FILTER_SDS_ON_LOCATION_STATUS,
    DEFAULT_SUBSTANCE_FILTER_SDS_STATUS,
    DEFAULT_SUBSTANCE_FILTER_APPROVAL_STATUS,
    DEFAULT_SUBSTANCE_FILTER_LOCATION_STATUS,
    DEFAULT_SUBSTANCE_FILTER_RISK_ASSESSMENT_STATUS,
    DEFAULT_SUBSTANCE_FILTER_SDS_REQUEST_STATUS,
    DEFAULT_SUBSTANCE_FILTER_SDS_ON_LOCATION_STATUS,
    PRODUCT_PRESENCE_FILTER_OPTIONS,
    EXTERNAL_SYSTEM_ID_FILTER_OPTIONS
}