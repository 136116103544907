import en from './messages.json';
import no from './messages.no.json';
import de from './messages.de.json';
import da from './messages.da.json';
import sv from './messages.sv.json';
import nl from './messages.nl.json';
import fi from './messages.fi.json';
import el from './messages.el.json';
import pl from './messages.pl.json';
import pt from './messages.pt.json';
import es from './messages.es.json';
import fr from './messages.fr.json';
import it from './messages.it.json';
import bg from './messages.bg.json';
import bs from './messages.bs.json';
import cs from './messages.cs.json';
import et from './messages.et.json';
import hi from './messages.hi.json';
import hr from './messages.hr.json';
import hu from './messages.hu.json';
import id from './messages.id.json';
import is from './messages.is.json';
import ja from './messages.ja.json';
import lt from './messages.lt.json';
import lv from './messages.lv.json';
import ms from './messages.ms.json';
import mt from './messages.mt.json';
import ro from './messages.ro.json';
import ru from './messages.ru.json';
import sk from './messages.sk.json';
import sl from './messages.sl.json';
import sq from './messages.sq.json';
import sr from './messages.sr.json';
import tr from './messages.tr.json';
import uk from './messages.uk.json';
import vi from './messages.vi.json';
import zh from './messages.zh.json';

export default {
  en,
  no,
  de,
  da,
  sv,
  nl,
  fi,
  el,
  pl,
  pt,
  es,
  it,
  fr,
  bg,
  bs,
  cs,
  et,
  hi,
  hr,
  hu,
  id,
  is,
  ja,
  lt,
  lv,
  ms,
  mt,
  ro,
  ru,
  sk,
  sl,
  sq,
  sr,
  tr,
  uk,
  vi,
  zh,
};
