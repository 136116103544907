import CustomSnackbar from 'components/commons/custom-snackbar';
import { ThemeProvider as ThemeProviderMui } from '@mui/material/styles';
import { theme } from './AppThemeProvider';
import { createRoot } from 'react-dom/client';
let snackbarExisted = false;

export function renderSnackbar(
  text: string[],
  actionLabel?: string,
  actionClick?: () => void,
  className?: string,
  autoHide?: boolean
) {
  if (snackbarExisted) return;
  const sNew = document.createElement('div');
  const root = createRoot(sNew!);
  document.getElementsByTagName('body')[0].appendChild(sNew);
  snackbarExisted = true;
  root.render(
    <ThemeProviderMui theme={theme}>
      <CustomSnackbar
        onClose={() => {
          sNew.remove();
          snackbarExisted = false;
        }}
        text={text}
        onClick={() => {
          if (actionClick) {
            actionClick()
            return;
          }

          sNew.remove();
          snackbarExisted = false;
        }}
        actionLabel={actionLabel}
        className={className}
        autoHide={autoHide}
      />
    </ThemeProviderMui>
  );
}
