import axiosAuthoringInstance from 'api/axios-client';

export const deleteUserFromWorkProcedure = (id) => {
  return axiosAuthoringInstance
    .delete(`workProcedures/users/${id}/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const deleteLocationFromWorkProcedure = (id) => {
  return axiosAuthoringInstance
    .delete(`/workProcedures/locations/${id}/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const deleteWorkProcedureRevisionHazard = (id) => {
  return axiosAuthoringInstance
    .delete(`/workProcedures/hazards/${id}/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const deleteRiskEvaluationHazardPPE = (id) => {
  return axiosAuthoringInstance
    .delete(`sdsEvaluations/ppe/${id}/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const deleteWorkProcedureHazardPPE = (id) => {
  return axiosAuthoringInstance
    .delete(`workProcedures/hazards/ppe/${id}/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
