import { createSlice } from '@reduxjs/toolkit';
import { fetchArchivedLocations, fetchLocationStructure } from './thunk';
import {
  ArchiveLocationItem,
  LocationItem,
  LocationStructureInterface,
} from 'interfaces/location-management';
import { UserLocationAccess } from 'interfaces/administration/UserInterface';

export interface LocationState {
  loading: boolean;
  locationData: Array<LocationItem>;
  commonLocationData: Array<LocationItem>;
  favoriteLocationData: Array<LocationItem>;
  archivedLocationData: Array<ArchiveLocationItem>;
  locationAccess: Array<UserLocationAccess>;
}

const initialState: LocationState = {
  loading: false,
  locationData: [],
  commonLocationData: [],
  favoriteLocationData: [],
  archivedLocationData: [],
  locationAccess: [],
};

const LocationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLoading(state, action: { payload: boolean }) {
      state.loading = action.payload;
    },
    setLocationAccess(state, action: { payload: Array<UserLocationAccess> }) {
      state.locationAccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /* fetchLocationStructure */
      .addCase(
        fetchLocationStructure.fulfilled,
        (
          state: LocationState,
          action: { payload: LocationStructureInterface | null }
        ) => {
          state.loading = false;
          const res = action.payload;
          if (!res) {
            state.locationData = [];
            state.commonLocationData = [];
            state.favoriteLocationData = [];
            state.locationAccess = [];
          } else {
            state.locationData = res.all;
            state.commonLocationData = res.common;
            state.favoriteLocationData = res.favorite;
            state.locationAccess = res.locations_access;
          }
        }
      )
      .addCase(fetchLocationStructure.pending, (state: LocationState) => {
        state.loading = true;
      })
      .addCase(fetchLocationStructure.rejected, (state: LocationState) => {
        state.loading = false;
        state.locationData = [];
        state.commonLocationData = [];
        state.favoriteLocationData = [];
      })
      /* fetchArchivedLocations */
      .addCase(
        fetchArchivedLocations.fulfilled,
        (
          state: LocationState,
          action: { payload: Array<ArchiveLocationItem> }
        ) => {
          state.archivedLocationData = action.payload;
        }
      )
  },
});

export const { setLoading, setLocationAccess } = LocationSlice.actions;
export default LocationSlice.reducer;
