import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  popover: {
    display: 'flex',
    flexDirection: 'column',
    width: '260px',
    '& .MuiListItemText-root .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: 500,
    },
    '& .MuiListItem-root .MuiFormLabel-root': {
      fontSize: '14px',
    },
    '& .MuiListItem-root .MuiInputBase-root': {
      fontSize: '14px',
    },
  },
  listItem: {
    paddingTop: 10,
    paddingBottom: 10,
    cursor: 'pointer',
  },
  heading: {
    color: '#00324E',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  selectInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-select': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    }
  },
  planChip: {
    padding: 2,
    borderRadius: 4,
    color: '#626DF9',
    fontSize: '8px',
    textAlign: 'center',
    marginLeft: 2,
    marginBottom: 6,
    fontWeight: 600,
    border: '1px solid #626DF9'
  }
});

export default useStyles;
