import { Button, ButtonProps, CircularProgress } from '@mui/material';
import useSynchronousResource from 'hooks/useSynchronousResource';

interface SubmitButtonProps extends ButtonProps {
  onSave?: any;
  disabled?: boolean;
}

const SubmitButton = ({
  onSave,
  disabled = false,
  children,
  ...props
}: SubmitButtonProps) => {
  const [saving, handleSave] = useSynchronousResource(onSave);

  return (
    <Button
      onClick={handleSave}
      disabled={saving || disabled}
      startIcon={
        saving ? <CircularProgress size={15} color={'inherit'} /> : null
      }
      {...props}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
