import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { sdsManagerUrlLocalization } from 'utils/i18n';
import { userDataSelector } from 'services/user/selectors';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

const Footer: React.FC = () => {
  const classes: any = useStyles();
  const { t } = useTranslation(['footer', 'key_info']);
  const userData = useSelector(userDataSelector);

  const location = window.location.pathname;
  const shouldDisplay = ![
    '/login',
    '/signup',
    '/reset-password',
    '/onesignin',
    'publicView',
    'pdfLabeling',
  ].some((item: string) => {
    return location.toLowerCase().indexOf(item) > -1;
  });

  const emergencyPhoneNumber =
    userData?.customer?.emergency_number ||
    userData?.customer?.country?.emergency_agency_phone_number;
  const showEmergencyInfo = userData?.qr_login_token;

  if (!shouldDisplay) return null;

  return (
    <div className={`${classes.footer} ${showEmergencyInfo ? classes.includeEmergencyAgencyInfo : ''}`}>
      {showEmergencyInfo && (
        <div className={classes.emergencyAgencyInfo}>
          <div>
            <Typography className={classes.emergencyTitle}>
              {t('key_info:emergency_name')}
            </Typography>
            <Typography>
              {userData?.customer?.emergency_agency ||
                userData?.customer?.country?.emergency_agency_name ||
                '-'}
            </Typography>
          </div>
          <div>
            <Typography className={classes.emergencyTitle}>
              {t('common:phone')}
            </Typography>
            {emergencyPhoneNumber ? (
              <a href={`tel:${emergencyPhoneNumber}`}>{emergencyPhoneNumber}</a>
            ) : (
              <Typography>-</Typography>
            )}
          </div>
        </div>
      )}
      <a
        className={classes.linkText}
        rel="noreferrer"
        href={sdsManagerUrlLocalization() + '/privacy-policy'}
        target={'_blank'}
      >
        {t('footer:privacy_policy')}
      </a>
      <a
        className={classes.linkText}
        rel="noreferrer"
        href={sdsManagerUrlLocalization() + '/terms-and-conditions'}
        target={'_blank'}
      >
        {t('footer:terms')}
      </a>
    </div>
  );
};

export default Footer;
