import CryptoJS from 'crypto-js';

const AES_128_ECB_KEY = '65XBuKNaKOfA8zlI';

const aes128EcbDecrypt = (cipher: string) => {
  const key = CryptoJS.enc.Utf8.parse(AES_128_ECB_KEY);
  const decrypted = CryptoJS.AES.decrypt(cipher, key, {
    mode: CryptoJS.mode.ECB,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export default aes128EcbDecrypt;
