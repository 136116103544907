import availableLanguages from './languages';
import availableCountries from './countries';
import Regions from './regions';
import { VOLUME_MEASUREMENT_OPTIONS, WEIGHT_MEASUREMENT_OPTIONS } from './measurements-unit';
import store from 'store/store';
import SUBSTANCE_FORM_OPTIONS from './substance-form';
import PERSONS_AT_RISK_OPTIONS from './persons_at_risk';

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const TIMESTAMP_10_DAYS = 864000000; // 1000 * 60 * 60 * 24 * 10;
export const TIMESTAMP_30_DAYS = 2592000000; // 1000 * 60 * 60 * 24 * 30;

const REGIONS = Regions;
REGIONS.sort((x, y) => {
  const xValue = x.name.toUpperCase();
  const yValue = y.name.toUpperCase();
  return xValue < yValue ? -1 : xValue > yValue ? 1 : 0;
});

const COUNTRIES = availableCountries;
COUNTRIES.sort((x, y) => {
  const xValue = x.name.toUpperCase();
  const yValue = y.name.toUpperCase();
  return xValue < yValue ? -1 : xValue > yValue ? 1 : 0;
});

const LANGUAGES = availableLanguages;
LANGUAGES.sort((x, y) => {
  const xValue = x.name.toUpperCase();
  const yValue = y.name.toUpperCase();
  return xValue < yValue ? -1 : xValue > yValue ? 1 : 0;
});

export {
  LANGUAGES,
  COUNTRIES,
  REGIONS,
  VOLUME_MEASUREMENT_OPTIONS,
  WEIGHT_MEASUREMENT_OPTIONS,
  SUBSTANCE_FORM_OPTIONS,
  PERSONS_AT_RISK_OPTIONS,
};

export const USER_STATUS: { [key: string]: number } = {
  active: 1,
  deactivated: 0,
  all: -1,
};

export const QR_LOGIN_STATUS: {
  [key: string]: {
    allow_login_qr_code?: number;
    qr_login_token__isnull?: number;
  };
} = {
  active: { allow_login_qr_code: 1, qr_login_token__isnull: 0 },
  deactivated: { allow_login_qr_code: 0, qr_login_token__isnull: 0 },
  all: { qr_login_token__isnull: 0 },
  default: {},
};

export interface USER_TYPE_VALUE {
  allow_login_qr_code?: number;
  qr_login_token__isnull?: number;
}

export interface USER_TYPE_INTERFACE {
  all: USER_TYPE_VALUE;
  qr_code: USER_TYPE_VALUE;
  user_account: USER_TYPE_VALUE;
}

export const USER_TYPE: USER_TYPE_INTERFACE = {
  all: {},
  qr_code: { qr_login_token__isnull: 0 },
  user_account: { qr_login_token__isnull: 1 },
};

export const TO_LITER_MAPPER: any = {
  ul: 10 ** 6,
  ml: 10 ** 3,
  l: 1,
  cc: 10 ** 3,
  m3: 10 ** -3,
  'fl oz': 33.814,
  pt: 1.75975,
  qt: 1.05669,
  gals: 0.2641720524,
  'cu ft': 0.0353147,
};

export const TO_KILOGRAM_MAPPER: any = {
  ug: 10 ** 9,
  mg: 10 ** 6,
  g: 10 ** 3,
  kg: 1,
  oz: 35.274,
  lbs: 2.20462,
};

export const IMPORT_PRODUCT_LIST_FIELDS = [
  {
    // Visible in table header and when matching columns.
    label: 'common:supplier_name',
    // This is the key used for this field when we call onSubmit.
    key: 'SUPPLIER_OF_SDS',
    // Allows for better automatic column matching. Optional.
    alternateMatches: ['SUPPLIER_NAME', 'PRODUCER_OF_SDS', 'PRODUCER_NAME'],
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: 'Supplier name',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Supplier name is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
  },
  {
    label: 'common:product_name',
    key: 'PRODUCT_NAME',
    alternateMatches: ['PPRODUCT_NAME'],
    fieldType: {
      type: 'input',
    },
    example: 'Product name',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Product name is required',
        level: 'error',
      },
    ],
  },
  {
    label: 'common:product_code',
    key: 'PRODUCT_CODE',
    alternateMatches: [],
    fieldType: {
      type: 'input',
    },
    example: 'Product name',
  },
  {
    label: 'common:location',
    key: 'LOCATION',
    alternateMatches: ['DEPARTMENT'],
    fieldType: {
      type: 'input',
    },
    example: 'Room A',
  },
  {
    label: 'common:link_to_sds',
    key: 'LINK_TO_SDS',
    alternateMatches: [],
    fieldType: {
      type: 'input',
    },
    example: 'Here you add the URL to the SDS if you know it',
  },
  {
    label: 'common:sku',
    key: 'SKU',
    alternateMatches: [],
    fieldType: {
      type: 'input',
    },
    example: 'For use by ecommerce solutions',
  },
  {
    label: 'common:external_system_id',
    key: 'EXTERNAL_SYSTEM_ID',
    alternateMatches: [],
    fieldType: {
      type: 'input',
    },
    example:
      'Use this field for your ERP system internal id used for the product',
  },
];

export const SDS_NUMBER_SELECTOR_RANGE = [
  100, 200, 400, 600, 800, 1000, 1500, 2000, 4000, 6000, 8000, 10000, 20000,
  30000, 40000, 50000, 100000,
];

export const SECTION_KEYS = [
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
];
export const CODE_EXPIRY_TIME = 300; // after 300s, otp will be expired
export const SPECIAL_CHARACTER_REGEX = /[\&\(\)\<\>\{\}\[\]\:\;\'\"\|]/g;
export const WORD_SPLITTING_REGEX =
  /[\,\.\+\%\@\*\-\_\\\/\`\~\&\(\)\<\>\{\}\[\]\:\;\'\"\|]/g;

export const UPLOAD_BINDER_MAX_FILES = 20;
export const UPLOAD_BINDER_GENERAL_MAX_SIZE = 26214400;
export const UPLOAD_BINDER_PRO_MAX_SIZE = 524288000;
export const UPLOAD_BINDER_PRO_MAX_SIZE_5GB = 5368709120;
export const UPLOAD_BINDER_RC_ENV = 5368709120 * 4; // 20GB

export const UPLOAD_ATTACHMENT_MAX_FILES = 1;
export const UPLOAD_ATTACHMENT_MAX_SIZE = 5242880;

export const SDS_MANAGER_URL = 'https://sdsmanager.com';
export const SDS_INVENTORY_URL = 'https://inventory.sdsmanager.com';
export const SDS_GOOGLE_LOGIN =
  'https://admin55.sdsmanager.com/oauthv2/login/google/';
export const SDS_SIGNUP_API = 'https://inventory.sdsmanager.com/signUp';
export const MEASUREMENT_ID = process.env.MEASUREMENT_ID || 'G-WXJYL5S5VJ';
export const SDS_HOME_PAGE = 'https://inventory.sdsmanager.com/';
export const SDS_PICTOGRAM_URL = `https://inventory.sdsmanager.com/sds-static/images/GHS_pictogram_files/`;
export const PRODUCT_FRUITS_WORKPLACE_CODE = 'cj2o2rwYlQkexrZ0';

export const CALENDLY_SDS_MANAGER_DEMO = 'https://calendly.com/d/cks9-rzj-krt/sds-manager-demo'
export const LIVE_CHAT = ''
export const FAQ_SDS_MANAGER_URL = 'https://faq.sdsmanager.com/'
