import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system";

const useStyles = makeStyles((theme: Theme) => ({
  footerDemoWrapper: {
    position: 'fixed',
    bottom: 0,
    zIndex: 1000,
    height: 50,
    width: '100%',
    backgroundColor: '#E3DFFD',
    boxShadow: '0px -1px 2px rgb(0 0 0 / 20%)'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: 50,
    margin: '8px 0px',
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      fontSize: 12,
      whiteSpace: 'nowrap',
      minWidth: 90
    },
    [theme.breakpoints.down('sm')]: {
      gap: 30,
    },
  },
  footerLink: {
    position: 'fixed',
    right: 30, 
    bottom: 0,
    '& a': {
      color: '#000000',
      marginLeft: 15,
      textDecoration: 'none',
      fontSize: 12,
      whiteSpace: 'nowrap',
    },
    margin: '10px 0px',
  },
}));
export default useStyles;