import { ReactElement } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedRevisionId } from 'services/work-procedure/slice';
import { AppDispatch } from 'constant';
/* Components */
import { ClassNameMap, Typography, Pagination } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import CustomLoader from 'components/loader/Loader';

type CardColumn = {
  title: string;
  id: string;
};

interface DefaultCardTableMobileProps {
  id?: string | number | null;
}

interface CardTableMobileProps<T extends DefaultCardTableMobileProps = any> {
  column: Array<CardColumn>;
  data: Array<T>;
  renderValue: (item: T, id: string) => ReactElement | string | number | null;
  loading?: boolean;
  count?: number;
  page?: number;
  handlePageChange?: any;
  renderAction?: (row: T) => ReactElement;
  actionPos?: 'bottom' | 'top' | 'top-right';
  hiddenNameLabel?: boolean;
  onClick?: (item: any) => void;
  renderEmptyRow?: () => ReactElement | null;
}

function CardTableMobile<T extends DefaultCardTableMobileProps = any>(
  props: CardTableMobileProps<T>
) {
  const {
    column,
    data,
    renderValue,
    loading,
    count,
    page,
    handlePageChange,
    renderAction,
    actionPos = 'bottom',
    hiddenNameLabel,
    onClick,
    renderEmptyRow,
  } = props;
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  if (loading != undefined && loading) {
    return (
      <div className={classes.loader}>
        <CustomLoader />
      </div>
    );
  }

  return (
    <>
      {data.length > 0 ? (
        data.map((item: T) => (
          <div className={classes.card} key={item.id}>
            {renderAction && actionPos === 'top' && renderAction(item)}
            {column.map((col: CardColumn, index: number) => {
              if (!col.id) return null;
              const value = renderValue(item, col.id);
              if (!value) return null;
              if (['detail', 'action'].includes(col.id)) return null;
              const renderTitle = () => {
                if (col.title && col.id === 'product_name' && hiddenNameLabel) {
                  return (
                    <div
                      className={classes.titleActionWrapper}
                      onClick={e => {
                        e.stopPropagation();
                        history.push(`/risk-evaluations/${(item as any).id}/`);
                      }}
                    >
                      <span className={classes.valuesColor}>{col.title}: </span>
                      <div className={classes.actionWrapper}>
                        <EastIcon />
                        {renderAction &&
                          actionPos === 'top-right' &&
                          index === 0 &&
                          renderAction(item)}
                      </div>
                    </div>
                  );
                }
                if (col.title && !(col.id === 'name' && hiddenNameLabel)) {
                  if (
                    renderAction &&
                    actionPos === 'top-right' &&
                    index === 0
                  ) {
                    return (
                      <div className={classes.titleActionWrapper}>
                        <span className={classes.valuesColor}>
                          {col.title}:{' '}
                        </span>
                        {renderAction(item)}
                      </div>
                    );
                  }

                  return (
                    <span className={classes.valuesColor}>{col.title}: </span>
                  );
                }
              };

              return (
                <div
                  className={classes.infoBlock}
                  key={col.id}
                  onClick={() => onClick && onClick(item)}
                >
                  {renderTitle()}
                  {col.id === 'name' && hiddenNameLabel ? (
                    <div
                      className={classes.titleActionWrapper}
                      onClick={e => {
                        e.stopPropagation();
                        dispatch(setSelectedRevisionId(item.id ?? null));
                        history.push(
                          `/work-procedures/${(item as any).work_procedure.id}/`
                        );
                      }}
                    >
                      <span className={classes.title}>
                        {renderValue(item, col.id)}
                      </span>
                      <EastIcon />
                    </div>
                  ) : (
                    <span>{renderValue(item, col.id)}</span>
                  )}
                </div>
              );
            })}
            {renderAction && actionPos === 'bottom' && renderAction(item)}
          </div>
        ))
      ) : renderEmptyRow !== undefined ? (
        renderEmptyRow()
      ) : (
        <Typography variant="subtitle2">{t('common:no_result')}</Typography>
      )}
      {count != undefined &&
        count > 10 &&
        page != undefined &&
        handlePageChange && (
          <div className={classes.pagination}>
            <Pagination
              defaultPage={6}
              count={Math.ceil(count / 10)}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </div>
        )}
    </>
  );
}

export default CardTableMobile;
