import { useEffect, useState } from 'react';

const useSynchronousResource = (
  consumer: () => Promise<any>
): [boolean, () => void] => {
  const [busy, setBusy] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setBusy(false);
    };
  }, []);

  const handler = () => {
    if (busy) return;
    setBusy(true);

    (async () => {
      try {
        await consumer();
      } catch (error) {
        console.log('Catch error: ', error);
      }
      setBusy(false);
    })();
  };

  return [busy, handler];
};

export default useSynchronousResource;