import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  sidebar: {
    boxShadow: '10px 0px 10px rgb(0, 0, 0, 0.05)',
    zIndex: 1,
    width: 60,
    position: 'absolute',
    transition: 'width 0.25s ease-in-out 0s, box-shadow 0.25s ease-in-out 0s',
    left: 0,
    background: '#fff',
    height: '100%',
    '& .MuiList-root .MuiBox-root': {
      transition: 'opacity 0.25s ease-in-out 0s',
      overflow: 'hidden',
      opacity: 0,
      marginLeft: 0,
    },
  },
  open: {
    width: 300,
    '& .MuiList-root .MuiBox-root': {
      // transition: 'opacity 0.25s ease-in-out 0s',
      opacity: 1,
      marginLeft: 10,
    },
  },
  openSidebarTex: {
    '& .MuiTypography-root': {
      whiteSpace: 'normal!important',
    },
  },
  iconsWrapperRoot: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'row',
    height: '100%',
    marginTop: 5,
    '& .simplebar-content': {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }
  },
  iconWrapperMore: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '0!important',
    '& .MuiListItem-container': {
      width: '100%',
    },
  },
  sidebarIconsMoreWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    height: '100%',
    marginBottom: '20px',
    overflow: 'hidden',
    flex: 1
  },
  logoImgMobile: {
    width: 'auto',
    height: 33,
    marginLeft: 18,
    marginBottom: 10,
    marginTop: 10,
  },
  popper: {
    fontSize: '1rem',
    backgroundColor: '#FFC93C',
    color: '#000000',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    overflowX: 'unset',
    overflowY: 'unset',
    cursor: 'pointer',
    borderRadius: 4,
    zIndex: 2,
    '&::before': {
      content: '""',
      position: 'absolute',
      marginRight: '-0.71em',
      top: 12,
      left: 0,
      width: 10,
      height: 10,
      backgroundColor: '#FFC93C',
      transform: 'translate(-50%, 50%) rotate(225deg)',
      clipPath:
        'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
    },
  },
  poperContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  isExpand: {
    width: '100%',
    maxHeight: 450,
  },
  iconWithBackground: {
    width: 14.67,
    height: 14.67,
    padding: 1,
    fill: '#FFF!important',
    backgroundColor: '#626DF9',
    borderRadius: '50%',
    marginRight: 9,
  },
  sidebarBottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 0px 12px',
    borderTop: '1px solid #F1F2F6',
  },
}));

export default useStyles;
