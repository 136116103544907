import { USER_ROLE, LOCATION_ROLE } from 'enums/role.enums';
import store from '../store/store';
import {
  LOCATION_PERMISSION,
  PERMISSION,
  USER_PERMISSION,
} from 'enums/permissions.enums';
import UserInterface from 'interfaces/UserInterface';
import {
  GetCustomerUserResponseDto,
  UserLocationAccess,
} from 'interfaces/administration/UserInterface';
import { setFeatureNotAvailable } from 'services/common/slice';

export const isOwner = (user: UserInterface | null): boolean => {
  if (!user) return false;
  return !!user.is_owner;
};

export const isAdmin = (user: UserInterface | null): boolean => {
  if (!user) return false;
  return user.inventory_manager_role === USER_ROLE.ADMIN_ROLE;
};

export const isAdminLight = (user: UserInterface | null): boolean => {
  if (!user) return false;
  return user.inventory_manager_role === USER_ROLE.ADMIN_LIGHT_ROLE;
} 

export const isNoAccessRole = (user: UserInterface | null): boolean => {
  if (!user) return false;
  return user.inventory_manager_role === USER_ROLE.NO_ACCESS_ROLE;
};

export const isReadOnlyRole = (user: UserInterface | null): boolean => {
  if (!user) return false;
  return user.inventory_manager_role === USER_ROLE.READ_ONLY_ROLE;
};

export const isStaffRole = (user: UserInterface | null): boolean => {
  if (!user) return false;
  return user.inventory_manager_role === USER_ROLE.STAFF_ROLE;
};

export const checkUserPermissions = (
  permission: USER_PERMISSION,
  forDisplay: boolean = false
): boolean => {
  const state = store.getState();
  const userRolePermissions = state.user.userPermission;

  if (!userRolePermissions.includes(permission)) {
    if (
      forDisplay &&
      userRolePermissions.includes(PERMISSION.SEE_FEATURE_NOT_AVAILABLE_POPUP)
    ) {
      const openVisionPermission = state.permission.openVisionPermission;
      if (openVisionPermission && openVisionPermission.includes(permission))
        return true;
    }

    return false;
  }
  return true;
};

export const checkCustomerPermissions = (permission: PERMISSION): boolean => {
  const state = store.getState();
  const subscriptionPlanPermissions = state.user.customerPermission;
  if (!subscriptionPlanPermissions.includes(permission)) return false;
  return true;
};

export const checkLocationPermissions = (
  permission: LOCATION_PERMISSION,
  forDisplay: boolean = false,
  locationId: string | number | null = null
): boolean => {
  const state = store.getState();
  const userRolePermissions = state.user.userPermission;

  if (locationId) {
    const locationAccess: UserLocationAccess[] = state.location.locationAccess;
    const location = locationAccess.find(
      (item: UserLocationAccess) =>
        String(item.department_id) == String(locationId)
    );
    if (location) {
      const access = location.role;
      const locationAccessPermission =
        state.permission.locationAccessPermission;
      if (!access || !locationAccessPermission) return false;

      const permissionList = locationAccessPermission[access];
      if (permissionList.includes(permission)) return true;

      if (
        forDisplay &&
        userRolePermissions.includes(PERMISSION.SEE_FEATURE_NOT_AVAILABLE_POPUP)
      ) {
        const openVisionPermission = state.permission.openVisionPermission;
        if (openVisionPermission && openVisionPermission.includes(permission))
          return true;
      }
    }

    return false;
  }

  const locationPermission = state.locationManagement.locationPermission;
  if (!locationPermission) return false;

  if (!locationPermission.includes(permission)) {
    if (
      forDisplay &&
      userRolePermissions.includes(PERMISSION.SEE_FEATURE_NOT_AVAILABLE_POPUP)
    ) {
      const openVisionPermission = state.permission.openVisionPermission;
      if (openVisionPermission && openVisionPermission.includes(permission))
        return true;
    }
    return false;
  }
  return true;
};

export const checkDisabledPermission = (permission: PERMISSION): boolean => {
  const state = store.getState();
  const disabledPermission = state.permission.disabledPermission;
  if (disabledPermission && disabledPermission.includes(permission))
    return true;
  return false;
};

export const checkHasParentCustomer = (
  userInfo: UserInterface | null
): boolean => {
  return !userInfo?.customer?.parent_customer;
};

/* Check roles of user on location */
export const hasUserRoleOnLocation = (
  locationId: string | number | null,
  roles: LOCATION_ROLE | Array<LOCATION_ROLE>
): boolean => {
  const state = store.getState();
  const locationAccess: UserLocationAccess[] = state.location.locationAccess;
  const user: UserInterface | null = state.user.userData;
  /* Fast return */
  if (!user) return false;
  if (!locationId) return false;
  if (!locationAccess || locationAccess.length === 0) return false;
  const filterLocation = locationAccess.find(
    (el: UserLocationAccess) => el.department_id == locationId
  );
  if (!filterLocation) return false;
  /* Owner will have all location access belong to customer */
  if (isOwner(user)) return true;
  /* Other USER_ROLE need to check role */
  if (Array.isArray(roles) && roles.includes(filterLocation.role)) return true;

  if (filterLocation.role === roles) return true;

  return false;
};

/* Get Locations User Access */
export const getLocationsUserAccess = (
  locationAccess: UserLocationAccess[]
): Array<UserLocationAccess> => {
  return locationAccess.filter(
    (el: UserLocationAccess) => el.role !== LOCATION_ROLE.NO_ACCESS_ROLE
  );
};

export const userRoles = {
  [USER_ROLE.ADMIN_ROLE]: 'admin',
  [USER_ROLE.ADMIN_LIGHT_ROLE]: 'admin_light',
  [USER_ROLE.NO_ACCESS_ROLE]: 'no_access',
  [USER_ROLE.READ_ONLY_ROLE]: 'read_only',
  [USER_ROLE.STAFF_ROLE]: 'staff',
  [USER_ROLE.OWNER]: 'owner',
};

export const isQRCodeLoginUser = (user: UserInterface | null): boolean => {
  return !!user?.qr_login_token;
};

/* If user not have permission -> Display upgrade plan dialog */
export const validateFeatureNotAvailable = (
  permission: PERMISSION | null | undefined = null
) => {
  if (!permission) return false;
  const state = store.getState();
  const userRolePermissions = state.user.userPermission;
  if (
    userRolePermissions.includes(PERMISSION.SEE_FEATURE_NOT_AVAILABLE_POPUP) &&
    !checkCustomerPermissions(permission)
  ) {
    const openVisionPermission = state.permission.openVisionPermission;
    if (openVisionPermission && openVisionPermission.includes(permission)) {
      store.dispatch(setFeatureNotAvailable(permission));
      return true;
    }
  }
  return false;
};

export const checkUserHasRolesOnLocation = (
  user: GetCustomerUserResponseDto,
  location: number | string,
  roles: LOCATION_ROLE[]
) => {
  if (user.is_owner) return true;
  if (user.qr_login_token || !user.is_active) return false;
  const findUserLocationAccess = user.locations_access.filter(
    el => el.department_id == location
  )[0];
  return (
    findUserLocationAccess && 
    roles.indexOf(findUserLocationAccess.role) !== -1
  )
}

export const checkUserHasRolesExist = (
  user: UserInterface | null, 
  roles: LOCATION_ROLE[]
): boolean => {
  if (!user) return false;
  if (user.is_owner) return true;
  if (user.qr_login_token) return false;
  const state = store.getState();
  const locationAccess: UserLocationAccess[] = state.location.locationAccess;
  
  return locationAccess.some(el => roles.includes(el.role));
};
