import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  snackbar: {
    backgroundColor: 'rgb(49, 49, 49)',
    display: 'flex',
    justifyContent: 'row',
    padding: '6px 16px',
    borderRadius: '4px',
    boxShadow:
      '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
  },
  formWrapper: {
    width: '100%',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 -10px',
    alignItems: 'stretch',
    rowGap: 15
  },
  column: {
    flex: '1',
    flexShrink: '0',
    width: 'calc(100% - 20px)',
    minWidth: '250px',
    margin: '0 10px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
      width: 'unset',
    },
  },
  inputRow: {
    display: 'flex',
  },
  errorText: {
    fontSize: '13px',
    color: '#f21212',
  },
  errorsRow: {
    width: '100%',
  },
}));

export default useStyles;