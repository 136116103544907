import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSectionSDSHeaderTranslation } from "api/inventory/get";
import { markAsFavorite, updateLocation } from 'api';
import { ApiError, ApiResponse, ErrorPayload } from "interfaces/common/ApiResponseInterface";
import { 
  EditLocationParams, 
  GetLocationByIdResponse, 
  GetLocationByIdResponseDto 
} from "interfaces/location-management/ChemicalContent";

export const fetchSectionSDSHeaderTranslation = createAsyncThunk(
  'substance/fetchSectionSDSHeaderTranslation',
  async(_, { rejectWithValue }
  ) => {
    const response = await getSectionSDSHeaderTranslation();
    if (response.status !== 200 || !response.data || response.data.status_code) 
      return  rejectWithValue(response.data);
    return response.data;
  }
)

export const editLocation = createAsyncThunk<
  GetLocationByIdResponseDto,
  EditLocationParams,
  {
    rejectValue: ErrorPayload
  }
>(
  'location-management/edit-location',
  async (
    params: EditLocationParams, 
    { rejectWithValue }
  ) => {
    const response: GetLocationByIdResponse|ApiError = await updateLocation(
      params.locationID,
      params.body
    );
    if (response.status !== 200) 
      return rejectWithValue(response.data as ErrorPayload);
      
    return response.data as GetLocationByIdResponseDto;
  }
)

export const markFavorite = createAsyncThunk<
  any,
  string,
  {
    rejectValue: ErrorPayload
  }
>(
  'location-management/mark-favorite',
  async (
    locationId,
    { rejectWithValue }
  ) => {
    const response: ApiResponse<any>|ApiError = await markAsFavorite(
      locationId,
    );
    if (response.status !== 200) 
      return rejectWithValue(response.data as ErrorPayload);
      
    return response.data as any;
  }
)
