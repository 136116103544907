import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { getEnv } from 'api/constants';
import { Button, Snackbar, Box } from '@mui/material';

const host = window.location.hostname;
const location = window.location.pathname;

const ErrorView = () => {
  return (
    <div style={{ flex: 1 }}>
      <Snackbar
        ContentProps={{
          style: {
            backgroundColor: '#626DF9',
          },
        }}
        open
        onClose={() => {}}
        message={"Oh no, there seems to be a problem, we're working on it..."}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={
          <Box sx={{ p: 1 }}>
            <Button
              style={{ background: '#fff' }}
              sx={{ mr: 1 }}
              color="primary"
              size="small"
              variant="outlined"
              onClick={() => {
                if (location == '/') window.location.reload();
                else window.location.href = '/';
              }}
            >
              Back to Dashboard
            </Button>
          </Box>
        }
      />
    </div>
  );
};

export const NewVersionView = () => {
  return (
    <div style={{ flex: 1 }}>
      <Snackbar
        ContentProps={{
          style: {
            backgroundColor: '#626DF9',
          },
        }}
        open
        onClose={() => {}}
        message={'A new version is available'}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={
          <Button
            style={{ background: '#fff' }}
            sx={{ mr: 1 }}
            color="primary"
            size="small"
            variant="outlined"
            onClick={() => {
              window.location.reload();
            }}
          >
            Update
          </Button>
        }
      ></Snackbar>
    </div>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  render() {
    // @ts-ignore
    const { error, hasError } = this.state;

    if (hasError) {
      if (
        error.name === 'ChunkLoadError' ||
        error.name === 'SyntaxError' ||
        error.name === 'Uncaught SyntaxError'
      )
        return <NewVersionView />;

      return <ErrorView />;
    }

    // @ts-ignore
    return this.props.children;
  }
}

let BugsnagErrorBoundary = ErrorBoundary;

if (getEnv() === 'production' && host.indexOf('localhost') === -1) {
  Bugsnag.start({
    apiKey: '1f2a5a901af0879495c3a1d55bded8f6',
    plugins: [new BugsnagPluginReact()],
    onError: function (event) {
      event.addMetadata('user', {
        token: localStorage.getItem('refresh_token'),
        email: localStorage.getItem('user_email'),
      });
    },
  });

  // @ts-ignore
  BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
}

export { BugsnagErrorBoundary, ErrorView };
