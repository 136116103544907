import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetRiskEvaluationsRequestDto } from "interfaces/work-procedure/RiskAssessmentInterface";
import { getRiskEvaluations } from "api/risk-assessment/get";

export const fetchRiskAssessments = createAsyncThunk(
  'risk-assessment/fetchRiskAssessment',
  async(
    params: GetRiskEvaluationsRequestDto, 
    { rejectWithValue }
  ) => {
    const response = await getRiskEvaluations(params);
    if (response.status !== 200 || !response.data || response.data.status_code) 
      return  rejectWithValue(response.data);
    return response.data;
  }
)
