import { createSlice } from '@reduxjs/toolkit';
import {
  SubstanceOfWorkProcedure,
  WorkProcedurePPE,
} from 'pages/work-procedures/work-procedure-details-v2/components/interfaces';
import {
  createNewRevisionRequest,
  fetchLocationWorkProcedure,
  fetchSubstancesWorkProcedure,
  fetchUsersWorkProcedure,
  fetchWorkProcedureInfoByID,
  fetchWorkProcedurePPEsByID,
  fetchMyWorkProcedures,
  fetchWorkProcedures,
} from './thunks';
import { WorkProceduresResponse } from 'interfaces/work-procedure';
import { WORK_PROCEDURE_STATUS } from 'enums/work-procedure-status.enums';
import {
  LocationOfWorkProcedure,
  WorkProcedureInfo,
  WorkProcedureUser,
} from 'interfaces/work-procedure/WorkProcedureDetails';

export interface WorkProcedureState {
  workProcedureInfo: WorkProcedureInfo | null;
  isLoading: boolean;
  workProcedureSubstances: Array<SubstanceOfWorkProcedure>;
  disableWorkProcedureEdit: boolean;
  selectedRevisionId: string | number | null;
  workProcedurePPEs: Array<WorkProcedurePPE>;
  locationsLinkedWithWorkProcedures: Array<LocationOfWorkProcedure>;
  workProcedureUsers: Array<WorkProcedureUser>;
  workProcedureMissedInfo: Array<string>;
  myWorkProcedureListData: WorkProceduresResponse | null;
  workProcedureListData: WorkProceduresResponse | null;
  workProcedureExposure: any;
}

const initialState: WorkProcedureState = {
  workProcedureInfo: null,
  isLoading: false,
  workProcedureSubstances: [],
  disableWorkProcedureEdit: true,
  selectedRevisionId: null,
  workProcedurePPEs: [],
  locationsLinkedWithWorkProcedures: [],
  workProcedureUsers: [],
  workProcedureMissedInfo: [],
  myWorkProcedureListData: null,
  workProcedureListData: null,
  workProcedureExposure: null,
};

const WorkProcedureSlice = createSlice({
  name: 'work-procedure',
  initialState,
  reducers: {
    setWorkProcedureInfo(state, action: { payload: WorkProcedureInfo }) {
      state.workProcedureInfo = action.payload;
    },
    setSelectedRevisionId(state, action: { payload: string | number | null }) {
      state.selectedRevisionId = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder
      /* fetchWorkProcedureInfoByID */
      .addCase(
        fetchWorkProcedureInfoByID.fulfilled,
        (state: WorkProcedureState, action: any) => {
          state.workProcedureInfo = action.payload;
          state.workProcedureSubstances = action.payload.substances;
          state.disableWorkProcedureEdit =
            action.payload.status === WORK_PROCEDURE_STATUS.APPROVED ||
            action.payload.status ===
              WORK_PROCEDURE_STATUS.REPLACED_BY_NEWER_VERSION;
          state.locationsLinkedWithWorkProcedures = action.payload.locations;
          state.workProcedureUsers = action.payload.users;
          state.workProcedureMissedInfo = action.payload.missed_info;
          state.isLoading = false;
          state.workProcedureExposure = action.payload.exposure;
        }
      )
      .addCase(
        fetchWorkProcedureInfoByID.pending,
        (state: WorkProcedureState) => {
          state.isLoading = true;
        }
      )
      .addCase(
        [fetchWorkProcedureInfoByID.rejected],
        (state: WorkProcedureState) => {
          state.isLoading = false;
        }
      )
      /* fetchWorkProcedurePPEsByID */
      .addCase(
        fetchWorkProcedurePPEsByID.fulfilled,
        (state: WorkProcedureState, action: any) => {
          state.workProcedurePPEs = action.payload;
        }
      )
      .addCase(
        fetchWorkProcedurePPEsByID.rejected,
        (state: WorkProcedureState) => {
          state.isLoading = false;
        }
      )
      /* fetchLocationWorkProcedure */
      .addCase(
        fetchLocationWorkProcedure.fulfilled,
        (state: WorkProcedureState, action: any) => {
          state.locationsLinkedWithWorkProcedures = action.payload.locations;
          state.disableWorkProcedureEdit =
            action.payload.status === WORK_PROCEDURE_STATUS.APPROVED ||
            action.payload.status ===
              WORK_PROCEDURE_STATUS.REPLACED_BY_NEWER_VERSION;
          state.isLoading = false;
        }
      )
      .addCase(
        fetchLocationWorkProcedure.rejected,
        (state: WorkProcedureState) => {
          state.isLoading = false;
        }
      )
      .addCase(
        fetchLocationWorkProcedure.pending,
        (state: WorkProcedureState) => {
          state.isLoading = true;
        }
      )
      /* fetchUsersWorkProcedure */
      .addCase(
        fetchUsersWorkProcedure.fulfilled,
        (state: WorkProcedureState, action: any) => {
          state.workProcedureUsers = action.payload.users;
          state.disableWorkProcedureEdit =
            action.payload.status === WORK_PROCEDURE_STATUS.APPROVED ||
            action.payload.status ===
              WORK_PROCEDURE_STATUS.REPLACED_BY_NEWER_VERSION;
          state.isLoading = false;
        }
      )
      .addCase(
        fetchUsersWorkProcedure.rejected,
        (state: WorkProcedureState) => {
          state.isLoading = false;
        }
      )
      .addCase(fetchUsersWorkProcedure.pending, (state: WorkProcedureState) => {
        state.isLoading = true;
      })
      /* fetchSubstancesWorkProcedure */
      .addCase(
        fetchSubstancesWorkProcedure.fulfilled,
        (state: WorkProcedureState, action: any) => {
          state.workProcedureSubstances = action.payload.substances;
          state.disableWorkProcedureEdit =
            action.payload.status === WORK_PROCEDURE_STATUS.APPROVED ||
            action.payload.status ===
              WORK_PROCEDURE_STATUS.REPLACED_BY_NEWER_VERSION;
          state.workProcedureMissedInfo = action.payload.missed_info;
          state.isLoading = false;
        }
      )
      .addCase(
        fetchSubstancesWorkProcedure.pending,
        (state: WorkProcedureState) => {
          state.isLoading = true;
        }
      )
      .addCase(
        fetchSubstancesWorkProcedure.rejected,
        (state: WorkProcedureState) => {
          state.isLoading = false;
        }
      )
      /* createNewRevisionRequest */
      .addCase(
        createNewRevisionRequest.fulfilled,
        (
          state: WorkProcedureState,
          action: { payload: string | number | null }
        ) => {
          state.selectedRevisionId = action.payload;
        }
      )
      .addCase(
        createNewRevisionRequest.rejected,
        (state: WorkProcedureState) => {
          state.isLoading = false;
        }
      )
      /* fetchMyWorkProcedures */
      .addCase(
        fetchMyWorkProcedures.fulfilled,
        (state: WorkProcedureState, action: any) => {
          state.myWorkProcedureListData = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(fetchMyWorkProcedures.pending, (state: WorkProcedureState) => {
        state.isLoading = true;
      })
      .addCase(fetchMyWorkProcedures.rejected, (state: WorkProcedureState) => {
        state.isLoading = false;
      })
      /* fetchWorkProcedures */
      .addCase(
        fetchWorkProcedures.fulfilled,
        (state: WorkProcedureState, action: any) => {
          state.workProcedureListData = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(fetchWorkProcedures.pending, (state: WorkProcedureState) => {
        state.isLoading = true;
      });
  },
});

export const { setWorkProcedureInfo, setSelectedRevisionId } =
  WorkProcedureSlice.actions;
export default WorkProcedureSlice.reducer;
