import axiosAuthoringInstance from 'api/axios-client';
import store from 'store/store';
import { getMoreSubstancesCount } from 'services/user/thunks';
import { fetchLocationStructure } from 'services/location/thunk';

export const archiveLocation = (locationId) => {
  return axiosAuthoringInstance
    .delete(`locations/${locationId}/`)
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const deleteWishList = (wishListId) => {
  return axiosAuthoringInstance
    .delete(`customerSdsWishList/${wishListId}/`)
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}