import i18next, { i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from '../i18n/resources';
import { SDS_MANAGER_URL } from '../constant';

let initialized = false;

export type Language = string;
export const languages: string[] = [
  'en',
  'no',
  'de',
  'da',
  'sv',
  'nl',
  'fi',
  'el',
  'es',
  'fr',
  'it',
  'pl',
  'pt',
  'bg',
  'bs',
  'cs',
  'et',
  'hi',
  'hr',
  'hu',
  'id',
  'is',
  'ja',
  'lt',
  'lv',
  'ms',
  'mt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sq',
  'sr',
  'tr',
  'uk',
  'vi',
  'zh',
];

export const supportedLanguages = [
  'da',
  'de',
  'el',
  'es',
  'et',
  'fi',
  'fr',
  'hi',
  'hu',
  'id',
  'is',
  'it',
  'ja',
  'en',
  'lt',
  'lv',
  'mt',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'sv',
  'sk',
  'sl',
  'tr',
  'vi',
  'cs',
  'zh',
  'uk'
];
export const defaultLanguage = 'en';

export function getI18n(defaultOptions: { lng?: string }): i18n {
  if (!initialized) {
    const options = {
      debug: process.env.NODE_ENV === 'development',
      resources,
      fallbackLng: 'en',
      defaultNS: 'common',
      react: {
        useSuspense: false,
      },
      ...defaultOptions,
    };

    if (!options.lng) {
      options.lng = getBrowserLanguage();
    }

    i18next.use(initReactI18next).init(options);

    i18next.on('languageChanged', lng => {
      setBrowserLanguage(lng);
    });

    initialized = true;
  }

  return i18next;
}
const localesToHreflangMap: { [key: string]: string } = {
  eu: 'en',
  uk: 'en',
  us: 'en',
  ca: 'en',
  au: 'en',
  nz: 'en',
  no: 'no',
};

export const getUrlCountry = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  const country = urlParams.get('lang');
  if (country) {
    localStorage.setItem('urlCountry', country);
  }
  return country;
};

export const getUrlLanguage = (): string | null => {
  const lang = getUrlCountry();
  if (!lang) return lang;
  return localesToHreflangMap[lang];
};

export const getBrowserLanguage = (): string => {
  let lang = localStorage.getItem('browserLanguage') ?? '';
  if (supportedLanguages.indexOf(lang) > -1) {
    return lang;
  }

  try {
    if (navigator.language) {
      lang = navigator.language.substring(0, 2).toLowerCase();
      if (supportedLanguages.indexOf(lang) > -1) {
        return lang;
      }
    }

    return defaultLanguage;
  } catch (err) {
    return defaultLanguage;
  }
};

export const setBrowserLanguage = (language: string): void => {
  return localStorage.setItem('browserLanguage', language);
};

export const tempi18n = (key: string, language: string): string => {
  return i18next.t(key, { lng: language });
};

export default i18next;
const supportLanguages = ['no', 'en'];
export const sdsManagerUrlLocalization = (country?: string): string => {
  if (country && Object.keys(localesToHreflangMap).indexOf(country) !== -1)
    return SDS_MANAGER_URL + '/' + country;
  const lang = i18next.language;
  if (i18next.language === 'en' || supportLanguages.indexOf(lang) === -1)
    return SDS_MANAGER_URL + '/eu';
  return SDS_MANAGER_URL + '/' + i18next.language;
};
