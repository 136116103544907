import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: '24px',
    cursor: 'pointer',
  },
  childMenuItem: {
    padding: '12px 9px 12px 8px!important',
    color: theme.palette.colors.textLight,
    '& svg': {
      minWidth: 'auto',
      fill: theme.palette.colors.textLight,
    },
    '&:hover': {
      color: theme.palette.colors.primary,
      background: theme.palette.hoverColor.main,
      '& svg': {
        fill: theme.palette.colors.primary,
      },
      '& .MuiSvgIcon-root': {
        fill: theme.palette.colors.primary,
      },
    },
  },
  menuActive: {
    '&.MuiListItem-root': {
      background: theme.palette.hoverColor.main,
      color: theme.palette.colors.primary,
      '& .MuiSvgIcon-root': {
        fill: theme.palette.colors.primary,
      },
      '& svg': {
        fill: theme.palette.colors.primary,
      },
    },
  },
  listItemMargin: {
    marginLeft: '5px',
    marginTop: '0!important',
    marginBottom: '0!important',

    '& .MuiTypography-root': {
      fontSize: 13,
    },
  },
}));

export default useStyles;
