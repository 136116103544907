import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    [theme.breakpoints.down('sm')]: {
      background: '#626DF9',
      color: '#fff',
      width: '100%',
      boxSizing: 'border-box',
      '& .MuiButtonBase-root': {
        color: '#fff',
        right: 0,
      },
    },
  },
  paperWidthLg: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
      margin: '15px',
    },
  },
  paperWidthMd: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
      margin: '15px',
    },
  },
  paperWidthSm: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
      margin: '15px',
    },
  },
  dialogContentRoot: {
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '15px!important',
      padding: 15,
    },
  },
}));

export default useStyles;
