import axiosAuthoringInstance from 'api/axios-client';
import store from 'store/store';
import { getMoreSubstancesCount } from 'services/user/thunks';
import { fetchLocationStructure } from 'services/location/thunk';

const updateVolumeForSubstance = (substanceID, data) => {
  return axiosAuthoringInstance
    .patch(`substances/${substanceID}/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateVolumeForSubstanceV2 = (substanceID, data) => {
  return axiosAuthoringInstance
    .post(`v2/substances/${substanceID}/amount/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateInventoryMessageSettings = (
  settingsId,
  messageSubject,
  messageBody
) => {
  return axiosAuthoringInstance
    .patch(`/inventorySettings/${settingsId}/`, {
      subject: messageSubject,
      body: messageBody,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateLocation = (locationId, data) => {
  return axiosAuthoringInstance
    .patch(`locations/${locationId}/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const changeSubscription = (plan, refreshStartDate) => {
  return axiosAuthoringInstance
    .patch(`/subscriptions/change-plan/?refreshStartDate=${refreshStartDate}`, {
      subscription_plan: plan,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateCustomer = (customerId, data) => {
  return axiosAuthoringInstance
    .patch(`/customer/${customerId}/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const deactivateUser = (userId) => {
  return axiosAuthoringInstance
    .patch(`/user/${userId}/deactivate/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const activateUser = (userId) => {
  return axiosAuthoringInstance
    .patch(`/user/${userId}/activate/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const reportSDSRequest = (substanceId, reportComment, mode) => {
  const payload = {
    wish_item_id: substanceId,
    customer_comment: reportComment,
    mode: mode
  }
  return axiosAuthoringInstance
    .patch(`v2/substances/completedSdsRequestReport/`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const deleteSubstance = (substanceID) => {
  return axiosAuthoringInstance
    .patch(`/v2/substances/${substanceID}/markDeleted/`)
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const deleteSubstances = (substanceIDs) => {
  const newParams = new URLSearchParams();
  substanceIDs.map((el) => newParams.append('sds_id', el));

  return axiosAuthoringInstance
    .patch(`/v2/substances/markDeleted/`, {}, { params: newParams })
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateUserQrLoginStatus = (userId, status) => {
  return axiosAuthoringInstance
    .patch(`/user/${userId}/allowQRCode/`, { allow_login_qr_code: status })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateImportBinder = (importId, resolution = null, comment = null) => {
  const req = {};
  if (resolution !== null) req.resolution = resolution;
  if (comment !== null) req.comment = comment;
  return axiosAuthoringInstance
    .patch(`importedBinders/${importId}/`, req)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateLatestExtractedDataCustomer = () => {
  return axiosAuthoringInstance
    .patch(`v2/substances/updateLatestExtractedDataCustomer/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const archiveCustomTag = (payload) => {
  return axiosAuthoringInstance
    .patch(`/customer/archiveCustomTag/`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const unarchiveCustomTag = (payload) => {
  return axiosAuthoringInstance
    .patch(`/customer/unarchiveCustomTag/`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const changeUserRole = (userId, role) => {
  return axiosAuthoringInstance
    .patch(`user/${userId}/changeUserRole/`, {
      inventory_manager_user_role: role,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const removeSDSAttachment = (sdsId, attachmentId, linkAllSds) => {
  let data = new FormData();
  data.append('attachment_id', attachmentId);
  data.append('apply_all_sds', linkAllSds);

  return axiosAuthoringInstance
    .patch(`/v2/substances/${sdsId}/removeAttachment/`, data)
    .then((response) => response)
    .catch((error) => error.response);
};

const allowUserAccessToAllSDS = (userId, hasAccess) => {
  return axiosAuthoringInstance
    .patch(`/user/${userId}/allowAccessToAllSDS/`, {
      access_to_all_sds: hasAccess,
    })
    .then((response) => response)
    .catch((error) => error.response);
};

const allowUserAccessToSDSByLanguage = (userId, enable) => {
  return axiosAuthoringInstance
    .patch(`/user/${userId}/allowAccessSDSByLanguage/`, {
      access_to_sds_by_language: enable,
    })
    .then((response) => response)
    .catch((error) => error.response);
};

const editCustomTag = (tagId, data) => {
  return axiosAuthoringInstance
    .patch(`/customer-custom-tags/${tagId}/`, data)
    .then((response) => response)
    .catch((error) => error.response);
};

const resetTrial = () => {
  return axiosAuthoringInstance
    .patch('/customer/resetTrial/')
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const changeToFreeVersion = () => {
  return axiosAuthoringInstance
    .patch('/customer/changeToFreeVersion/')
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const editIsRenewal = (payload) => {
  return axiosAuthoringInstance
    .patch(`/subscriptions/editIsRenewal/`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateSubstance = (data, substanceId) => {
  return axiosAuthoringInstance
    .patch(`/substances/${substanceId}/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateSubstanceEanUpcCode = (data, substanceId) => {
  return axiosAuthoringInstance
    .patch(`/v2/substances/${substanceId}/updateEanUpcCode/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const logPresenceOfProduct = (productId, data) => {
  return axiosAuthoringInstance
    .patch(`/v2/substances/${productId}/logPresence/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateSdsRequests = (data) => {
  return axiosAuthoringInstance
    .patch(`/v2/substances/updateSdsRequest/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateIngredientCheck = (data) => {
  return axiosAuthoringInstance
    .patch(`/user/ingredientCheck/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const allowAccessByCustomTag = (userId, data) => {
  return axiosAuthoringInstance
    .patch(`/user/${userId}/allowAccessByCustomTag/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const registerApprovalSDSRequest = (substanceId, data) => {
  return axiosAuthoringInstance
    .patch(`/v2/substances/${substanceId}/registerApproval/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const removeRegisteredApprovalSDSRequest = (substanceId) => {
  return axiosAuthoringInstance
    .patch(`/v2/substances/${substanceId}/removeRegisteredApproval/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateSetupStepsStatus = (data) => {
  return axiosAuthoringInstance
    .patch(`v2/user/updateSetupStepsStatus/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export {
  updateCustomer,
  updateInventoryMessageSettings,
  updateLocation,
  updateVolumeForSubstance,
  deactivateUser,
  activateUser,
  changeSubscription,
  updateVolumeForSubstanceV2,
  updateUserQrLoginStatus,
  updateImportBinder,
  updateLatestExtractedDataCustomer,
  reportSDSRequest,
  deleteSubstance,
  archiveCustomTag,
  unarchiveCustomTag,
  changeUserRole,
  removeSDSAttachment,
  allowUserAccessToAllSDS,
  editCustomTag,
  resetTrial,
  changeToFreeVersion,
  editIsRenewal,
  updateSubstance,
  updateSubstanceEanUpcCode,
  deleteSubstances,
  logPresenceOfProduct,
  updateSdsRequests,
  updateIngredientCheck,
  allowAccessByCustomTag,
  allowUserAccessToSDSByLanguage,
  registerApprovalSDSRequest,
  removeRegisteredApprovalSDSRequest,
  updateSetupStepsStatus,
};
