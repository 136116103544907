enum BARCODE_TYPE {
  EAN = 'ean',
  UPC = 'upc',
}

export enum EAN_TYPE {
  EAN_8 = 'ean_8',
  EAN_13 = 'ean_13',
}

export enum UPC_TYPE {
  UPC_A = 'upc_a',
  UPC_E = 'upc_e',
}

export default BARCODE_TYPE;
