import { createSlice } from '@reduxjs/toolkit';
import { GetCustomerUserResponseDto } from 'interfaces/administration/UserInterface';

export interface UserManagementState {
  userDataList: Array<GetCustomerUserResponseDto>,
  reloadTable: boolean,
}

const initialState: UserManagementState = {
  userDataList: [],
  reloadTable: true
}

const UserManagementSlice = createSlice({
  name: 'user-management',
  initialState,
  reducers: {
    setUserDataList(state, action: {payload: Array<GetCustomerUserResponseDto>}) {
      state.userDataList = action.payload;
    },
    setReloadTable(state, action: {payload: boolean}) {
      state.reloadTable = action.payload;
    }
  },
})

export const { 
  setUserDataList,
  setReloadTable
} = UserManagementSlice.actions;
export default UserManagementSlice.reducer