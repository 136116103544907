import {
  Suspense,
  lazy,
  useState,
  MouseEvent,
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  isMobile,
  isGoogleLogin,
  validateSandbox,
  deferImport,
  getCookie,
  hideShowGetStartedSection,
} from 'utils/helper';
import {
  checkUserPermissions,
  isAdmin,
  isNoAccessRole,
  isOwner,
  isReadOnlyRole,
  isStaffRole,
  validateFeatureNotAvailable,
} from 'utils/userRoleUtils';
import {
  missingSDSCountSelector,
  newerCountSelector,
  sdsRequestEmailCountSelector,
  userDataSelector,
} from 'services/user/selectors';
import {
  expandMenuSelector,
  isPinnedSelector,
  openDrawerSelector,
} from 'services/common/selectors';
import { setOpenDrawer } from 'services/common/slice';
import { PERMISSION } from 'enums/permissions.enums';
import { AppDispatch } from 'constant';
import { OFFLINE_APP_URL } from 'api/constants';
/* Components */
import {
  Box,
  Drawer,
  List,
  Popover,
  Tooltip,
  Typography,
  Button,
  ClassNameMap,
} from '@mui/material';
import {
  EmailOutlined,
  ThumbUp,
  InstallDesktop,
  InstallMobile,
} from '@mui/icons-material';
import {
  NotificationsNone as NotificationsNoneIcon,
  QrCodeScanner as QrCodeScannerIcon,
} from '@mui/icons-material';
import Avatar from 'components/commons/avatar';
import CustomSidebar from 'components/commons/sidebar';
import AppsPopoverContent from './apps-popover-content';
import Logo from './logo';
import ImpersonateUserPopup from 'components/popup/impersonate-user-popup';
import HomeSearch from 'components/home/search';
import LoadingModal from 'components/loader/LoadingModal';
import SdsRequestIcon from 'assets/icons/SdsRequest';

const HOME_SEARCH_BAR_IGNORE_PAGES = ['/my-sds/', '/global-sds-search/'];
const DELAY_SET_PWD_TOOLTIP_TIME = 3 * 24 * 60 * 60 * 1000; // 3 days

/* Lazy load component */
const ScanMethodPopup = lazy((): Promise<any> => {
  return deferImport(
    import('components/popup/scan-method-popup/ScanMethodPopup')
  );
});
const QRScanner = lazy((): Promise<any> => {
  return deferImport(import('components/scanner/QRScanner'));
});
const BarCodeScanner = lazy((): Promise<any> => {
  return deferImport(import('components/scanner/BarCodeScanner'));
});
const WebinarBanner = lazy((): Promise<any> => {
  return deferImport(import('components/commons/webinar-banner'));
});
/* End */

const HeaderV2 = () => {
  const { t } = useTranslation(['header']);
  const history = useHistory();
  const classes: ClassNameMap = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const headerRef = useRef<HTMLDivElement>(null);
  /* Selectors */
  const isPinned = useSelector(isPinnedSelector);
  const showMore = useSelector(expandMenuSelector);
  const openDrawer = useSelector(openDrawerSelector);
  const user = useSelector(userDataSelector);
  const updatedSDSCount = useSelector(newerCountSelector);
  const sdsRequestEmailCount = useSelector(sdsRequestEmailCountSelector);
  const missingSDSCount = useSelector(missingSDSCountSelector);
  /* State */
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [showPasswordHelpText, setShowPasswordHelpText] =
    useState<boolean>(true);
  const [openScanMethod, setOpenScanMethod] = useState<boolean>(false);
  const [scanMethod, setScanMethod] = useState<'QR' | 'Barcode' | null>(null);
  const [openImpersonatePopup, setOpenImpersonatePopup] =
    useState<boolean>(false);
  const [showHomeSearch, setShowHomeSearch] = useState<boolean>(false);
  const showWebinarBanner =
    !hideShowGetStartedSection(user) &&
    !(getCookie(`skipped_webinar_banner_${user?.id}`) === 'true');

  /* Constants */
  const showUpdatedSDSCount =
    !isNoAccessRole(user) &&
    !isReadOnlyRole(user) &&
    !isStaffRole(user) &&
    checkUserPermissions(PERMISSION.ACCESS_TO_NEWER_VERSION_DATA, true) &&
    updatedSDSCount &&
    updatedSDSCount !== 0;
  const showEmailCount =
    !isNoAccessRole(user) &&
    checkUserPermissions(
      PERMISSION.ACCESS_SUBSTANCE_WITH_MISSING_SDS_PAGE,
      true
    ) &&
    sdsRequestEmailCount &&
    sdsRequestEmailCount !== 0;
  const showSdsRequestCount =
    !isNoAccessRole(user) &&
    checkUserPermissions(
      PERMISSION.ACCESS_SUBSTANCE_WITH_MISSING_SDS_PAGE,
      true
    ) &&
    missingSDSCount !== 0;
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;
  const joinedDate = user?.date_joined
    ? new Date(user?.date_joined).getTime()
    : null;
  const currentDate = Date.now();

  const checkPath = (val: string) => {
    if (
      ['/', '/my-sds', '/global-sds-search'].includes(window.location.pathname)
    )
      return false;

    var result = HOME_SEARCH_BAR_IGNORE_PAGES.filter((el) =>
      el.startsWith(val)
    );
    if (result.length != 0) return false;
    return true;
  };

  useEffect(() => {
    setShowHomeSearch(checkPath(window.location.pathname));
  }, [location]);

  const onProfileClick = (event: MouseEvent<HTMLDivElement>): void => {
    setShowPasswordHelpText(false);
    if (
      user?.need_to_set_password &&
      user?.id &&
      joinedDate &&
      currentDate - joinedDate > DELAY_SET_PWD_TOOLTIP_TIME
    )
      localStorage.setItem(`is_open_profile_${user.id}`, 'true');
    setAnchorEl(event.currentTarget);
  };

  const toggleDrawer =
    (open: boolean) =>
    (event: { type: string; key: string }): void => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      dispatch(setOpenDrawer(open));
    };

  const needChangePwdGuide = (): boolean => {
    if (!user) return false;
    if (!user.need_to_set_password) return false;
    if (user.qr_login_token) return false;
    if (isGoogleLogin(user)) return false;
    if (window.location.pathname !== '/') return false;
    if (!showPasswordHelpText) return false;

    const isOpenProfile = localStorage.getItem(`is_open_profile_${user.id}`);
    if (isOpenProfile === 'true') return false;

    if (!joinedDate) return true;
    return currentDate - joinedDate > DELAY_SET_PWD_TOOLTIP_TIME;
  };

  const renderSetPasswordTooltip = (): ReactElement | null => {
    if (!needChangePwdGuide()) return null;

    return (
      <div className={classes.popperContainer}>
        <div className={classes.popper}>
          <div
            onClick={() => {
              setShowPasswordHelpText(false);
              history.push('/account/?action=change-password');
              if (user?.id)
                localStorage.setItem(`is_open_profile_${user.id}`, 'true');
            }}
            className={classes.poperContent}
          >
            <p> {t('header:set_password')} </p>
            <ThumbUp />
          </div>
        </div>
      </div>
    );
  };

  const renderHeaderLogo = (): ReactElement => {
    if (isMobile()) {
      return (
        <div className={classes.headerMobileLogo}>
          <Logo
            showMore={showMore}
            openDrawer={openDrawer}
            user={user}
            isPinned={isPinned}
          />
          {validateSandbox() && (
            <Button onClick={() => setOpenScanMethod(true)}>
              <QrCodeScannerIcon style={{ fontSize: '24px' }} />
            </Button>
          )}
        </div>
      );
    }

    return (
      <div className={classes.headerDesktopLogo}>
        <div style={{ width: showMore ? 300 : 60 }}>
          <Logo
            showMore={showMore}
            openDrawer={openDrawer}
            user={user}
            isPinned={isPinned}
          />
        </div>
        {validateSandbox() && (
          <Button
            style={{ marginLeft: 10 }}
            onClick={() => setOpenScanMethod(true)}
          >
            <QrCodeScannerIcon style={{ fontSize: '24px' }} />
          </Button>
        )}
      </div>
    );
  };

  const renderMobileSidebar = (): ReactElement => {
    return (
      <Drawer
        anchor={'left'}
        open={openDrawer}
        onClose={toggleDrawer(false)}
        disableEnforceFocus
        className={'drawerSidebar'}
        transitionDuration={100}
      >
        <Box role="presentation">
          <List style={{ width: 300 }}>
            <CustomSidebar />
          </List>
        </Box>
      </Drawer>
    );
  };

  const renderNotification = (): ReactElement => {
    if (!isOwner(user) && !isAdmin(user)) return <></>;
    return (
      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title={t('header:sds_with_newer_version')}
        placement="left"
        arrow
      >
        <div
          className={classes.notificationWrapper}
          onClick={() => {
            if (
              validateFeatureNotAvailable(
                PERMISSION.ACCESS_TO_NEWER_VERSION_DATA
              )
            )
              return;

            history.push('/sds-with-newer-version/');
          }}
        >
          <NotificationsNoneIcon style={{ fill: '#626DF9' }} />
          <Typography className={classes.countText}>
            {updatedSDSCount < 100 ? (
              updatedSDSCount
            ) : (
              <span style={{ fontSize: 8 }}>99+</span>
            )}
          </Typography>
        </div>
      </Tooltip>
    );
  };

  const renderSDSRequest = (): ReactElement => {
    return (
      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title={t('sidebar:sds_requets')}
        placement="left"
        arrow
      >
        <div
          className={classes.notificationWrapper}
          onClick={() => {
            if (
              validateFeatureNotAvailable(
                PERMISSION.ACCESS_SUBSTANCE_WITH_MISSING_SDS_PAGE
              )
            )
              return;

            history.push('/unmatched-substances/');
          }}
        >
          <SdsRequestIcon
            width={14}
            height={14}
            className={classes.sdsRequestIcon}
          />
          <Typography className={classes.countText}>
            {missingSDSCount < 100 ? (
              missingSDSCount
            ) : (
              <span style={{ fontSize: 8 }}>99+</span>
            )}
          </Typography>
        </div>
      </Tooltip>
    );
  };

  const renderEmail = (): ReactElement => {
    return (
      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title={t('header:message_from_vendor')}
        placement="left"
        arrow
      >
        <div
          className={classes.notificationWrapper}
          onClick={() => {
            if (
              validateFeatureNotAvailable(
                PERMISSION.ACCESS_SUBSTANCE_WITH_MISSING_SDS_PAGE
              )
            )
              return;

            history.push('/unmatched-substances/?email=1');
          }}
        >
          <EmailOutlined style={{ fill: '#626DF9', fontSize: 20 }} />
          <Typography className={classes.emailCountText}>
            {sdsRequestEmailCount < 100 ? (
              sdsRequestEmailCount
            ) : (
              <span style={{ fontSize: 8 }}>99+</span>
            )}
          </Typography>
        </div>
      </Tooltip>
    );
  };

  const renderProfile = (): ReactElement => {
    return (
      <div className={classes.appsIcon} id={'set-password-tooltip'}>
        {user && <Avatar user={user} onClick={onProfileClick} />}
        {renderSetPasswordTooltip()}
      </div>
    );
  };

  const renderProfileMenu = (): ReactElement => {
    return (
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{
          top: '4px',
          right: '8px',
        }}
      >
        <AppsPopoverContent
          onClose={() => setAnchorEl(null)}
          user={user}
          onImpersonate={() => {
            setOpenImpersonatePopup(true);
          }}
        />
      </Popover>
    );
  };

  const scrollHandle = () => {
    if (headerRef?.current) {
      const boundingClien = headerRef.current.getBoundingClientRect();
      const currentTopPosition = boundingClien.top || 0;
      if (currentTopPosition < (-(boundingClien.height + 50))) {
        headerRef.current.style.position = 'fixed';
      } else if (document.documentElement.scrollTop == 0) {
        headerRef.current.style.position = 'relative';
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollHandle, {
      passive: true,
    });
    return () => document.removeEventListener('scroll', scrollHandle);
  }, []);

  return (
    <div className={classes.headerWrapper} ref={headerRef}>
      <Suspense>{showWebinarBanner && <WebinarBanner />}</Suspense>
      <div className={classes.header}>
        {renderHeaderLogo()}
        {renderMobileSidebar()}

        {showHomeSearch && (
          <div className={classes.headerSearchBar}>
            <HomeSearch />
          </div>
        )}

        {user?.qr_login_token && user?.customer.offline_app_access && (
          <Typography
            component={'a'}
            target="_blank"
            href={`${OFFLINE_APP_URL}?token=${user?.qr_login_token}`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
            }}
          >
            {isMobile() ? (
              <InstallMobile style={{ fontSize: 20 }} />
            ) : (
              <InstallDesktop style={{ fontSize: 20 }} />
            )}
            {t('common:install_app_for_offline_access')}
          </Typography>
        )}

        <div className={classes.actionsWrapper}>
          {showSdsRequestCount && renderSDSRequest()}
          {showEmailCount ? renderEmail() : null}
          {showUpdatedSDSCount ? renderNotification() : null}
          {renderProfile()}
        </div>

        {renderProfileMenu()}
      </div>

      <Suspense fallback={<LoadingModal />}>
        {openImpersonatePopup && (
          <ImpersonateUserPopup
            open={true}
            onClose={() => setOpenImpersonatePopup(false)}
          />
        )}

        {/* Not used */}
        {openScanMethod && (
          <ScanMethodPopup
            open={openScanMethod}
            onClose={() => setOpenScanMethod(false)}
            method={[
              {
                label: 'QR code',
                action: () => {
                  setScanMethod('QR');
                  setOpenScanMethod(false);
                },
              },
              {
                label: 'Bar code',
                action: () => {
                  setScanMethod('Barcode');
                  setOpenScanMethod(false);
                },
              },
            ]}
          />
        )}

        {scanMethod === 'QR' && (
          <QRScanner
            disabledInput={scanMethod === 'QR'}
            onScanSuccess={(res: any) => console.log(res)}
            onScanError={(err: any) => console.log(err)}
            open={scanMethod === 'QR'}
            onClose={() => setScanMethod(null)}
          />
        )}

        {scanMethod === 'Barcode' && (
          <BarCodeScanner
            disabledInput={scanMethod === 'Barcode'}
            onScanSuccess={(res: any) => console.log(res)}
            onScanError={(err: any) => console.log(err)}
            open={scanMethod === 'Barcode'}
            onClose={() => setScanMethod(null)}
          />
        )}
        {/* End */}
      </Suspense>
    </div>
  );
};

export default HeaderV2;
