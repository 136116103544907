import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { openAlert } from 'store/pageAction/pageAction';
import useStyles from './styles';
import {useTranslation} from "react-i18next";

const AlertPopup = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(['scan_method']);

  const open = useSelector((state: any) => state.pageReducer.openAlert);

  React.useEffect(() => {
    if (open) {
      setTimeout(() => dispatch(openAlert(false)), 3000);
    }
  }, [open]);

  return (
    <div className={!open ? classes.alertDisabled : classes.alertActive}>
      <Alert
        variant="filled"
        severity="success"
        sx={{ height: '45px' }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => dispatch(openAlert(false))}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>{t('scan_method:scan_qr_code')}</AlertTitle>
      </Alert>
    </div>
  );
};

export default AlertPopup;
