import { CircularProgress, CircularProgressProps } from '@mui/material';

interface GradientCircularProgressProps extends CircularProgressProps {
  baseColor?: string;
}

const StepCircularProgress = ({
  baseColor = 'rgb(0 0 0 / 20%)',
  ...props
}: GradientCircularProgressProps) => {
  const { value, sx, thickness = 8, ...restProps } = props;
  return (
    <div
      style={{
        position: 'relative',
        width: restProps.size,
        height: restProps.size,
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={(theme) => ({
          position: 'absolute',
          left: 0,
          top: 0,
          color: baseColor,
          ...(theme.palette.mode === 'dark' && {
            color: baseColor,
          }),
        })}
        thickness={thickness}
        value={100}
        {...restProps}
      />
      <CircularProgress
        variant="determinate"
        value={value}
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          ...sx,
        }}
        {...restProps}
        thickness={thickness}
      />
    </div>
  );
};

export default StepCircularProgress;
