import React, { ForwardedRef } from 'react';
import clsx from 'clsx';

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import {
  ListItem,
  Tooltip,
  Typography,
  CircularProgress,
  ClassNameMap
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { FavoriteBorder, Favorite } from '@mui/icons-material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { favoriteLocationDataSelector } from 'services/location/selectors';

const FavoriteIcon = ({node, setLoading, addOrRemoveFromFavorites  }: any) => {
  const classes: ClassNameMap = useStyles();
  const { t } = useTranslation();
  return <Tooltip
    classes={{ tooltip: classes.tooltip }}
    title={`${t('location_management:remove_to_my_list')}`}
    placement="right"
    arrow
  >
    <Favorite
      style={{
        fontSize: 18,
        visibility: 'hidden',
        fill: '#626DF9',
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (addOrRemoveFromFavorites) {
          setLoading(true);
          addOrRemoveFromFavorites(node.id, () => {
            setLoading(false);
          });
        }
      }}
    />
  </Tooltip>
}
const FavoriteBorderIcon = ({node, setLoading, addOrRemoveFromFavorites  }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return <Tooltip
  classes={{ tooltip: classes.tooltip }}
  title={`${t('location_management:add_to_my_list')}`}
  placement="bottom"
  arrow
>
  <FavoriteBorder
    style={{ fontSize: 18, visibility: 'hidden' }}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      if (addOrRemoveFromFavorites) {
        setLoading(true);
        addOrRemoveFromFavorites(node.id, () => {
          setLoading(false);
        });
      }
    }}
  />
</Tooltip>
}
interface CustomContentProps {
  classes: any;
  className: any;
  label: any;
  nodeId: any;
  icon: any;
  expansionIcon: any;
  displayIcon: any;
  showCount: any;
  showSelected: boolean;
  node: any;
  onClick: any;
  isAdmin?: boolean;
  addOrRemoveFromFavorites?: (
    id: number,
    callback: ((res?: any) => void) | null
  ) => void;
  handleAddLocation?: (id: number) => void;
  selectedLocation?: string;
  fontSizeContent: number;
  showFavorites?: boolean
}

const CustomContent = React.forwardRef(function CustomContent(
  props: CustomContentProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    onClick,
    showCount,
    showSelected,
    node,
    isAdmin,
    selectedLocation,
    addOrRemoveFromFavorites,
    handleAddLocation,
    fontSizeContent,
    showFavorites
  } = props;

  const {
    disabled,
    expanded,
    selected,
    handleExpansion,
    preventSelection,
  } = useTreeItem(nodeId);
  const { t } = useTranslation();
  const icon = iconProp || expansionIcon || displayIcon;
  const classesCustom = useStyles();
  const favoriteLocations = useSelector(favoriteLocationDataSelector);
  const [loading, setLoading] = React.useState(false);
  const classesTooltip = useStyles().tooltip;
  const handleMouseDown = (event: any) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event: any) => {
    handleExpansion(event);
  };

  const handleSelectionClick = () => {
    onClick(nodeId);
  };
  const isFavoriteLocation =
    favoriteLocations.filter((el: any) => el.id === node.id).length > 0;
  
  return (
    <ListItem
      className={'labelContent'}
      style={{
        backgroundColor: nodeId == String(selectedLocation) && showSelected ? '#ECF7FF' : '',
        color: nodeId == String(selectedLocation) && showSelected ? '#626DF9' : 'rgba(58, 53, 65, 0.87)',
        padding: '0 5px 0 0px',
        boxSizing: 'border-box',
      }}
      button
    >
      <div
        className={clsx(
          className,
          classes.root,
          {
            [classes.expanded]: expanded,
            [classes.selected]: selected,
            [classes.disabled]: disabled,
          },
          classesCustom.treeItemContent
        )}
        onMouseDown={handleMouseDown}
        ref={ref}
        style={{
          padding: '2px 5px 2px 5px',
          boxSizing: 'border-box',
          // borderBottom: '1px dotted #ccc',
        }}
      >
        <div onClick={handleExpansionClick} className={classes.iconContainer} style={{marginRight: '23px'}}>
          {icon ? icon : <CircleOutlinedIcon style={{ fontSize: 10 }} />}
        </div>
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
          style={{
            display: 'flex',
            lineHeight: '24px',
            padding: '8px 0 8px 5px',
            flex: 1,
            marginLeft: '-21px'
          }}
        >
          <span
            style={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '80%',
              fontSize: fontSizeContent,
            }}
          >
            {label}
          </span>
          {showCount ? (
            <span style={{ marginLeft: 5, fontSize: 14 }}>
              ({node.count_of_sds})
            </span>
          ) : (
            ''
          )}
        </Typography>
        {isAdmin ? (
          <>
            <Tooltip
              classes={{ tooltip: classesTooltip }}
              title={`${t('common:add_sub_location')}`}
              placement="bottom"
              arrow
              style={{ fontSize: 14 }}
            >
              <AddIcon
                id={'add-sub-location'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (handleAddLocation) {
                    handleAddLocation(node.id);
                  }
                }}
                style={{ visibility: 'hidden' }}
              />
            </Tooltip>
          </>
        ) : null}
        {showFavorites 
          ? loading 
          ? (
          <div>
            <CircularProgress style={{ width: 20, height: 20 }} />
          </div>
          ) : isFavoriteLocation ? <FavoriteIcon node={node} setLoading={setLoading} addOrRemoveFromFavorites={addOrRemoveFromFavorites} /> 
          : <FavoriteBorderIcon node={node} setLoading={setLoading} addOrRemoveFromFavorites={addOrRemoveFromFavorites} /> 
          : null}
      </div>
    </ListItem>
  );
});

const useStyles = makeStyles({
  root: {
    '&.Mui-selected > .MuiTreeItem-label': {
      color: 'red',
    },
    '.Mui-expanded': {
      backgroundColor: 'black',
    },
    '& .MuiTreeItem-content': {
      boxSizing: 'border-box',
    },
  },
  label: {
    '& .MuiTreeItem-content .MuiTreeItem-label': {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px',
      paddingBottom: '8px',
      fontFamily: 'Montserrat, sans-serif',
      display: 'flex',
    },

    '& .MuiTreeItem-content .MuiTreeItem-label .sdsLabel': {},
    '& .MuiTreeItem-content .MuiTreeItem-label .countSds': {
      marginLeft: 5,
    },

    '& .labelContent:hover': {
      background: '#ECF7FF',
      color: '#626DF9!important',
      '& .svg-icon': {
        fill: '#626DF9',
      },
      '& .MuiSvgIcon-root': {
        fill: '#626DF9',
        visibility: 'visible!important',
      },
    },
  },
  itemActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  treeItemContent: {
    '&:hover': {
      backgroundColor: 'transparent!important',
    },
  },
  tooltip: {
    fontSize: '14px',
  },
});

const CustomTreeItem = (props: any) => {
  const classes = useStyles();
  // @ts-ignore
  const {
    showCount,
    showSelected,
    isAdmin,
    addOrRemoveFromFavorites,
    handleAddLocation,
    selectedLocation,
    fontSizeContent,
    showFavorites,
    ...treeItemProps
  } = props

  return (
    <TreeItem
      className={`${classes.label}`}
      ContentComponent={CustomContent}
      ContentProps={props}
      {...treeItemProps}
      style={{ maxWidth: '100%', padding: '0' }}
    />
  );
};
interface CustomTreeViewProps {
  data: any;
  onSelectLocation: (locationID: string, index?: number | any) => void;
  sortByName?: any;
  showCount: any;
  showSelected?: boolean;
  isAdmin?: boolean;
  addOrRemoveFromFavorites?: (
    id: number,
    callback: ((res: any) => void) | null
  ) => void;
  handleAddLocation?: (id: number) => void;
  selectedLocation?: string|null;
  fontSizeContent?: number;
  showFavorites?: boolean;
  className?: any;
  defaultEndIcon?: any
  sx?: any
}
const CustomTreeView = ({
  data,
  onSelectLocation,
  sortByName,
  showCount,
  showSelected = true,
  isAdmin = false,
  handleAddLocation,
  addOrRemoveFromFavorites,
  selectedLocation,
  fontSizeContent = 14,
  showFavorites = false,
  className,
  defaultEndIcon,
  sx
}: CustomTreeViewProps) => {

  const renderTree = (nodes: any) => {
    return (
      <CustomTreeItem
        key={nodes.id}
        nodeId={nodes.id.toString()}
        label={nodes.name}
        onClick={onSelectLocation}
        node={nodes}
        showCount={showCount}
        showSelected={showSelected}
        isAdmin={isAdmin}
        addOrRemoveFromFavorites={addOrRemoveFromFavorites}
        handleAddLocation={handleAddLocation}
        selectedLocation={selectedLocation}
        fontSizeContent={fontSizeContent}
        showFavorites={showFavorites}
      >
        {Array.isArray(nodes.children)
          ? [...nodes.children].sort(sortByName).map((node: any) => renderTree(node))
          : null}
      </CustomTreeItem>
    );
  };

  return (
    <TreeView
      aria-label="rich object"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ flexGrow: 1 }}
    >
      {renderTree(data)}
    </TreeView>
  );
};

export default CustomTreeView;
