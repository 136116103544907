import React from 'react';
import Routes from './routers';
import AppThemeProvider from './utils/AppThemeProvider';
import './App.css';
import { BugsnagErrorBoundary, ErrorView } from './utils/BugsnagErrorBoundary';
import { BACKEND_URL, BASE_API_URL, getEnv } from './api/constants';
import packageFile from '../package.json';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

function App() {
  let hash = window.location.hash;
  let env = getEnv();

  if (hash === '#debug') {
    return (
      <div>
        <ul>
          <li>Environment: {env}</li>
          <li>Version: {packageFile.version}</li>
          <li>API: {BASE_API_URL}</li>
          <li>BACKEND_URL: {BACKEND_URL}</li>
        </ul>
      </div>
    );
  }

  return (
    <BugsnagErrorBoundary FallbackComponent={ErrorView}>
      <AppThemeProvider>
        <BrowserRouter>
          <SnackbarProvider maxSnack={3}>
            <Routes />
          </SnackbarProvider>
        </BrowserRouter>
      </AppThemeProvider>
    </BugsnagErrorBoundary>
  );
}

export default App;
