import React from 'react';
import {
  TO_LITER_MAPPER,
  TO_KILOGRAM_MAPPER,
  SDS_INVENTORY_URL,
  TIMESTAMP_30_DAYS,
} from '../constant';
import BARCODE_TYPE from 'enums/barcode-type.enums';
import { renderSnackbar } from './renderSnackbar';
import { format, isValid } from 'date-fns';
import {
  DefaultLocationItem,
  ExtractedLocationItem,
  LocationItem,
  LocationStructureInterface,
} from 'interfaces/location-management';
import i18next from 'utils/i18n';
import { BACKEND_URL } from 'api';
import { hasUserRoleOnLocation, isAdmin, isAdminLight, isOwner } from './userRoleUtils';
import { LOCATION_ROLE } from 'enums/role.enums';
import { UserLocationAccess } from 'interfaces/administration/UserInterface';
import { isFreePlan } from './userSubscriptionUtils';
import UserInterface from 'interfaces/UserInterface';

export const trimString = (value: string | null | undefined): string => {
  if (!value) {
    return '';
  }
  return value.trim();
};

export const validateDate = (
  value: string | null | undefined,
  formatType: string = 'yyyy-MM-dd'
): boolean => {
  if (!value) return false;
  let m = format(new Date(value), formatType);
  return isValid(new Date(m));
};

export const validateSandbox = () => {
  const urlParams = new URLSearchParams(window.location?.search);
  const urlSandbox = urlParams.get('sandbox');
  return urlSandbox === '1';
};

export const sortArrayByField = (
  items: any[],
  orderBy: string,
  order: 'ASC' | 'DESC'
): Array<any> => {
  items.sort(function (a, b) {
    let valueA = (a[orderBy] ?? '').toUpperCase();
    let valueB = (b[orderBy] ?? '').toUpperCase();

    if (order === 'ASC') {
      if (valueA < valueB) return -1;

      if (valueA > valueB) return 1;
    } else {
      if (valueA < valueB) return 1;

      if (valueA > valueB) return -1;
    }

    return 0;
  });

  return items;
};

export const removeItemEmptyInArray = (
  items: any[],
  key: string
): Array<any> => {
  return items.filter((item: any) => {
    return item[key] !== undefined && item[key];
  });
};

export const multipleClasses = (arr: string[]) => {
  return arr.join(' ');
};

export const checkUserExpire = (user: any) => {
  return user?.customer?.inventory_subscription_plan?.expired;
};

export const isTrialPeriod = (user: any) => {
  return user?.customer?.inventory_subscription_plan?.is_trial_period;
};

export const isTrialOrFreePlan = (user: any) => {
  return isTrialPeriod(user) || isFreePlan(user);
};

export const createUUID = (): string => {
  let dt = new Date().getTime();
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

export function amountConvert(
  value: number,
  oldUnit: any,
  newUnit: any
): number {
  if (!oldUnit || !newUnit || !value) return 0;
  if (TO_LITER_MAPPER[oldUnit] && TO_LITER_MAPPER[newUnit]) {
    const literValue = value / TO_LITER_MAPPER[oldUnit];
    return literValue * TO_LITER_MAPPER[newUnit];
  }
  if (TO_KILOGRAM_MAPPER[oldUnit] && TO_KILOGRAM_MAPPER[newUnit]) {
    const kilogramValue = value / TO_KILOGRAM_MAPPER[oldUnit];
    return kilogramValue * TO_KILOGRAM_MAPPER[newUnit];
  }
  return 0;
}

export const getMediaUrl = (url: string) => {
  if (url.includes(BACKEND_URL)) return url;
  return BACKEND_URL + url;
};

export const renderTreeByKeyword = (
  allLocations: Array<any>,
  keyword: string
) => {
  const listLocation: Array<any> = [];

  const filterLocation = (location: any, list: Array<any>) => {
    if (location.name.toLowerCase().includes(keyword.toLowerCase())) {
      const childLocation: Array<any> = [];
      location.children.map((item: any) => filterLocation(item, childLocation));
      list.push({ ...location, children: childLocation });
      return true;
    }
    location.children.map((item: any) => filterLocation(item, list));
  };

  allLocations.map(item => {
    filterLocation(item, listLocation);
  });

  return listLocation;
};

/* README */
/* A function to extract location path from locationDataSelector */
/* Return: Array of all location access with path (Not recursion in tree mode) */
/* Parameters */
/* - locationData: List of location in tree mode (Usually locationDataSelector) */
/* - excluedNodeTreeId?: Skip traversing the node with this location ID */
/* - traverseCallback?: A callback function invoke on each node traversed */
/* - locationAccessRole?: Filter result for location access role */
export const extractLocation = (
  locationData: Array<DefaultLocationItem>,
  excludeNodeTreeId: string | number | null = null,
  traverseCallback: ((location: ExtractedLocationItem) => void) | null = null,
  locationAccessRole: LOCATION_ROLE | Array<LOCATION_ROLE> | null = null
): Array<ExtractedLocationItem> => {
  if (!locationData) return [];

  let tempArr: Array<ExtractedLocationItem> = [];

  /* Recursive Function */
  const generateChildren = (item: DefaultLocationItem, pair: string) => {
    /* Stop condition */
    if (item.children.length == 0) return;

    item?.children.forEach((child: DefaultLocationItem) => {
      /* Excluding whole node need excluded */
      /* -> This will cancel traversing this node */
      if (child.id == excludeNodeTreeId) return;

      let childPair = pair + '/' + child.name;
      const locationItem = {
        id: child.id,
        path: childPair,
        name: child.name,
      };

      /* If need to filter out location access */
      /* -> Only push if user have location access */
      if (
        !locationAccessRole ||
        hasUserRoleOnLocation(locationItem.id, locationAccessRole)
      )
        tempArr.push(locationItem);

      /* Perform callback on the node */
      if (traverseCallback !== null) traverseCallback(locationItem);

      /* Recursive */
      generateChildren(child, childPair);
    });
  };

  /* Check & Start recursive function for all root-node */
  locationData.forEach((item: DefaultLocationItem) => {
    /* Excluding whole node need excluded */
    /* -> This will cancel traversing this node */
    if (item.id == excludeNodeTreeId) return;

    const locationItem = {
      id: item.id,
      path: item.name,
      name: item.name,
    };

    /* If need to filter out location access */
    /* -> Only push if user have location access */
    if (
      !locationAccessRole ||
      hasUserRoleOnLocation(item.id, locationAccessRole)
    )
      tempArr.push(locationItem);

    /* Perform callback on the node */
    if (traverseCallback !== null) traverseCallback(locationItem);

    /* Start recursive function */
    generateChildren(item, item.name);
  });

  return tempArr;
};

export const getLocationDetailPath = (
  prefix: string = '/my-sds',
  locationId?: number | string | null,
  substanceId?: number | string | null,
  needParam: boolean = false,
  remainQuery: boolean = false,
  toSection?: number | null
) => {
  let queryParam = '?';

  if (remainQuery) {
    const urlParams: any = new URLSearchParams(window.location?.search);
    if (urlParams) {
      for (const [key, value] of urlParams) {
        if (key === 'substance') continue;
        queryParam = `${queryParam}${key}=${value}&`;
      }
    }
  }

  const suffix = locationId
    ? `/location-detail/${locationId}/`
    : substanceId
    ? `/substance-detail/${substanceId}/`
    : '';
  const hash = toSection ? `#section_${toSection}` : '';
  if (!needParam) return prefix + suffix + queryParam + hash;

  if (!!locationId && !!substanceId) queryParam += `substance=${substanceId}`;

  return prefix + suffix + queryParam + hash;
};

export const getSDSDetailPath = (
  prefix: string = '/my-sds',
  toSection?: number | null
) => {
  const hash = toSection ? `#section_${toSection}` : '';

  return prefix + hash;
};

export const printElement = (
  element: Element | null,
  onBeforePrint?: () => void,
  onAfterPrint?: () => void,
  fileName?: string
) => {
  if (!element) {
    onAfterPrint && onAfterPrint();
    return;
  }
  const styles = [...document.getElementsByTagName('style')]
    .flatMap(({ sheet }: any) =>
      [...sheet.cssRules].map(rules => rules.cssText)
    )
    .join('\n');
  const styleLinks = [...document.getElementsByTagName('link')]
    .filter(el => el.sheet)
    .map(
      ({ sheet }) =>
        `<link rel="stylesheet" type="${sheet?.type}" href="${sheet?.href}">`
    )
    .join('\n');
  let innerHTML = `<html>
      <head>
        <style>${styles}</style>
        ${styleLinks}
      </head>
      <body>`;
  innerHTML += element.innerHTML + '</body></html>';
  let fakeIFrame = window.document.createElement('iframe');
  const original_title = window.document.title;
  if (fileName) {
    window.document.title = fileName;
  }

  document.body.appendChild(fakeIFrame);
  let fakeContent = fakeIFrame.contentWindow;
  fakeContent?.document.open();
  fakeContent?.document.write(innerHTML);
  fakeContent?.document.close();
  fakeContent?.focus();
  fakeIFrame.addEventListener('load', () => {
    onBeforePrint && onBeforePrint();
    fakeContent?.print();
  });
  fakeContent?.addEventListener('afterprint', () => {
    if (window.document.title !== original_title) {
      window.document.title = original_title;
    }
    fakeIFrame.remove();
    onAfterPrint && onAfterPrint();
  });
};

export const displayErrorApi = (error: any) => {
  if (error.response?.data?.error_code !== undefined) {
    if (typeof error.response?.data?.error_message == 'string') {
      renderSnackbar([error.response.data.error_message]);
    }
    if (typeof error.response?.data?.error_message == 'object') {
      const res: string[] = [];
      Object.entries(error.response.data.error_message).map(
        ([key, value]: [string, any]) => {
          res.push(
            `<span style="text-transform: capitalize">${key}</span>: ${value.join(
              ', '
            )}`
          );
        }
      );
      renderSnackbar(res);
    }
  } else {
    renderSnackbar([i18next.t('common:some_thing_wrong')]);
  }
};

export const clearUserSessionFromStorage = () => {
  if (sessionStorage.getItem('qr_login_token')) {
    sessionStorage.removeItem('qr_login_token');
  } else {
    localStorage.removeItem('login_method');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('impersonate_backup_token');
    localStorage.removeItem('selectedLocationID');
    localStorage.removeItem('hide_onboarding_bubble');
  }
};

export const setUserSessionToStorage = (
  method?: string,
  refreshToken?: string | null,
  accessToken?: string | null
) => {
  method && localStorage.setItem('login_method', method);
  refreshToken && localStorage.setItem('refresh_token', refreshToken);
  accessToken && localStorage.setItem('access_token', accessToken);
};

export const setQRLoginTokenToStorage = (token: string) => {
  if (localStorage.getItem('access_token')) {
    sessionStorage.setItem('qr_login_token', token);
  } else {
    localStorage.setItem('access_token', token);
    localStorage.setItem('login_method', 'qrcode');
  }
};

export const setImpersonateSessionToStorage = (
  refreshToken?: string,
  accessToken?: string
) => {
  if (!accessToken || !refreshToken) return false;
  const impersonateBackupToken = localStorage.getItem('refresh_token');
  impersonateBackupToken &&
    localStorage.setItem('impersonate_backup_token', impersonateBackupToken);
  refreshToken && localStorage.setItem('refresh_token', refreshToken);
  accessToken && localStorage.setItem('access_token', accessToken);
  return true;
};

export const isImpersonated = () => {
  const impersonateBackupToken = localStorage.getItem(
    'impersonate_backup_token'
  );
  return !!impersonateBackupToken;
};

export const revertImpersonateFromStorage = () => {
  localStorage.removeItem('access_token');
  const impersonateBackupToken = localStorage.getItem(
    'impersonate_backup_token'
  );
  if (impersonateBackupToken) {
    localStorage.setItem('refresh_token', impersonateBackupToken);
    localStorage.removeItem('impersonate_backup_token');
    return true;
  }
  clearUserSessionFromStorage();
  return true;
};

/**
 * google tracking
 */
export const gtagReportConversion = (
  label: string,
  value?: string | number,
  url?: any
): boolean => {
  let googleAdsId = 'AW-1010407660';
  try {
    const callback = function () {
      if (typeof url != 'undefined') {
        window.location = url;
      }
    };

    // @ts-ignore
    window.gtag('event', 'conversion', {
      send_to: `${googleAdsId}/${label}`,
      currency: 'NOK',
      value: value ? value : 1.0,
      event_callback: callback,
    });

    return false;
  } catch (e) {
    return false;
  }
};

export const isMobile = (): boolean => {
  return window.matchMedia('(max-width: 767px)').matches;
};

export const isSm = (): boolean => {
  return window.matchMedia('(max-width: 600px)').matches;
};

export const isMd = (): boolean => {
  return window.matchMedia('(max-width: 900px)').matches;
};

export const isSLg = (): boolean => {
  return window.matchMedia('(max-width: 1000px)').matches;
};

export const isLg = (): boolean => {
  return window.matchMedia('(max-width: 1200px)').matches;
};

export const isMlg = () => {
  return window.matchMedia('(max-width: 1330px)').matches;
};

export const isXl = (): boolean => {
  return window.matchMedia('(max-width: 1440px)').matches;
};

export const isMediumView = () => {
  return isXl() && window.matchMedia('(min-width: 1331px)').matches;
};

export const shouldShowLocationSubmenuSidebar = (path: string): boolean => {
  return (
    path.includes('/locationManagement') ||
    path.includes('/my-sds') ||
    path.includes('/location-management') ||
    path.includes('/unmatched-substances') ||
    path.includes('/archived')
  );
};

export const isEmailValid = (email: string): boolean => {
  const testEmail = /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
  return testEmail.test(email);
};

export const isValidPostalCode = (code: string): boolean => {
  return code.length >= 2 && code.length <= 9;
};

export const roundSignificantDecimals = (
  value: number,
  numOfDecimal: number
): number => {
  if (!value) return 0;
  const numberOfZero = Math.round(Math.log(value) / Math.log(0.1));
  return (
    Math.round(value * Math.pow(10, numberOfZero + numOfDecimal - 1)) /
    Math.pow(10, numberOfZero + numOfDecimal - 1)
  );
};

export const formatDateTime = (
  dateTime: string,
  formatType: string
): string => {
  return format(new Date(dateTime), formatType);
};

export const numberWithCommas = (x: any): string => {
  if (!x) return '';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isGoogleLogin = (user: any): boolean => {
  return Object.keys(user?.providers ?? {}).length > 0;
};

export const isUsCaUser = (user: UserInterface | null): boolean => {
  return ['us', 'ca'].includes(user?.country?.short_name ?? '');
};

export const isUsUser = (user: UserInterface | null): boolean => {
  return user?.country?.short_name === 'us';
};

export const isUsCustomer = (user: UserInterface | null): boolean => {
  return user?.customer?.country?.short_name === 'us';
};

export const isCaCustomer = (user: UserInterface | null): boolean => {
  return user?.customer?.country?.short_name === 'ca';
};

export const isUsCaCustomer = (user: UserInterface | null): boolean => {
  return !!user && ['us', 'ca'].includes(user?.customer?.country?.short_name);
};

export const isNumeric = (value: any) => {
  return /^-?\d+$/.test(value);
};

export const checkValidFormatBarCode = (code: string, type: BARCODE_TYPE) => {
  const eanRegex = /^\d{8,13}$/g;
  const upcRegex = /^\d{12}$/g;
  if (type === BARCODE_TYPE.EAN && !eanRegex.test(code)) return false;
  if (type === BARCODE_TYPE.UPC && !upcRegex.test(code)) return false;

  let checksum = 0;
  const codeList = code.split('');
  const finalChar = codeList.pop();
  if (!finalChar) return false;

  const checkDigit = parseInt(finalChar, 10);
  codeList.map((value, index) => {
    const digit = parseInt(value, 10);
    if (code.length % 2 === 1) checksum += index % 2 ? digit * 3 : digit;
    else checksum += index % 2 ? digit : digit * 3;
  });

  let check = checksum % 10;
  if (check !== 0) check = 10 - check;
  return check === checkDigit;
};

export const checkValidQrCode = (code: string) => {
  if (!code) return false;
  return code.includes(SDS_INVENTORY_URL);
};

export const insertLocation = (
  locations: Array<LocationItem> | null,
  locations_access: Array<UserLocationAccess> | null
): LocationStructureInterface | null => {
  if (!locations) return null;
  const commonLocations = locations.filter((node: LocationItem) => {
    return !(node.children?.length < 1 && node.is_favorite);
  });
  const favoriteList: LocationItem[] = [];
  const traverseAllLocations = (node: LocationItem) => {
    if (node.is_favorite) favoriteList.push(node);
    if (node.children) {
      node.children.map((childNode: LocationItem) =>
        traverseAllLocations(childNode)
      );
    }
  };

  locations.map((node: LocationItem) => traverseAllLocations(node));
  return {
    all: locations,
    common: commonLocations,
    favorite: favoriteList,
    locations_access: locations_access || [],
  };
};

export const createDownloadFile = (fileUrl: string, fileName?: string) => {
  if (!fileName) {
    fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
  }

  let a = document.createElement('a');
  a.target = '_blank';
  a.download = fileName;
  a.href = fileUrl;
  a.click();
};

/**
 * HOC
 */
export const withVersioning =
  (ComponentList: Array<React.FC<any>> = [], version: number = 0) =>
  ({ ...props }) => {
    if (ComponentList !== undefined && ComponentList.length > 0) {
      const WrappedComponent: React.FC<any> = ComponentList[version];
      if (WrappedComponent) return <WrappedComponent {...props} />;
    }
    return null;
  };

/**
 * Import with error catch
 */
export const deferImport = (promise: Promise<any>) =>
  new Promise(resolve => {
    promise
      .then(res => {
        resolve(res);
      })
      .catch((error: Error) => {
        console.log(error.name);
        if (error.name === 'ChunkLoadError') {
          renderSnackbar(
            ['A new version is available'],
            'Update',
            () => window.location.reload(),
            'info-snackbar',
            false
          );
          resolve({ default: () => <div>A new version is available</div> });
        }

        renderSnackbar(
          ["Oh no, there seems to be a problem, we're working on it..."],
          'Close',
          undefined,
          'error-snackbar',
          false
        );
        resolve({
          default: (
            <div>
              Oh no, there seems to be a problem, we&#39;re working on it...
            </div>
          ),
        });
      });
  });

export const getCookie = (name: string) => {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
  return undefined;
};

export const isValidDateForValidation = (dateString: string) => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (dateString === '') {
    return true;
  } else {
    if (!dateRegex.test(dateString.trim())) {
      return false;
    }
  }

  return true;
};

export const isNumber = (value: string) => {
  const numStrPattern =
    /^-?(\d+\.?\d*)$|(\d*\.?\d+)$|^-?(\d+\,?\d*)$(\d*\,?\d+)$/;
  return numStrPattern.test(value);
};

export const required = (value: any) => {
  return value ? undefined : 'Required';
};

export const applyToAllSubLocations = (
  locationsStructure: DefaultLocationItem[],
  locationId: number,
  handleApplyChild: (childLocations: DefaultLocationItem) => void
) => {
  const traverseAllChillLocations = (
    parentNode: DefaultLocationItem,
    callback: (node: DefaultLocationItem) => void,
    stopTraverse?: boolean
  ) => {
    parentNode.children.forEach(childNode => {
      callback(childNode);
      if (stopTraverse) return;
      traverseAllChillLocations(childNode, callback, stopTraverse);
    });
  };

  let selectedLocationNode: DefaultLocationItem | null = null;

  const findLocationNode = locationsStructure.filter(
    el => el.id === locationId
  );
  if (findLocationNode.length > 0) {
    selectedLocationNode = findLocationNode[0];
  } else {
    let stopTraverse = false;
    locationsStructure.forEach(node => {
      if (!stopTraverse && node.children.length > 0) {
        traverseAllChillLocations(
          node,
          childNode => {
            if (childNode.id == locationId) {
              selectedLocationNode = childNode;
              stopTraverse = true;
            }
          },
          stopTraverse
        );
      }
    });
  }
  if (selectedLocationNode) {
    traverseAllChillLocations(selectedLocationNode, handleApplyChild);
  }
};

export const getSearchKeyFromFileName = (
  fileName: string | null
): { productName: string; supplierName: string } => {
  if (!fileName) {
    return {
      productName: '',
      supplierName: '',
    };
  }

  let productName = '';
  let supplierName = '';
  if (fileName) {
    const GOOD_FILE_NAME_REGEX = /^SN—.+—PN—.+/;
    if (GOOD_FILE_NAME_REGEX.test(fileName)) {
      const fileNameSplitted = fileName.split('—');
      supplierName = fileNameSplitted[1];
      productName = fileNameSplitted[3];
    } else {
      productName = fileName.replace(/_/g, ' ');
      productName = productName.replace(/\.pdf$/i, '');
    }
  }
  return {
    productName: productName,
    supplierName: supplierName,
  };
};

export const updateHistoryState = (state: any) => {
  window.history.replaceState(
    {
      ...window.history.state,
      customStates: state,
    },
    ''
  );
};

export const removeHistoryState = () => {
  updateHistoryState(null);
};

export const prettyFileSize = (bytes: number, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const convertTooLongName = (
  fileName: string,
  desktopLinmit?: number,
  mobileLimit?: number
) => {
  desktopLinmit = desktopLinmit || 50;
  mobileLimit = mobileLimit || 20;

  if (isMobile()) {
    if (fileName.length >= mobileLimit) {
      return `${fileName.slice(0, mobileLimit)} ...`;
    }
  }

  if (fileName.length >= desktopLinmit) {
    return `${fileName.slice(0, desktopLinmit)} ...`;
  }

  return fileName;
};

export const openNewTabSDSRequestsPage = (keyword: string) => {
  if (keyword) {
    localStorage.setItem('existFindUpdatedSdsProductName', keyword);
  }
  window.open('/unmatched-substances/');
};

export const isIPv4 = (ip: string): boolean => {
  const ipv4Regex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipv4Regex.test(ip);
};

export const isIPv4Network = (network: string): boolean => {
  const ipv4NetworkRegex =
    /^(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\/([0-9]|[1-2][0-9]|3[0-2])$/;
  return ipv4NetworkRegex.test(network);
};

export const isIPv6 = (ip: string): boolean => {
  const ipv6Regex =
    /^(([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}|:)|(([0-9a-fA-F]{1,4}:){1,6}|:):(([0-9a-fA-F]{1,4}:){1,6}|:)|(([0-9a-fA-F]{1,4}:){1,5}|:)((:[0-9a-fA-F]{1,4}){1,2}|:)|(([0-9a-fA-F]{1,4}:){1,4}|:)((:[0-9a-fA-F]{1,4}){1,3}|:)|(([0-9a-fA-F]{1,4}:){1,3}|:)((:[0-9a-fA-F]{1,4}){1,4}|:)|(([0-9a-fA-F]{1,4}:){1,2}|:)((:[0-9a-fA-F]{1,4}){1,5}|:)|([0-9a-fA-F]{1,4}:|:)((:[0-9a-fA-F]{1,4}){1,6}|:)|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}(([0-9]{1,3}\.){3}[0-9]{1,3})|([0-9a-fA-F]{1,4}:){1,4}:(([0-9]{1,3}\.){3}[0-9]{1,3}))$/;
  return ipv6Regex.test(ip);
};

export const isIPv6Network = (network: string): boolean => {
  const ipv6NetworkRegex =
    /^(([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}|(([0-9a-fA-F]{1,4}:){1,7}|:):|(([0-9a-fA-F]{1,4}:){1,6}|:):(([0-9a-fA-F]{1,4}:){1,6}|:)|(([0-9a-fA-F]{1,4}:){1,5}|:)((:[0-9a-fA-F]{1,4}){1,2}|:)|(([0-9a-fA-F]{1,4}:){1,4}|:)((:[0-9a-fA-F]{1,4}){1,3}|:)|(([0-9a-fA-F]{1,4}:){1,3}|:)((:[0-9a-fA-F]{1,4}){1,4}|:)|(([0-9a-fA-F]{1,4}:){1,2}|:)((:[0-9a-fA-F]{1,4}){1,5}|:)|([0-9a-fA-F]{1,4}:|:)((:[0-9a-fA-F]{1,4}){1,6}|:)|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}(([0-9]{1,3}\.){3}[0-9]{1,3})|([0-9a-fA-F]{1,4}:){1,4}:(([0-9]{1,3}\.){3}[0-9]{1,3}))(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))$/;
  return ipv6NetworkRegex.test(network);
};

export const customerSubscriptionStartedAfter = (user: UserInterface | null, days: number) => {
  if (!user?.customer.subscription_started_at) return false;
  const subscription_started_date = new Date(
    user.customer.subscription_started_at
  );
  const TenDaysAgo = new Date(Date.now() - days * 24 * 60 * 60 * 1000);

  if (subscription_started_date < TenDaysAgo) {
    return true;
  }
  return false;
}

export const shouldShowOnboarding = (user: UserInterface | null, section?: "bubble" | 'page') => {
  if (!user) return false;
  if (isOwner(user)) {
    if (
      user?.customer?.inventory_subscription_plan?.plan?.plan ==='free' 
      && !user?.setup_steps_status?.generated_sds_binder
    ) return true; 
    if (user.customer.inventory_subscription_plan.is_trial_period) return true;
    return false;
  }
  if (!isAdmin(user)) return false;

  if(
    !user.date_joined 
    || (
      new Date(user.date_joined) 
      < (new Date(Date.now() - TIMESTAMP_30_DAYS))
    )
  ) return false;
  
  if (
    section === 'bubble'
    && localStorage.getItem('hide_onboarding_bubble') == 'true'
  ) return false;
  
  return true
}

export const hideShowGetStartedSection = (user: UserInterface | null) => {
  if (!user) return true;
  if (isOwner(user)) {
     // owner: only show 10 days after customer paid or in trial period
    return customerSubscriptionStartedAfter(user, 10);
  }
  if ((isAdmin(user) || isAdminLight(user))) {
    return (
      !user.date_joined 
      || new Date(user.date_joined) < (new Date(Date.now() - TIMESTAMP_30_DAYS))
    )
  }
  return true;
}