import React from 'react';
import { Icon, IconProps } from '@mui/material';
import SdsRequestImage from 'icons/quote-request.png';

interface SdsRequestIconProps extends IconProps {
  width: number;
  height: number;
}
const SdsRequestIcon: React.FC<SdsRequestIconProps> = ({
  width,
  height,
  ...props
}) => (
  <Icon {...props}>
    <img alt="Icon" src={SdsRequestImage} height={height} width={width} />
  </Icon>
);

export default SdsRequestIcon;
