export function validateToken(token: string | null): boolean {
  if (!token) {
    return false;
  }
  const pattern =
    /^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-\+\/=]*)/g;
  return pattern.test(token);
}

export function isTokenAvailable (token?: string | null): boolean {
  if (!token) return false;
  if (!validateToken(token)) return false;
  
  const tokenParts = JSON.parse(atob(token.split('.')[1]));
  const now = Math.ceil(Date.now() / 1000);
  if (tokenParts.exp == undefined || tokenParts.exp < now)
    return false;

  return true;
}
