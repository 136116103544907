import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSubstancesV2, getAllSds } from 'api';
import {
  ApiError,
  ApiResponse,
  ErrorPayload,
} from 'interfaces/common/ApiResponseInterface';
import { PaginationResponse } from 'interfaces/common/PaginationInterface';
import {
  GetSubstanceRequestDto,
  SubstanceInterface,
} from 'interfaces/substance';

export const fetchSubstance = createAsyncThunk<
  PaginationResponse<SubstanceInterface>,
  GetSubstanceRequestDto,
  {
    rejectValue: ErrorPayload;
  }
>(
  'substance/fetchSubstance',
  async (params: GetSubstanceRequestDto, { rejectWithValue }) => {
    const response:
      | ApiResponse<PaginationResponse<SubstanceInterface>>
      | ApiError = await getSubstancesV2(params);
    if (response.status !== 200)
      return rejectWithValue(response.data as ErrorPayload);
    return response.data as PaginationResponse<SubstanceInterface>;
  }
);

export const fetchSds = createAsyncThunk<
  PaginationResponse<SubstanceInterface>,
  GetSubstanceRequestDto,
  {
    rejectValue: ErrorPayload;
  }
>(
  'substance/fetchSds',
  async (params: GetSubstanceRequestDto, { rejectWithValue }) => {
    const response:
      | ApiResponse<PaginationResponse<SubstanceInterface>>
      | ApiError = await getAllSds(params);
    if (response.status !== 200)
      return rejectWithValue(response.data as ErrorPayload);
    return response.data as PaginationResponse<SubstanceInterface>;
  }
);
