import axiosAuthoringInstance from 'api/axios-client';

export const getExposures = (params) => {
  const reqParams = new URLSearchParams();
  reqParams.append('page', params.page);
  reqParams.append('page_size', params.page_size);

  if (params.status && params.status.length > 0) {
    params.status.map((el) => reqParams.append('status', el));
  }
  if (params.locations && params.locations.length > 0) {
    params.locations.map((el) => reqParams.append('location', el));
  }
  if (params.location && params.location.length > 0) {
    params.location.map((el) => reqParams.append('location', el));
  }
  return axiosAuthoringInstance
    .get(`exposures/`, { params: reqParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getMyExposures = (params) => {
  const reqParams = new URLSearchParams();
  reqParams.append('page', params.page);
  reqParams.append('page_size', params.page_size);

  if (params.status && params.status.length > 0) {
    params.status.map((el) => reqParams.append('status', el));
  }
  if (params.work_procedure && params.work_procedure.length > 0) {
    params.work_procedure.map((el) => reqParams.append('work_procedure', el));
  }

  return axiosAuthoringInstance
    .get(`exposures/assigments/`, { params: reqParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
